import { takeLatest, call, put, select } from 'redux-saga/effects'
import { replace, push } from 'connected-react-router'

import { createProjectRoute, createShareRoute } from '@hip/helpers'
import { _t } from '@hip/translations'
import { definitions } from '@hip/design-service-types'
import { OPCOS } from '@hip/interfaces'

import { actions as hipCommunicatorActions } from '../hip-communicator/actions'
import { actions as errorsActions } from '../errors/actions'
import { MODALS } from '../modals/reducer'
import { actions as notificationsActions } from '../notifications/actions'
import {
  getToken,
  getOPCO,
  getDesignId,
  getIsShareFlow,
  getProjectId,
  getShareId,
} from '../utils/selectors'
import { actions as modalActions } from '../modals/actions'
import { NOTIFICATION_TIMEOUT } from '../utils/sagas'
import { TimeoutTypes } from '../utils/interfaces'
import { actions as hipEventsActions } from '../hip-events/actions'
import { MarxentJourneyType } from '../marxent/interfaces'
import { getMxtJourney } from '../marxent/selectors'
import { mapMxtJourneyToProjectCategory } from '../marxent/utils'
import {
  createDesign,
  duplicateDesign,
  duplicateSharedDesign,
  createSharedDesign,
  getDesignBySharedId,
  getDesignById,
} from './requests'
import { actions as designActions, ActionTypes } from './actions'

function* getShareableDesignByShareableId(shareId) {
  if (shareId) {
    const response: definitions['Design'] = yield call(
      getDesignBySharedId,
      shareId
    )
    yield put(designActions.setDesign({ ...response, id: response._id }))
  }
}

function* getShareableDesignByDesignId(projectId, designId) {
  const token = yield select(getToken)

  if (token && projectId && designId) {
    const response: definitions['Design'] = yield call(
      getDesignById,
      projectId,
      designId
    )

    yield put(designActions.setDesign({ ...response, id: response._id }))
  }
}

function* load() {
  try {
    const isShareFlow = yield select(getIsShareFlow)
    const opco: OPCOS = yield select(getOPCO)

    if (isShareFlow) {
      const shareId = yield select(getShareId)

      yield call(getShareableDesignByShareableId, shareId)

      // Avoid connection to events-service on "/bom" page
      const isBomPage = /\/bom$/i.test(location.pathname)
      if (!isBomPage) {
        yield put(
          hipEventsActions.accessShareableDesign({
            tenant: opco,
            shareableId: shareId ? shareId : undefined,
          })
        )
      }
    } else {
      const projectId = yield select(getProjectId)
      const designId = yield select(getDesignId)
      const isReadOnly =
        new URLSearchParams(window.location.search).get('readonly') === 'true'

      yield call(getShareableDesignByDesignId, projectId, designId)
      yield put(
        hipEventsActions.accessDesign({
          tenant: opco,
          projectId: projectId ? projectId : '',
          designId: designId ? designId : undefined,
          isReadOnly,
        })
      )
    }
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

export function* save({
  payload: { vendorId },
}: ReturnType<typeof designActions.saveDesign>) {
  try {
    const isShareFlow = yield select(getIsShareFlow)

    if (isShareFlow) {
      const mxtJourney: MarxentJourneyType = yield select(getMxtJourney)
      const category = mapMxtJourneyToProjectCategory(mxtJourney)
      const response: definitions['PostShareableDesignResponse'] = yield call(
        createSharedDesign,
        vendorId,
        category
      )

      if (response.editId) {
        yield put(designActions.setDesign({ ...response, id: response.editId }))
      } else {
        throw Error('No shareable id')
      }

      yield put(replace(createShareRoute(response.editId)))
      yield put(
        hipCommunicatorActions.designCreated({
          shareId: response.editId,
        })
      )
      yield call(getShareableDesignByShareableId, response.editId)
      yield put(modalActions.open({ modal: MODALS.SHARE }))
    } else {
      const projectId = yield select(getProjectId)
      const response: definitions['Design'] = yield call(
        createDesign,
        projectId,
        vendorId
      )

      yield put(designActions.setDesign({ ...response, id: response._id }))
      yield put(replace(createProjectRoute(projectId, response._id)))
      yield put(
        hipCommunicatorActions.designCreated({
          projectId,
          designId: response._id,
        })
      )
      yield call(getShareableDesignByDesignId, projectId, response._id)
    }
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* duplicate({
  payload: { projectId, designId, shareId },
}: ReturnType<typeof designActions.duplicate>) {
  try {
    const isShareFlow = yield select(getIsShareFlow)

    if (isShareFlow) {
      const response: definitions['PostShareableDesignResponse'] = yield call(
        duplicateSharedDesign,
        shareId
      )

      if (response.editId) {
        yield put(designActions.setDesign({ ...response, id: response.editId }))
      } else {
        throw Error('No shareable id')
      }

      yield put(modalActions.close({ modal: MODALS.DUPLICATE }))
      yield put(push(createShareRoute(response.editId)))
      yield put(
        hipCommunicatorActions.designCreated({
          shareId: response.editId,
        })
      )

      yield put(
        notificationsActions.set({
          name: TimeoutTypes.NOTIFICATION_TIMEOUT,
          type: 'success',
          body: _t('messages.duplicated'),
          close: true,
          timeout: NOTIFICATION_TIMEOUT,
        })
      )
    } else {
      const response: definitions['Design'] = yield call(
        duplicateDesign,
        projectId,
        designId
      )

      yield put(designActions.setDesign({ ...response, id: response._id }))
      yield put(modalActions.close({ modal: MODALS.DUPLICATE }))
      yield put(push(createProjectRoute(projectId, response._id)))
      yield put(
        hipCommunicatorActions.designCreated({
          projectId,
          designId: response._id,
        })
      )

      yield put(
        notificationsActions.set({
          name: TimeoutTypes.NOTIFICATION_TIMEOUT,
          type: 'success',
          body: _t('messages.duplicated'),
          close: true,
          timeout: NOTIFICATION_TIMEOUT,
        })
      )
    }
  } catch (e) {
    yield put(
      notificationsActions.set({
        name: TimeoutTypes.NOTIFICATION_TIMEOUT,
        type: 'danger',
        body: e.message,
        close: true,
      })
    )
  }
}

export function* sagas(): IterableIterator<ReturnType<typeof takeLatest>> {
  yield takeLatest(ActionTypes.LOAD_DESIGN, load)
  yield takeLatest(ActionTypes.SAVE_DESIGN, save)
  yield takeLatest(ActionTypes.DUPLICATE, duplicate)
}
