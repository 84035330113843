import * as React from 'react';
import { _t } from '@hip/translations';
const label = _t('icons.kitchen');
export const KitchenIcon = () => {
    return (React.createElement("svg", { width: "40px", height: "41px", "aria-label": label, role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 40 41" },
        React.createElement("title", null, label),
        React.createElement("g", { id: label, stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { transform: "translate(-80.000000, -602.000000)" },
                React.createElement("g", { transform: "translate(32.000000, 579.000000)" },
                    React.createElement("g", { transform: "translate(48.000000, 24.000000)" },
                        React.createElement("g", { strokeWidth: "1", transform: "translate(0.000000, 0.828000)" },
                            React.createElement("path", { d: "M35.5589504,18.8783998 L4.18504931,18.8783998 C3.70312608,18.8783998 3.31199997,19.2348993 3.31199997,19.6741575 L3.31199997,38.0672991 L36.4319997,38.0672991 L36.4319997,19.6741575 C36.4319997,19.2348993 36.0417466,18.8783998 35.5589504,18.8783998 L35.5589504,18.8783998 Z", id: "Path", stroke: "#323C41", strokeWidth: "1.70567994" }),
                            React.createElement("path", { d: "M20.2984198,18.2159998 L20.2984198,38.0800218", id: "Stroke-4", stroke: "#323C41", strokeWidth: "1.70567994" }),
                            React.createElement("path", { d: "M20.5298563,24.1587677 L36.2050603,24.1587677", id: "Stroke-5", stroke: "#323C41", strokeWidth: "1.70567994" }),
                            React.createElement("path", { d: "M23.3744398,34.9995597 L33.2607597,34.9995597 L33.2607597,27.5972397 L23.3744398,27.5972397 L23.3744398,34.9995597 Z", id: "Path", stroke: "#323C41", strokeWidth: "1.70567994" }),
                            React.createElement("polygon", { id: "Fill-8", fill: "#323C41", points: "6.62399994 22.3741712 8.35944386 22.3741712 8.35944386 20.6387273 6.62399994 20.6387273" }),
                            React.createElement("polygon", { id: "Fill-9", fill: "#323C41", points: "22.3054913 22.3741712 24.0409352 22.3741712 24.0409352 20.6387273 22.3054913 20.6387273" }),
                            React.createElement("polygon", { id: "Fill-10", fill: "#323C41", points: "25.4609992 22.3741712 27.1964431 22.3741712 27.1964431 20.6387273 25.4609992 20.6387273" }),
                            React.createElement("path", { d: "M7.47683991,19.2808842 L7.47683991,14.0172828 C7.47683991,12.6801232 8.59273035,11.5919999 9.97154055,11.5919999 C11.3503507,11.5919999 12.4653735,12.6801232 12.4653735,14.0172828 L12.4653735,15.0906548", id: "Stroke-11", stroke: "#323C41", strokeWidth: "1.70567994" }),
                            React.createElement("polygon", { id: "Stroke-1", stroke: "#323C41", strokeWidth: "1.70567994", points: "25.7260064 4.38711546 32.2174344 4.38711546 32.2174344 -8.6772196e-05 25.7260064 -8.6772196e-05" }),
                            React.createElement("path", { d: "M32.0811152,3.56564308 L36.382413,10.3503611 C36.4839365,10.5100219 36.3407623,10.8388885 36.151599,10.8388885 L21.7439435,10.8189309 C21.5547801,10.8189309 21.4697434,10.4891966 21.5712669,10.3304035 L25.9020672,3.50577026", id: "Stroke-12", stroke: "#323C41", strokeWidth: "1.70567994" }),
                            React.createElement("path", { d: "M0,38.0879997 L39.7439997,38.0879997", id: "Path-9", stroke: "#323C41", strokeWidth: "1.65599999" })),
                        React.createElement("path", { d: "M12.4199999,19.7063998 L16.5599999,19.7063998 L16.5599999,28.4003998 C16.5599999,29.5436292 15.6332293,30.4703997 14.4899999,30.4703997 C13.3467705,30.4703997 12.4199999,29.5436292 12.4199999,28.4003998 L12.4199999,19.7063998 L12.4199999,19.7063998 Z", stroke: "#323C41", strokeWidth: "1.65599999" })))))));
};
