import {
  takeLatest,
  CallEffect,
  put,
  call,
  select,
  SelectEffect,
  PutEffect,
  TakeEffect,
  take,
} from 'redux-saga/effects'
import convertToXML from 'jsontoxml'
import downloadFile from 'js-file-download'

import { definitions as experienceDefinitions } from '@hip/experience-service-types'
import { COMMUNICATOR_TYPES } from '@hip/communicator'
import { Features } from '@hip/interfaces'
import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'

import { getStockApi, getToken, getTokenStoreId } from '../utils/selectors'
import { actions as hipCommunicatorActions } from '../hip-communicator/actions'
import { init } from '../utils/sagas'
import { actions as errorsActions } from '../errors/actions'
import { ActionTypes as utilsActionsTypes } from '../utils/actions'

import {
  actions as hipProductsActions,
  ActionTypes as HipProductsActionTypes,
} from './actions'
import { fetchStockData, getBomByDesignId, getBomBySharedId } from './requests'
import { getProducts } from './selectors'
import {
  buildOrderObject,
  createFileName,
  getEansFromBomResponse,
  parseAggregatedStockApiResponse,
} from './utils'
import { AVAILABILITY_INFORMATION_STOCKS, xmlOptions } from './constants'

export function* loadProducts({
  payload: { shareId, projectId, designId },
}: ReturnType<typeof hipProductsActions.loadProducts>): IterableIterator<
  | CallEffect
  | SelectEffect
  | TakeEffect
  | PutEffect<ReturnType<typeof hipCommunicatorActions.getProducts>>
  | PutEffect<ReturnType<typeof hipProductsActions.loadStockData>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    let response: experienceDefinitions['GetBomResponse']

    yield call(init, { payload: { type: COMMUNICATOR_TYPES.BOM } })

    if (shareId) {
      response = yield call(getBomBySharedId, shareId)
    } else {
      const token = yield select(getToken)
      if (!token) {
        yield take(utilsActionsTypes.SET_TOKEN)
      }
      response = yield call(getBomByDesignId, projectId, designId)
    }

    yield put(hipCommunicatorActions.getProducts({ products: response }))

    const isStockAvailabilityFeatureEnabled = yield select(
      getIsFeatureEnabledByConst(Features.STOCK_AVAILABILITY_INFORMATION)
    )
    if (isStockAvailabilityFeatureEnabled) {
      const eans = yield call(getEansFromBomResponse, response)
      const storeId = yield select(getTokenStoreId)
      yield put(
        hipProductsActions.loadStockData({
          storeId,
          eans,
        })
      )
    }
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

export function* loadStockData({
  payload: { storeId, eans },
}: ReturnType<typeof hipProductsActions.loadStockData>): IterableIterator<
  | SelectEffect
  | CallEffect
  | PutEffect<ReturnType<typeof hipProductsActions.loadStockDataSuccess>>
  | PutEffect<ReturnType<typeof hipProductsActions.loadStockDataFailure>>
> {
  try {
    const stockApi = yield select(getStockApi)
    if (!stockApi) {
      throw new Error('Config "stockApi" is not found')
    }
    const response = yield call(fetchStockData, {
      ...(stockApi as any),
      storeId,
      eans,
      stockSources: AVAILABILITY_INFORMATION_STOCKS,
    })
    const stockSummaryData = yield call(
      parseAggregatedStockApiResponse,
      response
    )
    yield put(hipProductsActions.loadStockDataSuccess(stockSummaryData))
  } catch (err) {
    yield put(hipProductsActions.loadStockDataFailure(err))
  }
}

export function* exportBOMToXML({
  payload: { designId },
}: ReturnType<typeof hipProductsActions.exportBOMToXML>): IterableIterator<
  | SelectEffect
  | CallEffect
  | PutEffect<ReturnType<typeof hipProductsActions.exportBOMToXMLFail>>
> {
  try {
    const products: ReturnType<typeof getProducts> = yield select(getProducts)
    const order = yield call(buildOrderObject, designId, products)
    const orderXML = yield call(convertToXML, order, xmlOptions)
    const fileName = yield call(createFileName)
    yield call(downloadFile, orderXML, fileName)
  } catch {
    yield put(hipProductsActions.exportBOMToXMLFail())
  }
}

export function* sagas(): IterableIterator<ReturnType<typeof takeLatest>> {
  yield takeLatest(HipProductsActionTypes.LOAD_PRODUCTS, loadProducts)
  yield takeLatest(HipProductsActionTypes.LOAD_STOCK_DATA, loadStockData)
  yield takeLatest(HipProductsActionTypes.EXPORT_BOM_TO_XML, exportBOMToXML)
}
