import { createAction, ActionsUnion } from '@hip/helpers'
import { JsCommunicator } from '@mxt/kf-js-communicator'
import {
  AutoSavePromptResponse,
  MarxentInitPayload,
  MarxentJourneyPayload,
} from './interfaces'

export enum ActionTypes {
  MXT_INIT = '@hip/marxent/MXT_INIT',
  MXT_EVENT = '@hip/marxent/MXT_EVENT',
  MXT_TOKEN_EXPIRED = '@hip/marxent/MXT_TOKEN_EXPIRED',
  JOURNEY_SELECTED = '@hip/marxent/JOURNEY_SELECTED',
  CHANGE_JS_COMMUNICATOR = '@hip/marxent/CHANGE_JS_COMMUNICATOR',
  AUTO_SAVE_PROMPT_RESPONSE = '@hip/marxent/AUTO_SAVE_PROMPT_RESPONSE',
}

export const actions = {
  mxtInit: (payload: JsCommunicator) =>
    createAction(ActionTypes.MXT_INIT, payload),
  mxtEvent: (payload: MarxentInitPayload) =>
    createAction(ActionTypes.MXT_EVENT, payload),
  mxtTokenExpired: () => createAction(ActionTypes.MXT_TOKEN_EXPIRED),
  journeySelected: (payload: MarxentJourneyPayload) =>
    createAction(ActionTypes.JOURNEY_SELECTED, payload),

  changeJsCommunicator: (jsCommunicator: JsCommunicator) =>
    createAction(ActionTypes.CHANGE_JS_COMMUNICATOR, jsCommunicator),

  autoSavePromptResponse: (
    eventUUID: string,
    response: AutoSavePromptResponse
  ) =>
    createAction(ActionTypes.AUTO_SAVE_PROMPT_RESPONSE, {
      eventUUID,
      response,
    }),
}

export type Actions = ActionsUnion<typeof actions>
