import { ActionTypes, Actions } from './actions'
import { UserState } from './interfaces'

export const initialState: UserState = {
  data: null,
  error: null,
}

export const reducer = (state = initialState, action: Actions): UserState => {
  switch (action.type) {
    case ActionTypes.SET_USER_DATA:
      return {
        ...state,
        data: action.payload.data,
      }

    case ActionTypes.DECODE_USER_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }

    default:
      return state
  }
}
