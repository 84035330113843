import * as React from 'react';
import classnames from 'classnames';
import getIcon from './icons';
import iconStyles from './icon.module.css';
export const Icon = ({ icon, 'aria-hidden': ariaHidden, altText, classes, color = 'primaryDark', dataTestId, fluid, inline, rPadding = 'none', lPadding = 'none', styles, size, title, onClick, }) => (React.createElement("i", { className: classnames(iconStyles.Icon, classes, iconStyles[`Icon-size-${size}`], color && iconStyles[`Icon-color-${color}`], {
        [iconStyles['Icon--isInline']]: inline,
        [iconStyles[`Icon--rPadding-${rPadding}`]]: rPadding !== 'none',
        [iconStyles[`Icon--lPadding-${lPadding}`]]: lPadding !== 'none',
        [iconStyles['Icon--isFluid']]: fluid,
    }, { [iconStyles['Icon--disabledPointer']]: title }), style: styles, "aria-hidden": ariaHidden, "data-test-id": dataTestId, "aria-label": title, title: title, onClick: onClick }, getIcon(icon, { altText })));
Icon.defaultProps = {
    size: 'm',
};
