import * as React from 'react';
import { _t } from '@hip/translations';
export const RadioSelectedIcon = ({ borderColour = '#A6A6A6', bulletColour = '#A6A6A6', fill = '#FFF', }) => {
    const alt = _t('icons.radio_selected');
    return (React.createElement("svg", { role: "img", "aria-label": alt, width: "24px", height: "24px", viewBox: "0 0 24 24", version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, alt),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("circle", { fill: fill, cx: 12, cy: 12, r: 10 }),
            React.createElement("circle", { fill: bulletColour, cx: 12, cy: 12, r: 5 }),
            React.createElement("path", { d: "M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 2a8 8 0 110 16 8 8 0 010-16z", fill: borderColour, fillRule: "nonzero" }))));
};
