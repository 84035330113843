import { OPCOS } from '@hip/interfaces'
import { CustomerDesignsConfig } from '@hip/service-config'
import { ActionTypes, Actions } from './actions'

export interface UtilsState {
  config: CustomerDesignsConfig | undefined
  opco: OPCOS
  token: string | undefined
  analytics: boolean
  cookies: boolean
  copied: boolean
}

export const initialState: UtilsState = {
  config: undefined,
  opco: undefined,
  token: undefined,
  analytics: false,
  cookies: false,
  copied: false,
}

type CombinedActions = Actions

export const reducer = (
  state = initialState,
  action: CombinedActions
): UtilsState => {
  switch (action.type) {
    case ActionTypes.SET_CONFIG:
      return {
        ...state,
        config: {
          ...action.payload,
        },
      }

    case ActionTypes.SET_OPCO:
      return {
        ...state,
        opco: action.payload,
      }

    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      }

    case ActionTypes.SET_ANALYTICS:
      return {
        ...state,
        analytics: action.payload.analytics,
      }

    case ActionTypes.SET_COOKIES:
      return {
        ...state,
        cookies: action.payload.cookies,
      }

    case ActionTypes.SET_COPIED:
      return {
        ...state,
        copied: action.payload.copied,
      }

    default:
      return state
  }
}
