import { createAction, ActionsUnion } from '@hip/helpers'
import {
  SaveDesignPayload,
  DuplicateDesignPayload,
  SetVendorPayload,
  SetMxtTokenPayload,
  SetDesignPayload,
  DirtyStatePayload,
} from './interfaces'

export enum ActionTypes {
  RESET = '@hip/design/RESET',
  SET_DESIGN = '@hip/design/SET_DESIGN',
  SAVE_DESIGN = '@hip/design/SAVE_DESIGN',
  SAVE_DESIGN_SUCCESS = '@hip/design/SAVE_DESIGN_SUCCESS',
  DUPLICATE = '@hip/design/DUPLICATE',
  SET_VENDOR = '@hip/design/SET_VENDOR',
  SET_MXT_TOKEN = '@hip/design/SET_MXT_TOKEN',
  LOAD_DESIGN = '@hip/design/LOAD_DESIGN',
  DIRTY_STATE_CHANGED = '@hip/design/DIRTY_STATE_CHANGED',
}

export const actions = {
  reset: () => createAction(ActionTypes.RESET),
  saveDesign: (payload: SaveDesignPayload) =>
    createAction(ActionTypes.SAVE_DESIGN, payload),
  setDesign: (payload: SetDesignPayload) =>
    createAction(ActionTypes.SET_DESIGN, payload),
  duplicate: (payload: DuplicateDesignPayload) =>
    createAction(ActionTypes.DUPLICATE, payload),
  setVendor: (payload: SetVendorPayload) =>
    createAction(ActionTypes.SET_VENDOR, payload),
  setMxtToken: (payload: SetMxtTokenPayload) =>
    createAction(ActionTypes.SET_MXT_TOKEN, payload),
  loadDesign: () => createAction(ActionTypes.LOAD_DESIGN),
  dirtyStateChanged: (payload: DirtyStatePayload) =>
    createAction(ActionTypes.DIRTY_STATE_CHANGED, payload),
}

export type Actions = ActionsUnion<typeof actions>
