import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Tracking } from '@hip/analytics'
import { Hammertime } from '@hip/components/src/components/hammertime'
import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'

import { OPCOS, Features } from '@hip/interfaces'
import { actions as hipCommunicatorActions } from '../../core/hip-communicator/actions'
import { actions as productActions } from '../../core/products/actions'
import { getConfig, getOPCO } from '../../core/utils/selectors'
import {
  getIsLoadingProducts,
  getProducts,
} from '../../core/products/selectors'
import { PrintableBom as PrintableBomView } from '../../components/printable-bom/printable-bom'

const PrintableBom: React.FC = () => {
  const dispatch = useDispatch()
  const { shareId, projectId, designId } = useParams() as any
  const config: ReturnType<typeof getConfig> = useSelector(getConfig)
  const opco: OPCOS = useSelector(getOPCO)

  const data = useSelector(getProducts)
  const isLoading = useSelector(getIsLoadingProducts)
  const isBarcodesFeatureEnabled = useSelector(
    getIsFeatureEnabledByConst(Features.BARCODES)
  )

  const onResize = height => {
    dispatch(hipCommunicatorActions.resized({ height }))
  }

  React.useEffect(() => {
    if ((projectId && designId) || shareId) {
      dispatch(productActions.loadProducts({ projectId, designId, shareId }))
    }
  }, [projectId, designId, shareId])

  if (isLoading || !data) return <Hammertime />

  return (
    <>
      {!isLoading && data && (
        <PrintableBomView
          data={data}
          barcodesEnabled={isBarcodesFeatureEnabled}
          onResize={onResize}
          opco={opco}
        />
      )}

      {config?.analytics?.enabled && (
        <Tracking
          env={config?.analytics.env}
          profile={config?.analytics.profile}
          config={config?.analytics.config}
        />
      )}
    </>
  )
}

export default PrintableBom
