import * as React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash/fp';
import { Text } from '../../text/text';
import { Icon } from '../../icon/icon';
import styles from './radio.css';
export const RawRadio = ({ name, label, input = {}, classes, dataTestId = 'raw-radio-label', }) => {
    if (!input.value) {
        input.value = label;
    }
    const icon = input.checked ? 'RADIO_SELECTED' : 'RADIO_UNSELECTED';
    return (React.createElement("label", { "data-test-id": dataTestId, "aria-checked": input.checked, className: classnames(styles.Radio, classes) },
        React.createElement("input", Object.assign({ type: "radio", name: name, className: styles.RadioInput }, input)),
        React.createElement(Icon, { icon: icon, size: "s", color: "primaryBrand", inline: true, rPadding: "m", classes: styles.RadioIcon }),
        label && (React.createElement(Text, { el: "span", vMargin: "none", "data-test-id": input.value }, label))));
};
// Standalone radio input
export const Radio = ({ name, label, action = noop, checked, classes, }) => (React.createElement(RawRadio, Object.assign({}, {
    name,
    classes,
    label,
    input: {
        onChange: () => action(),
        checked,
        value: String(checked),
    },
})));
