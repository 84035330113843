import { AccessDesign, AccessShareableDesign } from '@hip/events-service-types'
import { createAction, ActionsUnion } from '@hip/helpers'

export enum ActionTypes {
  ACCESS_DESIGN = '@hip/events/ACCESS_DESIGN',
  ACCESS_SHAREABLE_DESIGN = '@hip/events/ACCESS_SHAREABLE_DESIGN',
  RESET = '@hip/events/RESET',
}

export const actions = {
  reset: () => createAction(ActionTypes.RESET),
  accessDesign: (payload: AccessDesign) =>
    createAction(ActionTypes.ACCESS_DESIGN, payload),
  accessShareableDesign: (payload: AccessShareableDesign) =>
    createAction(ActionTypes.ACCESS_SHAREABLE_DESIGN, payload),
}

export type Actions = ActionsUnion<typeof actions>
