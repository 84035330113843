"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClientIdFromSessionStorage = exports.setClientIdToSessionStorage = void 0;
const EVENTS_SERVICE_CLIENT_ID_SESSION_KEY = 'EVENTS_SERVICE_CLIENT_ID';
function setClientIdToSessionStorage(id) {
    sessionStorage.setItem(EVENTS_SERVICE_CLIENT_ID_SESSION_KEY, id);
}
exports.setClientIdToSessionStorage = setClientIdToSessionStorage;
function getClientIdFromSessionStorage() {
    return sessionStorage.getItem(EVENTS_SERVICE_CLIENT_ID_SESSION_KEY);
}
exports.getClientIdFromSessionStorage = getClientIdFromSessionStorage;
