import { createAction, ActionsUnion } from '@hip/helpers'
import { SetNotificationPayload } from './interfaces'

export enum ActionTypes {
  SET = '@hip/notifications/SET',
  CLEAR = '@hip/notifications/CLEAR',
}

export const actions = {
  set: (payload: SetNotificationPayload) =>
    createAction(ActionTypes.SET, payload),
  clear: () => createAction(ActionTypes.CLEAR),
}

export type Actions = ActionsUnion<typeof actions>
