import React from 'react'
import classnames from 'classnames'

import { _t } from '@hip/translations'
import { OPCOS } from '@hip/interfaces'
import { dataTestId } from '@hip/test-attributes'
import { BrandIcon } from '@hip/components/src/components/brandIcon/brandIcon'
import { Button } from '@hip/components/src/components/button/button'
import { Text } from '@hip/components/src/components/text/text'
import { Icon } from '@hip/components/src/components/icon/icon'
import { IconSize } from '@hip/components/src/components/icon'

import { MobileMenuButton } from '../mobile-menu-button/mobile-menu-button'

import styles from './header.module.css'

interface HeaderProps {
  opco: OPCOS
  vendorId?: string
  handleNewDesignClick: () => void
  handleAppointmentClick: () => void
  handleDuplicate: () => void
  handleShare: () => void
  exit: () => void
}

export const Header: React.FC<HeaderProps> = ({
  opco,
  vendorId,
  handleNewDesignClick,
  handleAppointmentClick,
  handleDuplicate,
  handleShare,
  exit,
}: HeaderProps) => {
  const [active, setActive] = React.useState(false)
  const toggleMobileMenu = (): void => {
    setActive(!active)
  }

  return (
    <div className={styles.Header} data-test-id={dataTestId.cdt.layout.header}>
      <a className={styles.Logo} onClick={exit}>
        <BrandIcon opco={opco} />
      </a>
      <nav
        className={classnames(
          styles.HeaderNav,
          active && styles['HeaderNav-MobileVisible']
        )}
      >
        <ul className={styles.HeaderNavItems}>
          <HeaderNavItem
            onClick={handleNewDesignClick}
            icon={'PLUS'}
            iconSize={'m'}
            label={_t('header.create_new_design')}
            dataTestId={dataTestId.cdt.layout.btnCreateNewDesign}
          />

          {//TODO: fix it with feature toggle in HIP-4082
          opco === OPCOS.BDRO || opco === OPCOS.TPUK ? null : (
            <HeaderNavItem
              onClick={handleAppointmentClick}
              icon={'PEOPLE'}
              iconSize={'s'}
              label={_t('header.book')}
              classes={styles['HeaderNavItem-Primary']}
              dataTestId={dataTestId.cdt.layout.btnBookAnAppointment}
            />
          )}

          <HeaderNavItem
            onClick={handleDuplicate}
            icon={'FILES'}
            iconSize={'s'}
            label={_t('header.duplicate')}
            disabled={!vendorId}
            dataTestId={dataTestId.cdt.layout.btnDuplicate}
          />
          <HeaderNavItem
            onClick={handleShare}
            icon={'PERSON_PLUS'}
            iconSize={'s'}
            label={_t('header.share')}
            disabled={!vendorId}
            dataTestId={dataTestId.cdt.layout.btnShare}
          />
          <HeaderNavItem
            onClick={exit}
            icon={'DOOR_OPEN'}
            iconSize={'s'}
            label={_t('header.exit')}
            dataTestId={dataTestId.cdt.layout.btnExit}
          />
        </ul>
        <div className={styles.MobileMenuOverlay} onClick={toggleMobileMenu} />
      </nav>
      <div className={styles.MobileMenuButtonWrapper}>
        <MobileMenuButton active={active} handleClick={toggleMobileMenu} />
      </div>
    </div>
  )
}

interface HeaderItemProps {
  onClick: () => void
  iconSize: IconSize
  icon: string
  label: string
  classes?: string
  disabled?: boolean
  dataTestId?: string
}
const HeaderNavItem: React.FC<HeaderItemProps> = ({
  onClick,
  iconSize,
  icon,
  label,
  classes,
  disabled = false,
  dataTestId,
}) => (
  <li
    className={classnames(
      styles.HeaderNavItem,
      disabled && styles['HeaderNavItem-Disabled'],
      classes
    )}
  >
    <Button
      classes={styles.HeaderButton}
      type="naked"
      onClick={onClick}
      disabled={disabled}
      dataTestId={dataTestId}
    >
      <div className={styles.IconWrapper}>
        <Icon icon={icon} size={iconSize} color="white" />
      </div>
      <Text color="white" vMargin="none">
        {label}
      </Text>
    </Button>
  </li>
)
