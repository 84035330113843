import * as React from 'react';
import { _t } from '@hip/translations';
export const DownloadIcon = ({ altText }) => {
    const alt = altText || _t('icons.download');
    return (React.createElement("svg", { role: "img", "aria-label": alt, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, alt),
        React.createElement("g", { fill: "none", fillRule: "evenodd", transform: "translate(4, 3)" },
            React.createElement("path", { d: "M-4-3h24v24H-4z" }),
            React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M0 16h16v2H0z" }),
            React.createElement("path", { fill: "currentColor", d: "M8.962 13.107l.389-.389 4.057-4.057a.954.954 0 1 0-1.35-1.349L8.962 10.41l-.005-9.13a.956.956 0 0 0-1.912 0l.005 9.136-3.093-3.093a.956.956 0 1 0-1.352 1.352l4.688 4.688a1 1 0 0 0 1.414 0l.254-.254h.002v-.002z" }))));
};
