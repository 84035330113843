import * as React from 'react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { ErrorMessage } from '../messages';
import { Text } from '../text/text';
import { PrintedTableSpacer } from '../printedTableComponents/printedTableComponents';
import styles from './missingProducts.module.css';
export const MissingProducts = ({ missingProducts, }) => (React.createElement(React.Fragment, null,
    React.createElement(ErrorMessage, { classes: styles.MissingProducts, dataTestId: dataTestId.component.productList.missingProductMsg },
        React.createElement(Text, { el: "h3", weight: "bold" }, _t('product_bom.missing_products')),
        React.createElement(Text, null, `${missingProducts.join(', ')}.`)),
    React.createElement(PrintedTableSpacer, null)));
