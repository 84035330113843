import { createAction, ActionsUnion } from '@hip/helpers'
import { StockSummaryData } from '@hip/interfaces'
import {
  LoadProductsPayload,
  LoadStockDataPayload,
  SuccessLoadProductsPayload,
  ExportBOMToXMLPayload,
} from './interfaces'

export enum ActionTypes {
  LOAD_PRODUCTS = '@hip/products/LOAD_PRODUCTS',
  SUCCESS_LOAD_PRODUCTS = '@hip/products/SUCCESS_LOAD_PRODUCTS',
  LOAD_PRODUCTS_FAIL = '@hip/products/LOAD_PRODUCTS_FAIL',
  LOAD_STOCK_DATA = '@hip/products/LOAD_STOCK_DATA',
  LOAD_STOCK_DATA_SUCCESS = '@hip/products/LOAD_STOCK_DATA_SUCCESS',
  LOAD_STOCK_DATA_FAILURE = '@hip/products/LOAD_STOCK_DATA_FAILURE',
  EXPORT_BOM_TO_XML = '@hip/products/EXPORT_BOM_TO_XML',
  EXPORT_BOM_TO_XML_FAIL = '@hip/products/EXPORT_BOM_TO_XML_FAIL',
}

export const actions = {
  loadProducts: (payload: LoadProductsPayload) =>
    createAction(ActionTypes.LOAD_PRODUCTS, payload),
  successLoadProducts: (payload: SuccessLoadProductsPayload) =>
    createAction(ActionTypes.SUCCESS_LOAD_PRODUCTS, payload),
  loadProductsFail: () => createAction(ActionTypes.LOAD_PRODUCTS_FAIL),
  loadStockData: (payload: LoadStockDataPayload) =>
    createAction(ActionTypes.LOAD_STOCK_DATA, payload),
  loadStockDataSuccess: (payload: StockSummaryData) =>
    createAction(ActionTypes.LOAD_STOCK_DATA_SUCCESS, payload),
  loadStockDataFailure: (payload: Error) =>
    createAction(ActionTypes.LOAD_STOCK_DATA_FAILURE, payload),
  exportBOMToXML: (payload: ExportBOMToXMLPayload) =>
    createAction(ActionTypes.EXPORT_BOM_TO_XML, payload),
  exportBOMToXMLFail: () => createAction(ActionTypes.EXPORT_BOM_TO_XML_FAIL),
}

export type Actions = ActionsUnion<typeof actions>
