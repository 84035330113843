interface CreateMxtUrlParams {
  journey_id: string
  account: string
  storeId: string
  type?: string
}

export const createMxtUrl = (
  baseUrl: string,
  params: Partial<CreateMxtUrlParams>
): string => {
  const url = new URL(baseUrl)

  const entries = Object.entries(params).filter(([_, value]) => value)
  for (const [key, value] of entries) {
    url.searchParams.set(key, value)
  }
  return url.toString()
}

export const getProjectTypeFromURL = (url?: string) => {
  if (!url) return
  return new URL(url).searchParams.get('type')
}

export const mapProjectType = (type: string) => {
  if (!type) return

  const mapping = {
    BATHROOM: 'bath',
    KITCHEN: 'kit',
    BEDROOM_STORAGE: 'stor',
  }

  return mapping[type]
}
