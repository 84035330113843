import { ProductStockMessageProps } from '@hip/components/src/components/productList/productStockMessage/productStockMessage'
import { CDTProductGroup, DesignBom, StockSummaryData } from '@hip/interfaces'
import { flattenDeep } from 'lodash/fp'

import { _t } from '@hip/translations'

import { AVAILABILITY_INFORMATION_STOCKS } from '../../core/products/constants'

const ENOUGH_QUANTITY_COLORS = {
  DC: 'green',
  STORE: 'amber',
}

export const getProductsQuantitiesFromBom = (
  bom: DesignBom<CDTProductGroup>
): {
  [key: string]: number
} => {
  if (!bom) {
    return {}
  }
  return flattenDeep<{ ean: string; quantity: number }>(
    bom.groups.map(group => {
      if (!group?.assemblies) {
        return null
      }
      return group.assemblies.map(assembly => {
        if (!assembly.products) {
          return null
        }
        return assembly.products.map(product => {
          if (!product.ean) {
            return null
          }
          return {
            ean: product.ean,
            quantity: (product.quantity ?? 0) * (assembly.quantity ?? 0),
          }
        })
      })
    })
  )
    .filter(Boolean)
    .reduce((acc, el) => {
      const currentVal = acc[el.ean]
      acc[el.ean] =
        currentVal === undefined ? el.quantity : el.quantity + currentVal
      return acc
    }, {})
}

export interface ParseStockUIMessagesOptions {
  stockDataSummary: StockSummaryData
  stockDataError: Error
  bom: DesignBom<CDTProductGroup>
}

export interface AllStockUIMessages {
  general: string[]
  byProduct: {
    [key: string]: ProductStockMessageProps[]
  }
}

export const parseStockUIMessages = ({
  stockDataSummary,
  stockDataError,
  bom,
}: ParseStockUIMessagesOptions): AllStockUIMessages => {
  const general: AllStockUIMessages['general'] = []
  const byProduct: AllStockUIMessages['byProduct'] = {}

  if (stockDataError) {
    general.push(
      _t('product_bom.product_stock.general_messages.connection_problem')
    )
    return { general, byProduct }
  }

  if (bom?.groups) {
    const productsQuantityNeed = getProductsQuantitiesFromBom(bom)
    let hasMissingStockData = false
    let hasNotEnoughQuantity = false
    for (const [ean, needQuantity] of Object.entries(productsQuantityNeed)) {
      const stockSummary = stockDataSummary[ean]
      byProduct[ean] = []
      for (const stockSource of AVAILABILITY_INFORMATION_STOCKS) {
        if (stockSummary?.[stockSource]) {
          const stockQuantity = stockSummary[stockSource].value
          const isEnoughQuantity = stockQuantity >= needQuantity
          byProduct[ean].push({
            message: _t('product_bom.product_stock.message', {
              pcs: stockQuantity,
              store: _t(`product_bom.product_stock.store_names.${stockSource}`),
            }),
            color: isEnoughQuantity
              ? ENOUGH_QUANTITY_COLORS[stockSource]
              : 'red',
          })
          if (!isEnoughQuantity) {
            hasNotEnoughQuantity = true
          }
        } else {
          hasMissingStockData = true
          byProduct[ean].push({
            message: _t(
              `product_bom.product_stock.store_error_messages.${stockSource}`
            ),
            color: 'red',
          })
        }
      }
    }
    if (hasMissingStockData) {
      general.push(
        _t('product_bom.product_stock.general_messages.missed_stock_data')
      )
    }
    if (hasNotEnoughQuantity) {
      general.push(
        _t('product_bom.product_stock.general_messages.not_enough_quantity')
      )
    }
  }
  return { general, byProduct }
}
