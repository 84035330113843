"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
const helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["CLOSE_EVENTS_CHANNEL"] = "@events-service-client/CLOSE_EVENTS_CHANNEL";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    closeEventsChannel: () => helpers_1.createAction(ActionTypes.CLOSE_EVENTS_CHANNEL),
};
