import * as React from 'react';
import { _t } from '@hip/translations';
import { QUOTE_TYPE } from '@hip/interfaces';
import { copyToClipboard } from '@hip/helpers';
import { dataTestId } from '@hip/test-attributes';
import { Icon } from '../../../icon';
import { Button } from '../../../button/button';
import { SidebarCard } from '../sidebarCard/sidebarCard';
import styles from './quoteInfoCard.module.css';
export const QuoteInfoCard = ({ quote, ctaClickFn, }) => {
    const [quoteCopied, setQuoteCopied] = React.useState(false);
    const { quoteType, quoteId } = quote;
    const copyQuoteHandler = () => {
        copyToClipboard(quoteId);
        setQuoteCopied(true);
        setTimeout(() => {
            setQuoteCopied(false);
        }, 5000);
    };
    const content = {
        heading: quoteType === QUOTE_TYPE.QUOTE
            ? _t('product_bom.your_quote_id')
            : _t('product_bom.your_estimate_id'),
        notification: quoteType === QUOTE_TYPE.QUOTE
            ? _t('product_bom.info_quoted')
            : _t('product_bom.info_estimated'),
    };
    return (React.createElement(SidebarCard, { classes: styles.QuoteInfoCard, dataTestId: dataTestId.component.productList.quoteInfoContainer },
        React.createElement("h4", { "data-test-id": dataTestId.component.productList.titleQuoteInfo }, content.heading),
        React.createElement("div", { className: styles.QuoteID },
            quoteCopied && (React.createElement("div", { className: styles.QuoteIDCopiedFlag, "data-test-id": dataTestId.component.productList.flagCopyQuote },
                React.createElement("div", { className: styles.QuoteIDCopiedFlagPointer }),
                React.createElement("p", null, _t('product_bom.copied')))),
            React.createElement("p", { id: "quote-id" }, quoteId),
            React.createElement("a", { onClick: copyQuoteHandler, role: "button", className: styles.CopyQuoteIDButton, "data-test-id": dataTestId.component.productList.btnCopyQuote },
                React.createElement(Icon, { icon: "COPY_OUTLINE", size: "s" }))),
        React.createElement("p", null, content.notification),
        React.createElement(Button, { onClick: ctaClickFn, dataTestId: dataTestId.component.productList.btnNavigate }, _t('product_bom.view_quote'))));
};
