import React from 'react'

import { _t } from '@hip/translations'
import { Button } from '@hip/components/src/components/button/button'
import { Text } from '@hip/components/src/components/text/text'

import { Modal } from '../modal/modal'

import styles from './confirm-action.module.css'

interface ActionModalProps {
  show: boolean
  confirm: () => void
  reject: () => void
}

export const ConfirmActionModal: React.FC<ActionModalProps> = ({
  show,
  confirm,
  reject,
}: ActionModalProps) => (
  <Modal title={_t('confirm-action-modal.title')} show={show} onClose={reject}>
    <Text>{_t('confirm-action-modal.sure')}</Text>
    <div className={styles.Buttons}>
      <Button type="secondary" onClick={reject}>
        <Text vMargin="none" color="white">
          {_t('confirm-action-modal.reject')}
        </Text>
      </Button>
      <Button type="primary" onClick={confirm}>
        <Text vMargin="none" color="white">
          {_t('confirm-action-modal.confirm')}
        </Text>
      </Button>
    </div>
  </Modal>
)
