import * as React from 'react';
import { _t } from '@hip/translations';
import { QUOTE_TYPE, OPCOS } from '@hip/interfaces';
import { dataTestId } from '@hip/test-attributes';
import { BomPrice } from '../../../bomPrice/bomPrice';
import { Button } from '../../../button/button';
import { Icon } from '../../../icon';
import { Text } from '../../../text/text';
import { SidebarCard } from '../sidebarCard/sidebarCard';
import styles from './orderSummaryCard.module.css';
export const OrderSummaryCard = ({ opco, subTotal, disableCreateQuote, showCreateQuote, quote, financeLink, createQuoteFn, handleAddToBasket, handleExportBOMToXML, handlePrintBOM, }) => {
    const { quoteType, quoteId } = quote;
    const [installationRequired, setInstallationRequired] = React.useState(quoteType === QUOTE_TYPE.ESTIMATE);
    const disableRadioButton = Boolean(quoteType && quoteId);
    const showInstallationToggle = Boolean(createQuoteFn);
    return (React.createElement(SidebarCard, { classes: styles.OrderSummary, dataTestId: dataTestId.component.productList.orderSummaryContainer },
        React.createElement(Text, { el: "h2" }, _t('product_bom.order_summary')),
        showInstallationToggle && (React.createElement(React.Fragment, null,
            React.createElement(Text, { vMargin: "none", classes: styles.Installation }, _t('product_bom.installation_required')),
            React.createElement("fieldset", { className: styles.InstallationRadioButtons },
                React.createElement("label", null,
                    _t('generic.yes'),
                    React.createElement("input", { type: "radio", name: "installationRequired", onChange: () => setInstallationRequired(true), disabled: disableRadioButton, "data-test-id": dataTestId.component.productList.radioInstallation, value: installationRequired === true
                            ? 'installationRequired'
                            : 'installationNotRequired' })),
                React.createElement("label", null,
                    _t('generic.no'),
                    React.createElement("input", { type: "radio", name: "installationNotRequired", onChange: () => setInstallationRequired(false), disabled: disableRadioButton, "data-test-id": dataTestId.component.productList.radioInstallation, value: installationRequired === true
                            ? 'installationRequired'
                            : 'installationNotRequired' }))),
            opco === OPCOS.CAFR && installationRequired && (React.createElement(Text, { vMargin: "none", classes: styles.InstallationNotice }, _t('product_bom.installation_notice'))))),
        React.createElement("div", { className: styles.OrderPriceAndActions },
            React.createElement("div", null,
                React.createElement(Text, { el: "h4", classes: styles.Total }, _t('product_bom.total')),
                React.createElement(BomPrice, { amount: subTotal.amount, currency: subTotal.currency, displayPrice: true, dataTestId: dataTestId.component.productList.orderSummaryPrice })),
            React.createElement("div", { className: styles.ButtonContainer },
                React.createElement(Button, { classes: styles.Button, onClick: handlePrintBOM, type: "naked", dataTestId: dataTestId.component.productList.btnDownload },
                    React.createElement(Icon, { icon: "DOWNLOAD", size: "s", rPadding: "l" }),
                    React.createElement(Text, { weight: "bold", vMargin: "none" }, _t('product_bom.download'))),
                handleAddToBasket && (React.createElement(Button, { classes: styles.Button, onClick: handleAddToBasket, type: "tertiary", dataTestId: dataTestId.component.productList.btnAddToBasket },
                    React.createElement(Text, { weight: "bold", vMargin: "none" }, _t('product_bom.add_to_basket')))),
                handleExportBOMToXML && (React.createElement(Button, { classes: styles.Button, onClick: handleExportBOMToXML, type: "tertiary", dataTestId: dataTestId.component.productList.btnExportBOMToXML },
                    React.createElement(Text, { weight: "bold", vMargin: "none" }, _t('product_bom.export_bom_to_xml')))))),
        showCreateQuote && (React.createElement(Button, { disabled: disableCreateQuote, onClick: () => createQuoteFn(installationRequired ? QUOTE_TYPE.ESTIMATE : QUOTE_TYPE.QUOTE), dataTestId: dataTestId.component.productList.btnCreateNewQuote }, installationRequired
            ? _t('product_bom.create_estimate')
            : _t('product_bom.create_quote'))),
        financeLink && (React.createElement(Text, { weight: "bold" },
            React.createElement("a", { className: styles.Finance, href: financeLink, target: "_blank", rel: "noreferrer", role: "navigation", "data-test-id": dataTestId.component.productList.linkFinanceOptions }, _t('product_bom.view_finance_options'))))));
};
