import { SelectEffect, call, select } from 'redux-saga/effects'
import axios from 'axios'
import { chunk } from 'lodash/fp'

import { Features, OPCOS, StockSource } from '@hip/interfaces'
import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'
import { definitions as experienceDefinitions } from '@hip/experience-service-types'
import { _t } from '@hip/translations'

import { hipApi } from '../hip-api/sagas'
import { getOPCO } from '../utils/selectors'
import { getExperienceUrl } from '../utils/sagas'

import { GetAggregatedStockApiResponse } from './interfaces'
import { mergeGetAggregatedStockResponses } from './utils'

const AGGREGATED_STOCK_MAX_CHUNKS = 25

export function* getBomByDesignId(projectId: string, designId: string) {
  const baseUrl = yield call(getExperienceUrl, false)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/projects/${projectId}/designs/${designId}/bom`

  return yield hipApi.get<void, experienceDefinitions['GetBomResponse']>(url)
}

export function* getBomBySharedId(shareId: string) {
  const baseUrl = yield call(getExperienceUrl, true)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/share/${shareId}/bom`

  return yield hipApi.get<void, experienceDefinitions['GetBomResponse']>(url)
}

export interface FetchStockDataOptions {
  baseUrl
  appId
  eans: string[]
  storeId: string
  stockSources?: StockSource[]
}

export function* fetchStockData({
  baseUrl,
  appId,
  eans,
  storeId,
  stockSources,
}: FetchStockDataOptions): IterableIterator<
  SelectEffect | Promise<GetAggregatedStockApiResponse>
> {
  const opco: OPCOS = yield select(getOPCO)
  const isTykCustomersApisFeatureEnabled = yield select(
    getIsFeatureEnabledByConst(Features.TYK_CUSTOMER_APIS)
  )

  const xTenantHeader = isTykCustomersApisFeatureEnabled
    ? { 'x-tenant': opco }
    : {}

  try {
    const batchedEans = chunk<string>(AGGREGATED_STOCK_MAX_CHUNKS, eans)
    const promises: Promise<any>[] = []
    for (const chunkEans of batchedEans) {
      const url = new URL(`${baseUrl}/getAggregatedStock`)
      url.searchParams.append('filter[ean]', chunkEans.join(','))
      url.searchParams.append('filter[storeId]', storeId)
      if (stockSources) {
        url.searchParams.append('filter[source]', stockSources.join(','))
      }
      promises.push(
        axios.get<GetAggregatedStockApiResponse>(url.toString(), {
          headers: {
            Authorization: `Atmosphere atmosphere_app_id=${appId}`,
            ...xTenantHeader,
          },
        })
      )
    }

    return yield Promise.all(promises).then(responses =>
      mergeGetAggregatedStockResponses(responses.map(r => r.data))
    )
  } catch (e) {
    throw Error(_t('messages.request-failed'))
  }
}
