import React, { useReducer, useEffect } from 'react';
import classnames from 'classnames';
import styles from './dropdown.module.css';
const getInitialState = (initialValue) => ({
    open: false,
    value: initialValue,
    prevValue: initialValue,
    isDirty: false,
});
const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_OPEN':
            return Object.assign(Object.assign({}, state), { open: !state.open });
        case 'SET_VALUE':
            return {
                open: false,
                value: action.payload,
                prevValue: state.value,
                isDirty: true,
            };
        default:
            return state;
    }
};
export const Dropdown = ({ buttonComponent, optionComponent, options, onChange, classes, }) => {
    const ButtonComponent = buttonComponent;
    const OptionComponent = optionComponent;
    const initialState = getInitialState(options[0].value);
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        const { isDirty, value, prevValue } = state;
        const shouldEmitChange = isDirty && value !== prevValue;
        shouldEmitChange && onChange(state.value);
    }, [state]);
    const toggleOpen = () => dispatch({ type: 'TOGGLE_OPEN' });
    const selectOption = value => dispatch({ type: 'SET_VALUE', payload: value });
    const buttonLabel = options.find(option => option.value === state.value).label;
    return (React.createElement("div", { className: classnames(styles.DropdownWrapper, classes) },
        React.createElement(ButtonComponent, { onClick: toggleOpen, label: buttonLabel, isOpen: state.open }),
        state.open && (React.createElement("ul", { className: styles.OptionsList }, options.map(option => (React.createElement(OptionComponent, { key: option.label, label: option.label, dataTestId: option.dataTestId, onClick: () => selectOption(option.value) })))))));
};
