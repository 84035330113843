import { call, select } from 'redux-saga/effects'
import { definitions as designDefinitions } from '@hip/design-service-types'
import { definitions as experienceDefinitions } from '@hip/experience-service-types'
import { OPCOS } from '@hip/interfaces'
import { PROJECT_CATEGORY } from '@hip/rest-utils/providers/projectService/models'

import { hipApi } from '../hip-api/sagas'
import { getOPCO } from '../utils/selectors'
import { getExperienceUrl } from '../utils/sagas'

export function* getDesignById(projectId: string, designId: string) {
  const baseUrl = yield call(getExperienceUrl, false)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/projects/${projectId}/designs/${designId}/share`

  return yield hipApi.get<void, designDefinitions['GetDesignResponse']>(url)
}

export function* getDesignBySharedId(shareId: string) {
  const baseUrl = yield call(getExperienceUrl, true)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/share/${shareId}`

  return yield hipApi.get<
    void,
    designDefinitions['GetShareableDesignByDesignIdResponse']
  >(url)
}

export function* createDesign(projectId: string, vendorId: string) {
  const baseUrl = yield call(getExperienceUrl, false)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/projects/${projectId}/designs`

  return yield hipApi.post<designDefinitions['PostDesignRequest']>(url, {
    vendorId,
  })
}

export function* createSharedDesign(
  vendorId: string,
  category: PROJECT_CATEGORY
) {
  const baseUrl = yield call(getExperienceUrl, true)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/share`

  return yield hipApi.post<designDefinitions['PostShareableDesignResponse']>(
    url,
    { vendorId, category }
  )
}

export function* duplicateDesign(projectId: string, designId: string) {
  const baseUrl = yield call(getExperienceUrl, false)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/projects/${projectId}/designs/${designId}/copy`

  return yield hipApi.post<experienceDefinitions['Design']>(url)
}

export function* duplicateSharedDesign(shareId: string) {
  const baseUrl = yield call(getExperienceUrl, true)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/share/${shareId}/copy`

  return yield hipApi.post<experienceDefinitions['Design']>(url)
}
