import { ErrorPayload } from './interfaces'
import { ActionTypes, Actions } from './actions'

export interface ErrorsState {
  pageError?: ErrorPayload
  sectionError?: ErrorPayload
}

const initialState: ErrorsState = {
  pageError: undefined,
  sectionError: undefined,
}

export const reducer = (state = initialState, action: Actions): ErrorsState => {
  switch (action.type) {
    case ActionTypes.PAGE_ERROR:
      return {
        ...state,
        pageError: {
          code: action.payload.code,
          message: action.payload.message,
        },
      }

    case ActionTypes.SECTION_ERROR:
      return {
        ...state,
        sectionError: {
          code: action.payload.code,
          message: action.payload.message,
        },
      }

    default:
      return state
  }
}
