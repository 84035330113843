import React from 'react'
import { Switch, Route } from 'react-router'
import {
  baseShareRoute,
  createProjectRoute,
  createShareRoute,
  createBomProjectRoute,
  createBomShareRoute,
  createPrintBomProjectRoute,
  createPrintBomShareRoute,
  createFeatureTogglesRoute,
} from '@hip/helpers'
import MarxentClient from './containers/marxent-client/marxent-client'
import Bom from './containers/bom/bom'
import PrintableBom from './containers/printable-bom/printable-bom'
import { FeatureTogglesDashboard } from './containers/featureTogglesDashboard/featureTogglesDashboard'

export const Routes: React.FC = () => (
  <Switch>
    <Route exact path={createProjectRoute()}>
      <MarxentClient />
    </Route>
    <Route exact path={createShareRoute(baseShareRoute)}>
      <MarxentClient />
    </Route>
    <Route exact path={createBomProjectRoute()}>
      <Bom />
    </Route>
    <Route exact path={createBomShareRoute(baseShareRoute)}>
      <Bom />
    </Route>
    <Route exact path={createPrintBomProjectRoute()}>
      <PrintableBom />
    </Route>
    <Route exact path={createPrintBomShareRoute(baseShareRoute)}>
      <PrintableBom />
    </Route>
    <Route exact path={createFeatureTogglesRoute()}>
      <FeatureTogglesDashboard />
    </Route>
  </Switch>
)
