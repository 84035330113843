import {
  takeLatest,
  PutEffect,
  put,
  call,
  fork,
  CallEffect,
  ForkEffect,
  select,
  SelectEffect,
} from 'redux-saga/effects'
import { _t } from '@hip/translations'
import {
  connectToEventsService,
  watchSocketEvents,
  emitAccessDesign,
  emitAccessShareableDesign,
  disconnectFromEventsService,
} from '@hip/events-service-client'
import {
  ACCESS_DESIGN_EVENTS,
  ACCESS_SHAREABLE_DESIGN_EVENTS,
  AccessDesignSuccess,
  AccessDesignError,
} from '@hip/events-service-types'

import { actions as designActions } from '../design/actions'
import { actions as notificationsActions } from '../notifications/actions'
import {
  getAtmosphereId,
  getEventService,
  getIsAuthenticatedShareFlow,
  getIsTykAuthenticatedEventsWsEnabled,
  getToken,
  getUnauthenticatedEventService,
} from '../utils/selectors'
import { TimeoutTypes } from '../utils/interfaces'
import {
  actions as hipEventsActions,
  ActionTypes as HipEventsActionTypes,
} from './actions'
import { getIsTykUnauthenticatedEventsWs } from './selectors'

function* handleAccessDesignSuccess(
  response: AccessDesignSuccess
): IterableIterator<
  | SelectEffect
  | PutEffect<ReturnType<typeof notificationsActions.set>>
  | PutEffect<ReturnType<typeof designActions.setVendor>>
  | PutEffect<ReturnType<typeof designActions.setMxtToken>>
> {
  const { token, accessRestrictedEditSessionInUse, vendorId = '' } = response

  if (!token) {
    yield put(
      notificationsActions.set({
        name: TimeoutTypes.NOTIFICATION_TIMEOUT,
        type: 'danger',
        body: _t('messages.error'),
        close: true,
      })
    )
    return
  }

  yield put(designActions.setVendor({ vendor: vendorId }))
  yield put(designActions.setMxtToken({ mxtToken: token }))

  if (accessRestrictedEditSessionInUse) {
    yield put(
      notificationsActions.set({
        name: TimeoutTypes.NOTIFICATION_TIMEOUT,
        type: 'danger',
        body: _t('messages.more-than-one-user'),
        close: true,
      })
    )
  }
}

function* handleAccessDesignError(
  response: AccessDesignError
): IterableIterator<PutEffect<ReturnType<typeof notificationsActions.set>>> {
  switch (response.code) {
    case 404:
      yield put(
        notificationsActions.set({
          name: TimeoutTypes.NOTIFICATION_TIMEOUT,
          type: 'danger',
          body: _t('messages.no-design'),
          close: true,
        })
      )
      break

    default:
      yield put(
        notificationsActions.set({
          name: TimeoutTypes.NOTIFICATION_TIMEOUT,
          type: 'danger',
          body: _t('messages.error'),
          close: true,
        })
      )
  }
}

function* getEventsService() {
  const eventService: ReturnType<typeof getEventService> = yield select(
    getEventService
  )

  if (
    !eventService ||
    // @ts-ignore
    !eventService.api ||
    // @ts-ignore
    !eventService.api.url ||
    // @ts-ignore
    !eventService.api.pathname
  ) {
    throw Error(_t('messages.error'))
  }

  // @ts-ignore
  return eventService?.api
}

function* getUnauthenticatedEventsService() {
  const eventService: ReturnType<typeof getEventService> = yield select(
    getUnauthenticatedEventService
  )

  if (
    !eventService ||
    // @ts-ignore
    !eventService.api ||
    // @ts-ignore
    !eventService.api.url ||
    // @ts-ignore
    !eventService.api.pathname
  ) {
    throw Error(_t('messages.error'))
  }

  // @ts-ignore
  return eventService?.api
}

export function* connectToUnauthenticatedEventsService(): IterableIterator<
  SelectEffect | CallEffect
> {
  // @ts-ignore
  const { url, pathname } = yield call(getUnauthenticatedEventsService)
  const atomsphereId = yield select(getAtmosphereId)

  const isTykUnauthenticatedEventsWs = yield select(
    getIsTykUnauthenticatedEventsWs
  )

  yield call(connectToEventsService, {
    baseUrl: url,
    path: pathname,
    ...(isTykUnauthenticatedEventsWs
      ? {
          query: {
            authKey: atomsphereId,
          },
          transports: ['websocket'],
        }
      : {
          authorization: `Atmosphere atmosphere_app_id="${atomsphereId}"`,
          transports: ['polling'],
        }),
  })
}

export function* connectToAuthenticatedEventsService(): IterableIterator<
  SelectEffect | PutEffect | CallEffect
> {
  const token = yield select(getToken)

  if (!token) {
    return yield put(
      notificationsActions.set({
        name: TimeoutTypes.NOTIFICATION_TIMEOUT,
        type: 'danger',
        body: _t('messages.error'),
        close: true,
      })
    )
  }

  // @ts-ignore
  const { url, pathname } = yield call(getEventsService)

  const isTykAuthenticatedEventsWsEnabled = yield select(
    getIsTykAuthenticatedEventsWsEnabled
  )

  yield call(connectToEventsService, {
    baseUrl: url,
    path: pathname,
    ...(isTykAuthenticatedEventsWsEnabled
      ? {
          query: {
            authKey: `Bearer ${token}`,
          },
          transports: ['websocket'],
        }
      : {
          authorization: `Bearer ${token}`,
          transports: ['polling'],
        }),
  })
}

export function* handleAccessShareableDesign(
  action: ReturnType<typeof hipEventsActions.accessShareableDesign>
): IterableIterator<SelectEffect | CallEffect | ForkEffect> {
  const isAuthenticatedShareFlow = yield select(getIsAuthenticatedShareFlow)
  if (isAuthenticatedShareFlow) {
    yield call(connectToAuthenticatedEventsService)
  } else {
    yield call(connectToUnauthenticatedEventsService)
  }

  yield fork(watchSocketEvents, [
    {
      type: ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_SUCCESS,
      handler: handleAccessDesignSuccess,
    },
    {
      type: ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_ERROR,
      handler: handleAccessDesignError,
    },
  ])

  yield call(emitAccessShareableDesign, action.payload)
}

export function* handleAccessDesign(
  action: ReturnType<typeof hipEventsActions.accessDesign>
): IterableIterator<CallEffect | ForkEffect> {
  yield call(connectToAuthenticatedEventsService)

  yield fork(watchSocketEvents, [
    {
      type: ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_SUCCESS,
      handler: handleAccessDesignSuccess,
    },
    {
      type: ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_ERROR,
      handler: handleAccessDesignError,
    },
  ])
  yield call(emitAccessDesign, action.payload)
}

export function* handleReset(): IterableIterator<CallEffect> {
  yield call(disconnectFromEventsService)
}

export function* sagas(): IterableIterator<
  ReturnType<typeof fork> | ReturnType<typeof takeLatest>
> {
  yield takeLatest(HipEventsActionTypes.ACCESS_DESIGN, handleAccessDesign)
  yield takeLatest(
    HipEventsActionTypes.ACCESS_SHAREABLE_DESIGN,
    handleAccessShareableDesign
  )
  yield takeLatest(HipEventsActionTypes.RESET, handleReset)
}
