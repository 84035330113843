"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsFeatureError = exports.getIsFeatureEnabledByConst = void 0;
const reselect_1 = require("reselect");
const getRootState = (state) => state === null || state === void 0 ? void 0 : state.features;
const getFeatureKey = (_state, featureKey) => featureKey;
const getIsFeatureEnabled = (features, featureKey) => {
    return Boolean(features.list[featureKey]);
};
const getIsFeatureEnabledByConst = (featureKey) => (state) => reselect_1.createSelector(getRootState, getFeatureKey, getIsFeatureEnabled)(state, featureKey);
exports.getIsFeatureEnabledByConst = getIsFeatureEnabledByConst;
exports.getIsFeatureError = reselect_1.createSelector(getRootState, state => state.error);
