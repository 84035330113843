import { ActionTypes, Actions } from './actions'

import { ProjectState } from './interfaces'

export const defaultState: ProjectState = {
  isLoading: false,
  data: null,
  error: null,
}

export const reducer = (
  state: ProjectState = defaultState,
  action: Actions
): ProjectState => {
  switch (action.type) {
    case ActionTypes.RESET:
      return { ...defaultState }

    case ActionTypes.LOAD_PROJECT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case ActionTypes.LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }

    case ActionTypes.LOAD_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        error:
          action.payload instanceof Error
            ? action.payload.message
            : action.payload,
      }

    default:
      return state
  }
}
