import React, { Dispatch } from 'react'
import { useDispatch } from 'react-redux'

import { _t } from '@hip/translations'
import {
  actions as analyticsActions,
  Actions as AnalyticsActions,
} from '@hip/analytics'
import { dataTestId } from '@hip/test-attributes'
import { Button } from '@hip/components/src/components/button/button'

import { ANALYTICS } from '../../core/analytics/constants'
import { Facebook } from '../icons/facebook/facebook'
import { Twitter } from '../icons/twitter/twitter'
import { Whatsapp } from '../icons/whatsapp/whatsapp'
import { Email } from '../icons/email/email'

import './share-buttons.module.css'

export enum SHARE_OPTIONS {
  EMAIL = 'email',
  WA = 'whatsapp',
  FB = 'facebook',
  TW = 'twitter',
}

export const ShareButtonTypes = [
  'email',
  'facebook',
  'whatsapp',
  'twitter',
] as const
type ShareButtonTypes = typeof ShareButtonTypes
type ShareButtonVariants = ShareButtonTypes[number]

interface ShareButtonsProps {
  variant: ShareButtonVariants
  link: string
}

const ShareLink = (
  dispatch: Dispatch<AnalyticsActions>,
  type: SHARE_OPTIONS,
  link: string
): void => {
  const encodedLink = encodeURIComponent(link)
  const encodedTitle = encodeURIComponent(_t('email.subject'))
  const encodedBody = encodeURIComponent(
    _t('email.body').replace(/%link%/, link)
  )

  switch (type) {
    case SHARE_OPTIONS.EMAIL:
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.SHARE_EMAIL,
        })
      )
      window.open(
        `mailto:?subject=${encodedTitle}&body=${encodedBody}`,
        '_blank'
      )
      break

    case SHARE_OPTIONS.WA:
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.SHARE_WHATS_APP,
        })
      )
      window.open(`https://api.whatsapp.com/send?text=${encodedBody}`, '_blank')
      break

    case SHARE_OPTIONS.FB:
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.SHARE_FACEBOOK,
        })
      )
      window.open(
        `https://www.facebook.com/sharer.php?u=${encodedLink}`,
        '_blank'
      )
      break

    case SHARE_OPTIONS.TW:
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.SHARE_TWITTER,
        })
      )
      window.open(
        `http://twitter.com/share?text=${encodedTitle}&url=${encodedLink}`,
        '_blank'
      )
      break
  }
}

export const ShareButtons: React.FC<ShareButtonsProps> = ({
  variant,
  link,
}: ShareButtonsProps) => {
  const dispatch = useDispatch()
  if (link === '') {
    return <></>
  }

  const shareEmail = (): void => {
    ShareLink(dispatch, SHARE_OPTIONS.EMAIL, link)
  }

  const shareFB = (): void => {
    ShareLink(dispatch, SHARE_OPTIONS.FB, link)
  }

  const shareWA = (): void => {
    ShareLink(dispatch, SHARE_OPTIONS.WA, link)
  }

  const shareTW = (): void => {
    ShareLink(dispatch, SHARE_OPTIONS.TW, link)
  }

  switch (variant) {
    case 'email':
      return (
        <Button
          type="naked"
          classes="share-button"
          onClick={shareEmail}
          dataTestId={dataTestId.cdt.shareDesign.btnSendViaEmail}
        >
          <Email />
        </Button>
      )
    case 'facebook':
      return (
        <Button
          type="naked"
          classes="share-button"
          onClick={shareFB}
          dataTestId={dataTestId.cdt.shareDesign.btnSendViaFacebook}
        >
          <Facebook />
        </Button>
      )
    case 'whatsapp':
      return (
        <Button
          type="naked"
          classes="share-button"
          onClick={shareWA}
          dataTestId={dataTestId.cdt.shareDesign.btnSendViaWhatsApp}
        >
          <Whatsapp />
        </Button>
      )
    case 'twitter':
      return (
        <Button
          type="naked"
          classes="share-button"
          onClick={shareTW}
          dataTestId={dataTestId.cdt.shareDesign.btnSendViaTwitter}
        >
          <Twitter />
        </Button>
      )
  }
}
