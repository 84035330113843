import { ActionTypes, Actions } from './actions'
import { MarxentJourneyType } from './interfaces'

export interface MarxentState {
  journey: MarxentJourneyType
}

export const DEFAULT_STATE: MarxentState = {
  journey: undefined,
}

export const reducer = (
  state: MarxentState = DEFAULT_STATE,
  action: Actions
): MarxentState => {
  switch (action.type) {
    case ActionTypes.JOURNEY_SELECTED:
      return {
        ...state,
        journey: action.payload.journey,
      }

    default:
      return state
  }
}
