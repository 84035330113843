import { eventChannel } from 'redux-saga'
import { push } from 'connected-react-router'
import {
  takeLatest,
  PutEffect,
  put,
  call,
  CallEffect,
  TakeEffect,
  take,
  ForkEffect,
  fork,
  SelectEffect,
  select,
  race,
  delay,
} from 'redux-saga/effects'
import { _t } from '@hip/translations'

import hipCommunicator, {
  COMMUNICATOR_TYPES,
  EVENTS as COMMUNICATOR_EVENTS,
  MSG_NAMESPACE,
} from '@hip/communicator'
import { createProjectRoute, createShareRoute } from '@hip/helpers'

import { actions as notificationsActions } from '../notifications/actions'
import {
  actions as productsActions,
  ActionTypes as ProductsActionTypes,
} from '../products/actions'
import { actions as designActions } from '../design/actions'
import { actions as hipEventsActions } from '../hip-events/actions'
import { actions as errorsActions } from '../errors/actions'
import { actions as utilsActions } from '../utils/actions'
import { getAllowedOrigins, getToken, getProjectId } from '../utils/selectors'
import { actions as marxentActions } from '../marxent/actions'
import { mapProjectCategoryToMxtJourney } from '../marxent/utils'
import {
  actions as hipCommunicatorActions,
  ActionTypes as HipCommunicatorActionTypes,
} from './actions'

let hipCommunicatorPost

const createChannel = (channel: any, allowedOrigins: string[]) =>
  eventChannel(emit => {
    const listener: any = (event: MessageEvent) => {
      const message = event.data

      if (
        !(event.source instanceof MessagePort) &&
        message.namespace === MSG_NAMESPACE &&
        (allowedOrigins.includes('*') ||
          allowedOrigins.some(allowedOrigin =>
            event.origin.includes(allowedOrigin)
          ))
      ) {
        switch (message.kind) {
          case COMMUNICATOR_EVENTS.POST_AUTH:
            emit({ type: COMMUNICATOR_EVENTS.POST_AUTH, event: message })
            break

          case COMMUNICATOR_EVENTS.POST_CATEGORY:
            emit({ type: COMMUNICATOR_EVENTS.POST_CATEGORY, event: message })
            break

          case COMMUNICATOR_EVENTS.POST_PRODUCTS:
            emit({ type: COMMUNICATOR_EVENTS.POST_PRODUCTS, event: message })
            break

          case COMMUNICATOR_EVENTS.GET_PRODUCTS_FAIL:
            emit({ type: COMMUNICATOR_EVENTS.GET_PRODUCTS_FAIL })
            break

          case COMMUNICATOR_EVENTS.POST_ANALYTICS:
            emit({ type: COMMUNICATOR_EVENTS.POST_ANALYTICS, event: message })
            break

          case COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN_SUCCESS:
            emit({
              type: COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN_SUCCESS,
              event: message,
            })
            break

          case COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN_FAIL:
            emit({
              type: COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN_FAIL,
              event: message,
            })
            break
        }
      }
    }

    channel.addEventListener('message', listener)

    return (): void => {
      channel.removeEventListener('message', listener)
    }
  })

export function* watchEvents(): IterableIterator<
  | SelectEffect
  | CallEffect<ReturnType<typeof createChannel>>
  | TakeEffect
  | CallEffect<ReturnType<typeof channel.close>>
  | PutEffect<ReturnType<typeof utilsActions.setToken>>
  | PutEffect<ReturnType<typeof utilsActions.setAnalytics>>
  | PutEffect<ReturnType<typeof notificationsActions.set>>
  | PutEffect<ReturnType<typeof designActions.loadDesign>>
  | PutEffect<ReturnType<typeof marxentActions.journeySelected>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
  | PutEffect<ReturnType<typeof productsActions.successLoadProducts>>
  | PutEffect<ReturnType<typeof productsActions.loadProductsFail>>
  | PutEffect<ReturnType<typeof hipCommunicatorActions.refreshAuthTokenSuccess>>
  | PutEffect<ReturnType<typeof hipCommunicatorActions.refreshAuthTokenFail>>
> {
  const allowedOrigins = yield select(getAllowedOrigins)
  const channel: any = yield call(createChannel, window, allowedOrigins)

  while (true) {
    try {
      const payload: any = yield take(channel)

      if (payload) {
        switch (payload.type) {
          case COMMUNICATOR_EVENTS.POST_AUTH: {
            // if there was not a token previously, events connectivity will not have been initialised
            yield put(utilsActions.setToken({ token: payload.event.data }))
            yield put(designActions.loadDesign())
            break
          }

          case COMMUNICATOR_EVENTS.POST_PRODUCTS: {
            yield put(productsActions.successLoadProducts(payload.event.data))
            break
          }

          case COMMUNICATOR_EVENTS.POST_CATEGORY: {
            const journey = mapProjectCategoryToMxtJourney(payload.event.data)
            yield put(marxentActions.journeySelected({ journey }))
            break
          }

          case COMMUNICATOR_EVENTS.GET_PRODUCTS_FAIL: {
            yield put(productsActions.loadProductsFail())
            break
          }

          case COMMUNICATOR_EVENTS.POST_ANALYTICS: {
            yield put(
              utilsActions.setAnalytics({ analytics: payload.event.data })
            )
            break
          }

          case COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN_SUCCESS: {
            yield put(utilsActions.setToken({ token: payload.event.data }))
            yield put(hipCommunicatorActions.refreshAuthTokenSuccess())
            break
          }

          case COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN_FAIL: {
            yield put(hipCommunicatorActions.refreshAuthTokenFail())
            break
          }
        }
      }
    } catch (e) {
      yield put(errorsActions.pageError(e))
      yield call(channel.close)
    }
  }
}

function* bootstrap(): IterableIterator<
  | SelectEffect
  | ForkEffect
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    hipCommunicatorPost = hipCommunicator.post(document.referrer, window.parent)

    yield fork(watchEvents)

    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.LOADED)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* designCreated({
  payload: { projectId, designId, shareId },
}: ReturnType<typeof hipCommunicatorActions.designCreated>): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(
      hipCommunicatorPost,
      COMMUNICATOR_EVENTS.DESIGN_CREATED,
      shareId
        ? { shareId }
        : {
            projectId,
            designId,
          }
    )
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* bookAppointment(): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.BOOK_APPOINTMENT)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* exit(): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.EXIT)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* bom(): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.BOM)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* showPrintedBom(): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.PRINTED_BOM)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* addToBasket({
  payload: { products },
}: ReturnType<typeof hipCommunicatorActions.addToBasket>): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.ADD_TO_BASKET, products)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* getProducts({
  payload: { products },
}: ReturnType<typeof hipCommunicatorActions.getProducts>) {
  const TIMEOUT = 15000
  yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.GET_PRODUCTS, products)
  const { success, fail } = yield race({
    success: take(ProductsActionTypes.SUCCESS_LOAD_PRODUCTS),
    fail: delay(TIMEOUT),
  })
  if (success) return
  else if (fail)
    return yield put(
      yield put(
        errorsActions.pageError({
          code: 400,
          message: _t('messages.request-timed-out'),
        })
      )
    )
}

function* resized({
  payload: { height },
}: ReturnType<typeof hipCommunicatorActions.resized>): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.RESIZED, height)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* closeBom(): IterableIterator<
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.CLOSE_BOM)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* createNewDesign(): IterableIterator<
  | SelectEffect
  | PutEffect
  | PutEffect<ReturnType<typeof designActions.reset>>
  | PutEffect<ReturnType<typeof hipEventsActions.reset>>
  | PutEffect<ReturnType<typeof utilsActions.init>>
  | CallEffect<ReturnType<typeof hipCommunicatorPost>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    const projectId = yield select(getProjectId)
    yield put(
      push(projectId ? createProjectRoute(projectId) : createShareRoute(''))
    )
    yield put(designActions.reset())
    yield put(hipEventsActions.reset())
    yield put(utilsActions.init({ type: COMMUNICATOR_TYPES.MXT }))
    yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.CREATE_NEW_DESIGN)
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

export function* refreshAuthToken() {
  yield call(hipCommunicatorPost, COMMUNICATOR_EVENTS.REFRESH_AUTH_TOKEN)
  const { success, fail } = yield race({
    success: take(HipCommunicatorActionTypes.REFRESH_AUTH_TOKEN_SUCCESS),
    fail: take(HipCommunicatorActionTypes.REFRESH_AUTH_TOKEN_FAIL),
  })

  if (success) return yield select(getToken)
  else if (fail) return null
}

export function* sagas(): IterableIterator<ReturnType<typeof takeLatest>> {
  yield takeLatest(HipCommunicatorActionTypes.BOOTSTRAP, bootstrap)
  yield takeLatest(HipCommunicatorActionTypes.RESIZED, resized)
  yield takeLatest(HipCommunicatorActionTypes.BOM, bom)
  yield takeLatest(HipCommunicatorActionTypes.DESIGN_CREATED, designCreated)
  yield takeLatest(HipCommunicatorActionTypes.BOOK_APPOINTMENT, bookAppointment)
  yield takeLatest(HipCommunicatorActionTypes.EXIT, exit)
  yield takeLatest(
    HipCommunicatorActionTypes.CREATE_NEW_DESIGN,
    createNewDesign
  )
  yield takeLatest(HipCommunicatorActionTypes.GET_PRODUCTS, getProducts)
  yield takeLatest(HipCommunicatorActionTypes.CLOSE_BOM, closeBom)
  yield takeLatest(HipCommunicatorActionTypes.SHOW_PRINTED_BOM, showPrintedBom)
  yield takeLatest(HipCommunicatorActionTypes.ADD_TO_BASKET, addToBasket)
}
