import { createSelector } from 'reselect'
import { ApplicationState } from '../../interfaces'
import { NotificationsState } from './reducer'

const getRoot = ({ notifications }: ApplicationState): NotificationsState =>
  notifications

export const getNotification = createSelector(
  getRoot,
  state => state && state.notification
)
