"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToAccessDesignError = exports.subscribeToAccessDesignSuccess = exports.subscribeToAccessDesign = void 0;
const events_service_types_1 = require("@hip/events-service-types");
const createSubscriber_1 = require("../helpers/createSubscriber");
const subscribeToAccessDesign = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN, listener);
exports.subscribeToAccessDesign = subscribeToAccessDesign;
const subscribeToAccessDesignSuccess = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_SUCCESS, listener);
exports.subscribeToAccessDesignSuccess = subscribeToAccessDesignSuccess;
const subscribeToAccessDesignError = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_ERROR, listener);
exports.subscribeToAccessDesignError = subscribeToAccessDesignError;
