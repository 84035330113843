import * as React from 'react';
import { Text } from '../text/text';
import { BomPrice } from '../bomPrice/bomPrice';
import { PrintedTableHeader, PrintedTableRow, PrintedTableSpacer, } from '../printedTableComponents/printedTableComponents';
import { PrintedEnhancedBomDetailedTableRow } from '../printedEnhancedBomDetailedTableRow/printedEnhancedBomDetailedTableRow';
import { Barcode } from '../barcode/barcode';
import styles from './printedEnhancedBomDetailedTable.module.css';
// PrintedEnhancedBomDetailedTable is wrapped in a fragment intentionally because the DynamicPage
// component with which it is used slices it's immediate children into sub-arrays to be rendered
// across multiple pages when necessary. Returning this table wrapped in a single element
// would prevent this from working correctly.
// Ternary condition inside render function means that the table header is grouped with the first
// row of the table. This means that the two will never be rendered on separate pages.
// Spacer is rendered after final row so as to provide visual separation between tables
export const PrintedEnhancedBomDetailedTable = ({ title, quantity, unitPrice, products, barcodesEnabled, }) => (React.createElement(React.Fragment, null, products.map((product, i) => {
    const isFirstRow = i === 0;
    const isFinalRow = i === products.length - 1;
    const productRowClasses = barcodesEnabled
        ? styles.ProductRowNoBorderBottom
        : undefined;
    return (React.createElement(React.Fragment, { key: `DetailedTable__${product.name}__${i}` },
        isFirstRow ? (React.createElement("div", null,
            React.createElement(PrintedTableHeader, null,
                React.createElement(Text, { weight: "bold", classes: styles.ProductNameCell }, title),
                React.createElement(Text, { weight: "bold", classes: styles.ProductQuantityCell },
                    "x",
                    quantity),
                React.createElement("div", { className: styles.ProductPriceCell },
                    React.createElement(BomPrice, { amount: unitPrice.amount, currency: unitPrice.currency, displayPrice: true, size: "xxl" }))),
            React.createElement(PrintedTableRow, { classes: productRowClasses },
                React.createElement(PrintedEnhancedBomDetailedTableRow, { product: product })),
            barcodesEnabled && (React.createElement(PrintedTableRow, { classes: styles.BarcodeRow },
                React.createElement(Barcode, { ean: product.ean }))))) : (React.createElement("div", null,
            React.createElement(PrintedTableRow, { classes: productRowClasses },
                React.createElement(PrintedEnhancedBomDetailedTableRow, { product: product })),
            barcodesEnabled && (React.createElement(PrintedTableRow, { classes: styles.BarcodeRow },
                React.createElement(Barcode, { ean: product.ean }))))),
        isFinalRow && React.createElement(PrintedTableSpacer, null)));
})));
