import { ChevronDownIcon } from './chevronDown';
import { KitchenIcon } from './kitchen';
import { ChevronUpIcon } from './chevronUp';
import { CopyOutlineIcon } from './copyOutline';
import { CalendarIcon } from './calendar';
import { SpinnerIcon } from './spinner';
import { ErrorCircleIcon, ErrorCircleGreyIcon } from './errorCircle';
import { WarningCircleIcon, WarningCircleRedIcon, WarningCircleWhiteIcon, } from './warningCircle';
import { CloseIcon } from './closeIcon';
import { PlusIcon } from './plus';
import { PeopleIcon } from './people';
import { FilesIcon } from './files';
import { PersonPlusIcon } from './personPlus';
import { DoorOpenIcon } from './doorOpen';
import { DownloadIcon } from './download';
import { RadioSelectedIcon } from './radioSelected';
import { RadioUnselectedIcon } from './radioUnselected';
import { PrintIcon } from './print';
import { BasketEmptyIcon } from './basketEmpty';
export const icons = {
    CALENDAR: CalendarIcon,
    CLOSE: CloseIcon,
    COPY_OUTLINE: CopyOutlineIcon,
    CHEVRON_DOWN: ChevronDownIcon,
    CHEVRON_UP: ChevronUpIcon,
    DOOR_OPEN: DoorOpenIcon,
    DOWNLOAD: DownloadIcon,
    ERROR_CIRCLE: ErrorCircleIcon,
    ERROR_CIRCLE_GREY: ErrorCircleGreyIcon,
    FILES: FilesIcon,
    KITCHEN: KitchenIcon,
    PEOPLE: PeopleIcon,
    PERSON_PLUS: PersonPlusIcon,
    PRINT: PrintIcon,
    PLUS: PlusIcon,
    RADIO_SELECTED: RadioSelectedIcon,
    RADIO_UNSELECTED: RadioUnselectedIcon,
    SPINNER: SpinnerIcon,
    WARNING_CIRCLE: WarningCircleIcon,
    WARNING_CIRCLE_RED: WarningCircleRedIcon,
    WARNING_CIRCLE_WHITE: WarningCircleWhiteIcon,
    BASKET_EMPTY: BasketEmptyIcon,
};
export default (id, props = {}) => {
    return icons[id](props);
};
