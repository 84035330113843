import * as React from 'react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { SidebarCard } from '../sidebarCard/sidebarCard';
import { SidebarCardHeader } from '../sidebarCard/sidebarCardHeader/sidebarCardHeader';
import { Icon } from '../../../icon';
import { Text } from '../../../text/text';
import styles from './warningCard.module.css';
export var WarningType;
(function (WarningType) {
    WarningType["REGULATORY"] = "regulatory";
    WarningType["ADVISORY"] = "advisory";
})(WarningType || (WarningType = {}));
export const WarningCard = ({ type, alerts, }) => {
    const isRegulatoryWarning = type === WarningType.REGULATORY;
    const content = {
        heading: isRegulatoryWarning
            ? _t('product_bom.attention_required')
            : _t('product_bom.recommendations'),
        iconName: isRegulatoryWarning ? 'WARNING_CIRCLE_RED' : 'WARNING_CIRCLE',
        notification: isRegulatoryWarning
            ? _t('product_bom.notifications.regulatory_warning')
            : _t('product_bom.notifications.advisory_warning'),
    };
    const alertClass = isRegulatoryWarning
        ? styles.RegulatoryWarningCard
        : styles.AdvisoryWarningCard;
    const dataTestIdValue = type === WarningType.ADVISORY
        ? dataTestId.component.productList.advisoryWarningContainer
        : dataTestId.component.productList.regulatoryWarningContainer;
    return (React.createElement(SidebarCard, { classes: alertClass, dataTestId: dataTestIdValue },
        React.createElement(SidebarCardHeader, null,
            React.createElement(Icon, { size: "s", classes: styles.SidebarHeaderIcon, inline: true, icon: content.iconName }),
            React.createElement(Text, { weight: "bold", size: "xl", classes: styles.Title }, content.heading)),
        React.createElement(Text, { weight: "bold" }, content.notification),
        React.createElement("ul", { className: styles.BulletList }, alerts.map(code => (React.createElement(Text, { el: "li", vMargin: "m", key: code },
            "\u2022 ",
            _t(`product_bom.notifications.n${code}`)))))));
};
