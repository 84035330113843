"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFeatures = void 0;
const effects_1 = require("redux-saga/effects");
const actions_1 = require("./actions");
const helpers_1 = require("@hip/helpers");
const GET_FEATURES_RETRY_ATTEMPTS = 3;
function* getFeatures(url, authHeader) {
    let attempts = 0;
    let errorPayload = null;
    while (true) {
        if (attempts === GET_FEATURES_RETRY_ATTEMPTS)
            throw errorPayload;
        try {
            return yield effects_1.call(helpers_1.Api.get, url, { Authorization: authHeader });
        }
        catch (error) {
            attempts++;
            errorPayload = error;
        }
    }
}
function* loadFeatures(url, authHeader) {
    try {
        const features = yield effects_1.call(getFeatures, url, authHeader);
        yield effects_1.put(actions_1.actions.getFeaturesSuccess(features));
    }
    catch (error) {
        yield effects_1.put(actions_1.actions.getFeaturesFailure(error));
    }
}
exports.loadFeatures = loadFeatures;
