import React from 'react';
import classnames from 'classnames';
import styles from './text.module.css';
const defaultElStyles = {
    span: { size: 'm', weight: 'normal', vMargin: 's', color: 'slate' },
    p: { size: 'm', weight: 'normal', vMargin: 's', color: 'slate' },
    li: { size: 'm', weight: 'normal', vMargin: 's', color: 'slate' },
    h1: { size: 'xxxl', weight: 'bold', vMargin: 'xxxl', color: 'slate' },
    h2: { size: 'xxl', weight: 'bold', vMargin: 'xxl', color: 'slate' },
    h3: { size: 'xl', weight: 'bold', vMargin: 'xl', color: 'slate' },
    h4: { size: 'l', weight: 'bold', vMargin: 'l', color: 'slate' },
    h5: { size: 'l', weight: 'bold', vMargin: 'l', color: 'slate' },
    h6: { size: 'l', weight: 'bold', vMargin: 'l', color: 'slate' },
};
const getTextStyles = (el, size, weight, vMargin, color, customClasses) => {
    const sizeClass = size
        ? styles[`Size-${size}`]
        : styles[`Size-${defaultElStyles[el].size}`];
    const weightClass = weight
        ? styles[`Weight-${weight}`]
        : styles[`Weight-${defaultElStyles[el].weight}`];
    const vPadClass = vMargin
        ? styles[`vMargin-${vMargin}`]
        : styles[`Weight-${defaultElStyles[el].vMargin}`];
    const colorClass = color
        ? styles[`Color-${color}`]
        : styles[`Color-${defaultElStyles[el].color}`];
    return classnames([
        sizeClass,
        weightClass,
        vPadClass,
        colorClass,
        customClasses,
    ]);
};
export const Text = ({ children, el = 'p', size, weight, vMargin, dataTestId, classes, color, onClick, }) => {
    const Element = el;
    const textClasses = getTextStyles(el, size, weight, vMargin, color, classes);
    return (React.createElement(Element, { onClick: onClick, className: textClasses, "data-test-id": dataTestId }, children));
};
