"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.defaultState = void 0;
const actions_1 = require("./actions");
exports.defaultState = {
    list: {},
    error: undefined,
};
const reducer = (state = exports.defaultState, action) => {
    switch (action.type) {
        case actions_1.ActionTypes.GET_FEATURES_SUCCESS:
            return {
                list: Object.assign(Object.assign({}, state.list), action.payload.features),
                error: undefined,
            };
        case actions_1.ActionTypes.GET_FEATURES_FAILURE:
            return Object.assign(Object.assign({}, state), { error: action.payload.error });
        case actions_1.ActionTypes.UPDATE_FEATURES:
            return Object.assign(Object.assign({}, state), { list: Object.assign(Object.assign({}, state.list), action.payload.features) });
        default:
            return state;
    }
};
exports.reducer = reducer;
