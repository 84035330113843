import * as React from 'react';
import { Text } from '../text/text';
import styles from './bomPdfHeader.module.css';
const MAX_DESIGN_NAME_HEADER_LENGTH = 45;
export const BomPdfHeader = ({ left, right }) => (React.createElement("div", { className: styles.Header },
    React.createElement("div", { className: styles.HeaderContent },
        React.createElement(Text, { weight: "bold", vMargin: "none" }, left),
        React.createElement(Text, { vMargin: "none" }, right.length > MAX_DESIGN_NAME_HEADER_LENGTH
            ? `${right.slice(0, MAX_DESIGN_NAME_HEADER_LENGTH)}...`
            : right)),
    React.createElement("div", { className: styles.Separator })));
