import * as React from 'react';
import { _t } from '@hip/translations';
export const DoorOpenIcon = () => {
    const alt = _t('icons.doorOpen');
    return (React.createElement("svg", { role: "img", "aria-label": alt, width: "16", height: "16", viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, alt),
        React.createElement("path", { d: "M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z" }),
        React.createElement("path", { d: "M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6 .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z" }),
        React.createElement("path", { d: "M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z" })));
};
