import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { JsCommunicator } from '@mxt/kf-js-communicator'
import { actions as analyticsActions, Tracking } from '@hip/analytics'
import { COMMUNICATOR_TYPES } from '@hip/communicator'
import { dataTestId } from '@hip/test-attributes'
import { requireConfirmationActions } from '@hip/util-sagas'
import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'
import { Features } from '@hip/interfaces'

import { ANALYTICS } from '../../core/analytics/constants'
import { actions as utilsActions } from '../../core/utils/actions'
import { actions as mxtActions } from '../../core/marxent/actions'
import { actions as hipEventsActions } from '../../core/hip-events/actions'
import { actions as hipCommunicatorActions } from '../../core/hip-communicator/actions'
import { actions as modalActions } from '../../core/modals/actions'
import { actions as projectActions } from '../../core/project/actions'
import { getProjectStatus } from '../../core/project/selectors'
import { getVendor, getMxtToken, getIsDirty } from '../../core/design/selectors'
import { getMxtJourney } from '../../core/marxent/selectors'
import { MODALS } from '../../core/modals/reducer'
import {
  getConfig,
  getMxtUrl,
  getIsMxtReady,
  getOPCO,
  getIsShareFlow,
  getProjectId,
  getDesignId,
  getBearerTokenAuth,
} from '../../core/utils/selectors'
import { NotificationsModule } from '../../modules/notifications/notifications'
import { ModalsModule } from '../../modules/modals/modals'
import { Main } from '../../components/layouts/main/main'

import {
  getIsUserDataReady,
  getUserGivenName,
  getUserSapStoreID,
} from '../../core/user/selectors'
import {
  createMxtUrl,
  getProjectTypeFromURL,
  mapProjectType,
} from '../../utils'
import styles from './marxent-client.module.css'

const MarxentClient: React.FC = props => {
  const type = mapProjectType(getProjectTypeFromURL(window?.location?.href))

  const dispatch = useDispatch()
  const mxt = React.useMemo(() => new JsCommunicator(), [])
  const client = React.useRef()
  const config = useSelector(getConfig)
  const mxtToken = useSelector(getMxtToken)
  const bearerToken = useSelector(getBearerTokenAuth)
  const vendor = useSelector(getVendor)
  const mxtBaseUrl = useSelector(getMxtUrl)
  const mxtJourney = useSelector(getMxtJourney)
  const isMxtReady = useSelector(getIsMxtReady)
  const isShareFlow = useSelector(getIsShareFlow)
  const opco = useSelector(getOPCO)
  const projectId = useSelector(getProjectId)
  const projectStatus = useSelector(getProjectStatus)
  const designId = useSelector(getDesignId)
  const account = useSelector(getUserGivenName)
  const storeId = useSelector(getUserSapStoreID)
  const isUserDataReady = useSelector(getIsUserDataReady)
  const isDirty = useSelector(getIsDirty)

  const isLockSoldDesignsEnabled = useSelector(
    getIsFeatureEnabledByConst(Features.COLLEAGUE_LOCK_SOLD_DESIGNS)
  )

  const [isInited, setIsInited] = React.useState(false)

  React.useEffect(() => {
    dispatch(mxtActions.mxtInit(mxt))
    dispatch(utilsActions.init({ type: COMMUNICATOR_TYPES.MXT }))

    return () => {
      dispatch(hipEventsActions.reset())
    }
  }, [])

  React.useEffect(() => {
    const iframeEventHandler = mxt.subscribeToIFrame(mxtEvent =>
      dispatch(mxtActions.mxtEvent({ mxtEvent }))
    )

    return () => mxt.clearPostMessageSubscription(iframeEventHandler)
  }, [])

  React.useEffect(() => {
    if (isMxtReady && !isInited && isUserDataReady) {
      const userParams = { account, storeId, lng: config?.languageCode, type }

      if (isShareFlow || designId) {
        const mxtUrl = createMxtUrl(mxtBaseUrl, userParams)
        initMxt(mxtUrl)
      } else if (mxtJourney) {
        const mxtUrl = createMxtUrl(mxtBaseUrl, {
          ...userParams,
          journey_id: mxtJourney,
          type,
        })
        initMxt(mxtUrl)
      }
    }
  }, [
    isMxtReady,
    mxtJourney,
    isShareFlow,
    designId,
    mxtBaseUrl,
    isInited,
    isUserDataReady,
    type,
  ])

  const initMxt = (url: string) => {
    mxt.init(mxtToken, url, vendor, client.current)
    setIsInited(true)
  }

  const onExit = () => {
    const dispatchExit = () => {
      dispatch(analyticsActions.event({ eventName: ANALYTICS.EXIT }))
      dispatch(hipCommunicatorActions.exit())
    }
    isDirty
      ? dispatch(
          requireConfirmationActions.init({
            onStart: () =>
              dispatch(modalActions.open({ modal: MODALS.CONFIRM_ACTION })),
            onEnd: () =>
              dispatch(modalActions.close({ modal: MODALS.CONFIRM_ACTION })),
            onConfirm: dispatchExit,
          })
        )
      : dispatchExit()
  }

  const onNewDesignClick = () => {
    const dispatchCreateNew = () => {
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.NEW_DESIGN,
        })
      )
      dispatch(hipCommunicatorActions.createNewDesign())
      setIsInited(false)
    }
    isDirty
      ? dispatch(
          requireConfirmationActions.init({
            onStart: () =>
              dispatch(modalActions.open({ modal: MODALS.CONFIRM_ACTION })),
            onEnd: () =>
              dispatch(modalActions.close({ modal: MODALS.CONFIRM_ACTION })),
            onConfirm: dispatchCreateNew,
          })
        )
      : dispatchCreateNew()
  }

  const onDuplicateClick = () => {
    const dispatchDuplicate = () => {
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.DUPLICATE,
        })
      )
      dispatch(modalActions.open({ modal: MODALS.DUPLICATE }))
    }
    isDirty
      ? dispatch(
          requireConfirmationActions.init({
            onStart: () =>
              dispatch(modalActions.open({ modal: MODALS.CONFIRM_ACTION })),
            onEnd: () =>
              dispatch(modalActions.close({ modal: MODALS.CONFIRM_ACTION })),
            onConfirm: dispatchDuplicate,
          })
        )
      : dispatchDuplicate()
  }

  const onShareClick = () => {
    const dispatchShare = () => {
      dispatch(modalActions.open({ modal: MODALS.SHARE }))
    }
    isDirty
      ? dispatch(
          requireConfirmationActions.init({
            onStart: () =>
              dispatch(modalActions.open({ modal: MODALS.CONFIRM_ACTION })),
            onEnd: () =>
              dispatch(modalActions.close({ modal: MODALS.CONFIRM_ACTION })),
            onConfirm: dispatchShare,
          })
        )
      : dispatchShare()
  }

  const onAppointmentClick = () => {
    const dispatchBookAppointment = () => {
      dispatch(
        analyticsActions.event({
          eventName: ANALYTICS.BOOK_APPOINTMENT,
        })
      )
      dispatch(hipCommunicatorActions.bookAppointment())
    }
    isDirty
      ? dispatch(
          requireConfirmationActions.init({
            onStart: () =>
              dispatch(modalActions.open({ modal: MODALS.CONFIRM_ACTION })),
            onEnd: () =>
              dispatch(modalActions.close({ modal: MODALS.CONFIRM_ACTION })),
            onConfirm: dispatchBookAppointment,
          })
        )
      : dispatchBookAppointment()
  }

  React.useEffect(() => {
    if (projectId && bearerToken) {
      dispatch(projectActions.loadProject(projectId))
    }
  }, [projectId, bearerToken])

  React.useEffect(() => {
    if (isLockSoldDesignsEnabled && projectStatus && projectStatus === 'LOST') {
      dispatch(modalActions.open({ modal: MODALS.DESIGN_WARNING_MODAL }))
    }
  }, [projectStatus, isLockSoldDesignsEnabled])

  return (
    <Main
      opco={opco}
      vendorId={vendor}
      exit={onExit}
      handleNewDesignClick={onNewDesignClick}
      handleDuplicate={onDuplicateClick}
      handleShare={onShareClick}
      handleAppointmentClick={onAppointmentClick}
    >
      <NotificationsModule />

      {mxtToken && (
        // @ts-ignore
        <div
          className={styles.MarxentClient}
          ref={client}
          data-test-id={dataTestId.cdt.layout.iframeMarxentContainer}
        />
      )}

      <ModalsModule />
      {config?.analytics?.enabled && (
        <Tracking
          env={config?.analytics.env}
          profile={config?.analytics.profile}
          config={config?.analytics.config}
        />
      )}
    </Main>
  )
}

export default MarxentClient
