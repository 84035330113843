import { _t } from '@hip/translations'
import { HydratedBom, StockSummaryData } from '@hip/interfaces'

import { ActionTypes, Actions } from './actions'

export interface ProductsState {
  isLoading: boolean
  products: HydratedBom
  error: string
  stockData: {
    isLoading: boolean
    data: StockSummaryData
    error: Error
  }
}

export const defaultState = {
  isLoading: false,
  products: null,
  error: null,
  stockData: {
    isLoading: false,
    data: null,
    error: null,
  },
}

export const reducer = (
  state: ProductsState = defaultState,
  action: Actions
): ProductsState => {
  switch (action.type) {
    case ActionTypes.LOAD_PRODUCTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case ActionTypes.SUCCESS_LOAD_PRODUCTS:
      return {
        ...state,
        isLoading: false,
        products: {
          ...action.payload,
        },
        error: null,
      }

    case ActionTypes.LOAD_PRODUCTS_FAIL:
      return {
        ...state,
        isLoading: false,
        products: null,
        error: _t('product_bom.errors.product_load_fail'),
      }

    case ActionTypes.LOAD_STOCK_DATA:
      return {
        ...state,
        stockData: {
          ...state.stockData,
          isLoading: true,
          error: null,
        },
      }

    case ActionTypes.LOAD_STOCK_DATA_SUCCESS:
      return {
        ...state,
        stockData: {
          ...state.stockData,
          isLoading: false,
          data: action.payload,
        },
      }

    case ActionTypes.LOAD_STOCK_DATA_FAILURE:
      return {
        ...state,
        stockData: {
          ...state.stockData,
          isLoading: false,
          error: action.payload,
        },
      }

    default:
      return state
  }
}
