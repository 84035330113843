import * as React from 'react';
import classnames from 'classnames';
import { _t } from '@hip/translations';
import { BrandIcon } from '../brandIcon/brandIcon';
import { BomPrice } from '../bomPrice/bomPrice';
import styles from './overview.module.css';
const Illustration = () => (React.createElement("svg", { viewBox: "0 0 281 281", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { fill: "#F1EFEE" },
        React.createElement("path", { d: "M29.191 133H250.81c6.618 0 12.191 5.097 12.191 11.677V281H17V144.677C17 138.098 22.578 133 29.191 133zm221.76 135.918V145.025l-.042.045H29.09l-.041-.049v123.897H250.95z" }),
        React.createElement("path", { d: "M138 135v140h12V135z" }),
        React.createElement("path", { d: "M145 183h111v-12H145zM241 195v64h-82v-64h82zm-69.934 11.985v40.03h57.868v-40.03h-57.868zM47 164h12v-12H47zM158 164h12v-12h-12zM180 164h12v-12h-12zM58.976 142v-36.973c0-6.055 5.126-11.046 11.527-11.046 6.4 0 11.521 4.989 11.521 11.046v7.54H94v-7.54C94 92.28 83.446 82 70.503 82S47 92.28 47 105.027V142h11.976zM234 43h-58V0h58v43zm-12.069-30.962H188.07v18.924h33.862V12.038z" }),
        React.createElement("path", { d: "M161.076 76.831l26.866-42.38L177.79 28l-30.534 48.167c-3.007 4.708-.495 12.692 6.294 12.692L255.12 89c6.324 0 9.86-7.737 6.71-12.697l-30.322-47.878-10.156 6.446 26.65 42.08-86.926-.12zM0 281h281v-12H0zM123 200.467c0 11.34-9.178 20.533-20.5 20.533S82 211.807 82 200.467V133h41v67.467zm-29.286-55.734v55.734c0 4.86 3.934 8.8 8.786 8.8 4.852 0 8.786-3.94 8.786-8.8v-55.734H93.714z" }))));
export const Overview = ({ locale, designName, financeImage, image, amount, currency, opco, }) => (React.createElement("div", { className: styles.Container },
    React.createElement("div", { className: styles.Details },
        React.createElement("div", { className: styles.DetailsInfo },
            React.createElement("h2", null, designName),
            React.createElement("img", { src: image, alt: designName }),
            React.createElement("div", { className: styles.Separator }),
            React.createElement("div", null,
                React.createElement("h3", { className: classnames(styles.PriceText, styles.PriceTitle) },
                    _t('bom_pdf.design_price'),
                    ":"),
                React.createElement("h4", { className: classnames(styles.PriceText, styles.PriceAmount) }, React.createElement(BomPrice, { displayPrice: true, amount: amount, currency: currency })))),
        React.createElement(BrandIcon, { opco: opco, size: "xxl" })),
    React.createElement("div", { className: styles.Info },
        React.createElement("img", { className: styles.Image, src: financeImage }),
        React.createElement(Illustration, null))));
