import { groupBy, flattenDeep, uniq, flatten } from 'lodash/fp'

import { HydratedBom, StockSummaryData } from '@hip/interfaces'
import { definitions as experienceDefinitions } from '@hip/experience-service-types'

import {
  GetAggregatedStockApiResponse,
  OrderXMLFormat,
  ProductXMLFormat,
} from './interfaces'

export const buildOrderObject = (
  designId: string,
  products: HydratedBom
): OrderXMLFormat => {
  const productItems: ProductXMLFormat[] = []

  products?.groups?.forEach(group => {
    group?.assemblies?.forEach(assembly => {
      assembly?.products?.forEach(product => {
        productItems.push({
          Product: {
            Ean: product.ean,
            Name: product.name,
            Quantity: product.quantity,
            Price: product.unitPrice.amount,
          },
        })
      })
    })
  })

  return {
    Order: {
      DesignId: designId,
      Products: productItems,
    },
  }
}

export const createFileName = (): string => {
  const now = new Date()

  const year = now.getFullYear()
  const month = `0${now.getMonth() + 1}`.slice(-2)
  const date = `0${now.getDate()}`.slice(-2)
  const hours = now.getHours()
  const minutes = now.getMinutes()

  return `order-${year}${month}${date}-${hours}${minutes}.xml`
}

export const getMostPriorityStoreData = (
  data: GetAggregatedStockApiResponse['data']
) => {
  if (data.length === 0) {
    return null
  }
  const sortedElements = [...data].sort(
    (a, b) => (a.attributes.priority || 0) - (b.attributes.priority || 0)
  )
  return sortedElements[0]
}

export const parseAggregatedStockApiResponse = (
  response: GetAggregatedStockApiResponse
): StockSummaryData => {
  const stockSummaryData: StockSummaryData = {}

  const groupedByEan = groupBy(el => el.attributes.ean, response.data)
  for (const [ean, elements] of Object.entries(groupedByEan)) {
    stockSummaryData[ean] = {}
    const groupedBySource = groupBy(el => el.attributes.source, elements)
    for (const [source, sourceElements] of Object.entries(groupedBySource)) {
      const mostPriorityStoreData = getMostPriorityStoreData(sourceElements)
      if (mostPriorityStoreData) {
        stockSummaryData[ean][source] = {
          value: mostPriorityStoreData.attributes.stockValue,
        }
      }
    }
  }

  return stockSummaryData
}

export const getEansFromBomResponse = (
  response: experienceDefinitions['GetBomResponse']
): string[] =>
  uniq<string>(
    flattenDeep<string>(
      response?.groups?.map(group =>
        group?.assemblies?.map(assembly =>
          assembly?.products?.map(product => product.ean)
        )
      )
    )
  ).filter(Boolean)

export const mergeGetAggregatedStockResponses = (
  responses: GetAggregatedStockApiResponse[]
): GetAggregatedStockApiResponse => {
  return {
    data: flatten(responses?.map(el => el?.data)).filter(Boolean),
  }
}
