import React from 'react'

import { Text } from '@hip/components/src/components/text/text'

import { Modal } from '../modal/modal'

import styles from './design-warning-modal.module.css'

interface DesignWarningModalProps {
  show: boolean
  close: () => void
  description: string
}

export const DesignWarningModal: React.FC<DesignWarningModalProps> = ({
  show,
  close,
  description,
}) => (
  <Modal show={show} onClose={close} title={null}>
    <div className={styles.DesignWarningModalContent}>
      <Text>{description}</Text>
    </div>
  </Modal>
)
