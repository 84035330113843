import axios from 'axios'
import {
  takeLatest,
  put,
  CallEffect,
  PutEffect,
  fork,
  delay,
  cancel,
  ForkEffect,
  CancelEffect,
  call,
  select,
  SelectEffect,
  take,
  TakeEffect,
} from 'redux-saga/effects'

import { CustomerDesignsConfig } from '@hip/service-config'
import initTranslations, { _t } from '@hip/translations'
import {
  loadFeaturesSaga,
  actions as featureTogglesActions,
} from '@hip/feature-toggles'
import { COMMUNICATOR_TYPES } from '@hip/communicator'
import { OPCOS } from '@hip/interfaces'
import {
  requireConfirmation,
  RequireConfirmationActionTypes,
} from '@hip/util-sagas'

import { actions as errorsActions } from '../errors/actions'
import { actions as hipCommunicatorActions } from '../hip-communicator/actions'
import { actions as designActions } from '../design/actions'
import { loadFeatures } from '../feature-toggles-dashboard/utils'
import {
  actions as utilsActions,
  ActionTypes as UtilsActionTypes,
} from './actions'
import { SetTimeoutPayload, TimeoutTypes } from './interfaces'
import {
  getExperienceService,
  getUnauthenticatedExperienceService,
  getIsShareFlow,
  getIsAuthenticatedShareFlow,
} from './selectors'

const timeout: any = {}
export const NOTIFICATION_TIMEOUT = 5000

export const fetchConfig = async () =>
  await axios.get('/config').then(({ data }) => JSON.parse(data))

export function* getExperienceUrl(unauthenticated: boolean) {
  const isAuthenticatedShareFlow: boolean = yield select(
    getIsAuthenticatedShareFlow
  )
  const experienceService: ReturnType<typeof getExperienceService> = yield select(
    unauthenticated && !isAuthenticatedShareFlow
      ? getUnauthenticatedExperienceService
      : getExperienceService
  )

  if (
    !experienceService ||
    !experienceService.api ||
    !experienceService.api.url
  ) {
    throw Error(_t('messages.error'))
  }

  return experienceService.api.url
}

export function* init({
  payload: { type },
}: any): IterableIterator<
  | CallEffect
  | PutEffect<ReturnType<typeof utilsActions.setConfig>>
  | PutEffect<ReturnType<typeof utilsActions.setOPCO>>
  | PutEffect<ReturnType<typeof designActions.loadDesign>>
  | PutEffect<ReturnType<typeof hipCommunicatorActions.bootstrap>>
  | SelectEffect
  | TakeEffect
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
> {
  try {
    const {
      opco,
      config,
    }: { opco: OPCOS; config: CustomerDesignsConfig } = yield call(fetchConfig)

    if (config && opco) {
      yield put(utilsActions.setConfig(config))
      yield put(utilsActions.setOPCO(opco))
      yield call(initTranslations, opco)

      const experienceServiceUrl: ReturnType<typeof getExperienceUrl> = yield call(
        getExperienceUrl,
        true
      )
      if (!config.security || !experienceServiceUrl) {
        throw Error(_t('messages.error'))
      }
      const featureTogglesUrl = `${experienceServiceUrl}/${opco}/feature-toggles`
      const authHeader = `Atmosphere atmosphere_app_id="${config.security.clientId}"`
      yield call(loadFeaturesSaga, featureTogglesUrl, authHeader)
      yield call(loadSavedDashboardFeatures)
      const isAuthenticatedShareFlow = yield select(getIsAuthenticatedShareFlow)
      yield put(hipCommunicatorActions.bootstrap())
      if (isAuthenticatedShareFlow) {
        yield take(UtilsActionTypes.SET_TOKEN)
      }

      if (type === COMMUNICATOR_TYPES.MXT && opco !== OPCOS.BDRO) {
        const isShareFlow = yield select(getIsShareFlow)
        if (isShareFlow) {
          yield put(designActions.loadDesign())
        }
      }
    } else {
      throw Error(_t('messages.error'))
    }
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* copyToClipboard({
  payload: { value },
}: ReturnType<typeof utilsActions.copyToClipboard>) {
  const input = global.document.createElement('input')
  input.type = 'text'
  input.value = value
  input.setAttribute(
    'styles',
    `
    position: absolute;
    left: -9999px;
    top: -999px;
  `
  )
  global.document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  global.document.body.removeChild(input)
  yield put(utilsActions.setCopied({ copied: true }))
  yield put(
    utilsActions.setTimeout({
      name: TimeoutTypes.COPIED_NOTIFICATION,
      time: NOTIFICATION_TIMEOUT,
    })
  )
}

function* startTimeout({
  time,
  name,
}: SetTimeoutPayload): IterableIterator<
  CallEffect | PutEffect<ReturnType<typeof utilsActions.clearTimeout>>
> {
  yield delay(time)
  yield put(utilsActions.clearTimeout({ name }))
}

function* setTimeout({
  payload: { name, time },
}: ReturnType<typeof utilsActions.setTimeout>): IterableIterator<ForkEffect> {
  timeout[name] = yield fork(startTimeout, { name, time })
}

function* clearTimeout({
  payload: { name },
}: ReturnType<typeof utilsActions.clearTimeout>): IterableIterator<
  CancelEffect | PutEffect<ReturnType<typeof utilsActions.setCopied>>
> {
  yield cancel(timeout[name])

  if (name === TimeoutTypes.COPIED_NOTIFICATION) {
    yield put(utilsActions.setCopied({ copied: false }))
  }
}

function* loadSavedDashboardFeatures(): IterableIterator<
  | CallEffect
  | PutEffect<ReturnType<typeof featureTogglesActions.updateFeatures>>
> {
  const loadedFeatures = yield call(loadFeatures)
  if (loadedFeatures) {
    yield put(featureTogglesActions.updateFeatures(loadedFeatures))
  }
}

export function* sagas(): IterableIterator<ReturnType<typeof takeLatest>> {
  yield takeLatest(UtilsActionTypes.INIT, init)
  yield takeLatest(UtilsActionTypes.COPY_TO_CLIPBOARD, copyToClipboard)
  yield takeLatest(UtilsActionTypes.SET_TIMEOUT, setTimeout)
  yield takeLatest(UtilsActionTypes.CLEAR_TIMEOUT, clearTimeout)
  yield takeLatest(RequireConfirmationActionTypes.INIT, requireConfirmation)
}
