import { createAction, ActionsUnion } from '@hip/helpers'
import { OPCOS } from '@hip/interfaces'
import { CustomerDesignsConfig } from '@hip/service-config'
import {
  InitPayload,
  SetCookiesPayload,
  CopyToClipboardPayload,
  SetTimeoutPayload,
  TokenPayload,
  AnalyticsPayload,
  CopiedPayload,
  ClearTimeoutPayload,
} from './interfaces'

export enum ActionTypes {
  INIT = '@hip/utils/INIT',
  SET_CONFIG = '@hip/utils/SET_CONFIG',
  SET_OPCO = '@hip/utils/SET_OPCO',
  SET_COPIED = '@hip/utils/SET_COPIED',
  SET_TOKEN = '@hip/utils/SET_TOKEN',
  SET_ANALYTICS = '@hip/utils/SET_ANALYTICS',
  SET_COOKIES = '@hip/utils/SET_COOKIES',
  COPY_TO_CLIPBOARD = '@hip/utils/COPY_TO_CLIPBOARD',
  SET_TIMEOUT = '@hip/utils/SET_TIMEOUT',
  CLEAR_TIMEOUT = '@hip/utils/CLEAR_TIMEOUT',
}

export const actions = {
  init: (payload: InitPayload) => createAction(ActionTypes.INIT, payload),
  setConfig: (payload: CustomerDesignsConfig) =>
    createAction(ActionTypes.SET_CONFIG, payload),
  setOPCO: (payload: OPCOS) => createAction(ActionTypes.SET_OPCO, payload),
  setCopied: (payload: CopiedPayload) =>
    createAction(ActionTypes.SET_COPIED, payload),
  setToken: (payload: TokenPayload) =>
    createAction(ActionTypes.SET_TOKEN, payload),
  setAnalytics: (payload: AnalyticsPayload) =>
    createAction(ActionTypes.SET_ANALYTICS, payload),
  setCookies: (payload: SetCookiesPayload) =>
    createAction(ActionTypes.SET_COOKIES, payload),
  copyToClipboard: (payload: CopyToClipboardPayload) =>
    createAction(ActionTypes.COPY_TO_CLIPBOARD, payload),
  setTimeout: (payload: SetTimeoutPayload) =>
    createAction(ActionTypes.SET_TIMEOUT, payload),
  clearTimeout: (payload: ClearTimeoutPayload) =>
    createAction(ActionTypes.CLEAR_TIMEOUT, payload),
}

export type Actions = ActionsUnion<typeof actions>
