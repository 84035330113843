import React from 'react'
import { Features } from '@hip/interfaces'
import {
  FeatureTogglesList,
  FeatureTogglesSavedMessage,
} from '@hip/components/src/components/featureTogglesDashboard'
import { Text } from '@hip/components/src/components/text/text'
import { SmartLayout } from '../../components/layouts/smart/smart'
import { FeatureToggleListValues } from '../../core/feature-toggles-dashboard/interfaces'
import {
  loadFeatures,
  saveFeatures,
} from '../../core/feature-toggles-dashboard/utils'
import styles from './featureTogglesDashboard.css'

const FEATURE_TOGGLES_DISPLAY = [Features.STOCK_AVAILABILITY_INFORMATION]
const DISPLAY_SUCCESS_MESSAGE_SECONDS = 3

export const FeatureTogglesDashboard: React.FC = () => {
  const [values, setValues] = React.useState<FeatureToggleListValues>(null)
  const [showMessageSeconds, setShowMessageSeconds] = React.useState(0)

  React.useEffect(() => {
    const values = loadFeatures() ?? {}
    setValues(values)
  }, [])

  const handleFeatureTogglesChange = React.useCallback(
    (values: FeatureToggleListValues) => {
      saveFeatures(values)
      setValues(values)
      setShowMessageSeconds(DISPLAY_SUCCESS_MESSAGE_SECONDS)
    },
    [values]
  )

  React.useEffect(() => {
    const interval = setInterval(() => {
      setShowMessageSeconds(prevValue => Math.max(0, prevValue - 1))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <SmartLayout>
      <div className={styles.FeatureTogglesDashboard}>
        <Text size="xxxl" vMargin="l">
          Feature Toggles
        </Text>

        <Text vMargin="xxxl">
          The default value is <strong>Not set</strong>, which means the feature
          toggle will be loaded from the server. Please click the{' '}
          <strong>Not set</strong> button if you no longer need to change the
          required feature
        </Text>

        {values && (
          <FeatureTogglesList
            displayFeatures={FEATURE_TOGGLES_DISPLAY}
            values={values}
            onChange={handleFeatureTogglesChange}
          />
        )}

        {showMessageSeconds > 0 && (
          <FeatureTogglesSavedMessage text="Saved locally" />
        )}
      </div>
    </SmartLayout>
  )
}
