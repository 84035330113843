import * as React from 'react';
import classnames from 'classnames';
import { dataTestId } from '@hip/test-attributes';
import { Icon } from '../icon';
import styles from './hammertime.module.css';
const centralizeWrapper = child => React.createElement("div", { className: styles.Wrapper }, child);
export const Hammertime = ({ flat, isAnimating = true, central = false, classes, iconClasses, size = 'm', }) => {
    const spinner = (React.createElement("div", { className: classnames(classes, styles.Hammertime, {
            [styles['Hammertime--flat']]: flat,
        }) },
        React.createElement(Icon, { icon: "SPINNER", dataTestId: dataTestId.component.hammertime.icon, classes: classnames(iconClasses, styles[`Icon--size-${size}`], {
                [styles['Hammer-animate']]: isAnimating,
            }) })));
    return central ? centralizeWrapper(spinner) : spinner;
};
