import * as React from 'react';
import { _t } from '@hip/translations';
import { BomPrice } from '../bomPrice/bomPrice';
import { Text } from '../text/text';
import { WarningMessage } from '../messages';
import styles from './printedEnhancedBomDetailedTableRow.module.css';
// PrintedEnhancedBomDetailedTableRow is wrapped in a fragment intentionally because the DynamicPage
// component with which it is used slices it's immediate children into sub-arrays to be rendered
// across multiple pages when necessary. Returning this list wrapped in a single element
// would prevent this from working correctly.
export const PrintedEnhancedBomDetailedTableRow = ({ product }) => {
    const { productStatus, unitPrice, image, ean, quantity, name } = product;
    const showUnitPrice = unitPrice.amount && unitPrice.currency;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.ProductThumb },
            React.createElement("img", { src: image || '/projectcard-no-img.png' })),
        React.createElement("div", { className: styles.ProductDetails },
            React.createElement("div", { className: styles.ProductDetailsContent },
                React.createElement("div", { className: styles.ProductName },
                    React.createElement("p", { className: styles.ProductNameText }, name),
                    React.createElement("p", { className: styles.ProductCode },
                        _t('product_bom.product_code'),
                        " ",
                        ean)),
                React.createElement("div", { className: styles.ProductQuantity },
                    React.createElement("p", null, quantity)),
                React.createElement("div", { className: styles.ProductPrice }, showUnitPrice ? (React.createElement(React.Fragment, null,
                    React.createElement(BomPrice, { size: "l", amount: unitPrice.amount, currency: unitPrice.currency }),
                    React.createElement(Text, null,
                        "(",
                        _t('product_bom.unit_price'),
                        ")"))) : (React.createElement(Text, null, "-")))),
            React.createElement("div", null, productStatus === 'Discontinued' && (React.createElement(WarningMessage, null,
                React.createElement("p", { className: styles.ProductDiscontinued }, _t('product_bom.unit_discontinued'))))))));
};
