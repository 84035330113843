"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.post = void 0;
const constants_1 = require("../constants");
const post = (targetOrigin, target = window.parent) => (kind, payload) => {
    const data = {
        namespace: constants_1.MSG_NAMESPACE,
        kind,
        data: payload,
    };
    target && target.postMessage(data, targetOrigin);
};
exports.post = post;
