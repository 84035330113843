import * as React from 'react';
import { _t } from '@hip/translations';
export const ErrorCircleIcon = ({ color = '#E30303' }) => {
    const alt = _t('icons.error_circle');
    return (React.createElement("svg", { role: "img", "aria-label": alt, width: "24px", height: "24px", viewBox: "0 0 24 24", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
        React.createElement("title", null, alt),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "Icon/24/Validation/ic_tickNegative" },
                React.createElement("path", { d: "M12,2 C17.5,2 22,6.5 22,12 C22,17.5 17.5,22 12,22 C6.5,22 2,17.5 2,12 C2,6.5 6.5,2 12,2 Z", id: "Shape", fill: color }),
                React.createElement("path", { d: "M15.508203,8.50820296 C15.1170733,8.11707326 14.4788044,8.1211956 14.0934418,8.50655823 L12,10.6 L9.90655823,8.50655823 C9.51633689,8.11633689 8.88929462,8.11070538 8.49179704,8.50820296 L8.50820296,8.49179704 C8.11707326,8.88292674 8.1211956,9.5211956 8.50655823,9.90655823 L10.6,12 L8.50655823,14.0934418 C8.11633689,14.4836631 8.11070538,15.1107054 8.50820296,15.508203 L8.49179704,15.491797 C8.88292674,15.8829267 9.5211956,15.8788044 9.90655823,15.4934418 L12,13.4 L14.0934418,15.4934418 C14.4836631,15.8836631 15.1107054,15.8892946 15.508203,15.491797 L15.491797,15.508203 C15.8829267,15.1170733 15.8788044,14.4788044 15.4934418,14.0934418 L13.4,12 L15.4934418,9.90655823 C15.8836631,9.51633689 15.8892946,8.88929462 15.491797,8.49179704 L15.508203,8.50820296 Z", id: "Path", fill: "#FFFFFF" })))));
};
export const ErrorCircleGreyIcon = () => React.createElement(ErrorCircleIcon, { color: "#989da0" });
