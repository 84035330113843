"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToAccessShareableDesignError = exports.subscribeToAccessShareableDesignSuccess = exports.subscribeToAccessShareableDesign = void 0;
const events_service_types_1 = require("@hip/events-service-types");
const createSubscriber_1 = require("../helpers/createSubscriber");
const subscribeToAccessShareableDesign = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN, listener);
exports.subscribeToAccessShareableDesign = subscribeToAccessShareableDesign;
const subscribeToAccessShareableDesignSuccess = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_SUCCESS, listener);
exports.subscribeToAccessShareableDesignSuccess = subscribeToAccessShareableDesignSuccess;
const subscribeToAccessShareableDesignError = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_ERROR, listener);
exports.subscribeToAccessShareableDesignError = subscribeToAccessShareableDesignError;
