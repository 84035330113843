import { PROJECT_CATEGORY } from '@hip/rest-utils/providers/projectService/models'

import { ActionTypes, Actions } from './actions'

export interface DesignState {
  id?: string
  vendorId?: string
  mxtToken?: string
  viewId?: string
  editId?: string
  dateCreated?: Date
  isDirty?: boolean
  category?: PROJECT_CATEGORY
}

export const DEFAULT_STATE: DesignState = {}

export const reducer = (
  state: DesignState = DEFAULT_STATE,
  action: Actions
): DesignState => {
  switch (action.type) {
    case ActionTypes.RESET:
      return {}

    case ActionTypes.SET_DESIGN:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.SET_VENDOR:
      return {
        ...state,
        vendorId: action.payload.vendor,
      }

    case ActionTypes.SET_MXT_TOKEN:
      return {
        ...state,
        mxtToken: action.payload.mxtToken,
      }

    case ActionTypes.DIRTY_STATE_CHANGED:
      return {
        ...state,
        isDirty: action.payload.isDirty,
      }

    default:
      return state
  }
}
