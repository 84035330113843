"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPost = exports.generatePageEvent = exports.generateTrackingEvent = void 0;
const constants_1 = require("./constants");
const generateEvent = (type) => payload => ({
    type,
    payload: Object.assign({ metaId: 'CDT' }, payload),
});
exports.generateTrackingEvent = generateEvent(constants_1.TRACK_EVENT);
exports.generatePageEvent = generateEvent(constants_1.TRACK_PAGE);
const safeEvent = trigger => (ctx, payload) => {
    if (!ctx || !ctx[trigger]) {
        throw new Error('Can not find tracking event type');
    }
    try {
        ctx[trigger](payload);
    }
    catch (err) {
        throw new Error('Error sending tracking event');
    }
};
const getTagCheckVariable = ctx => {
    if (!ctx) {
        return constants_1.TagCheckMapper.UTAG_JS_NOT_LOADED;
    }
    return ctx.data
        ? constants_1.TagCheckMapper.UTAG_DATA_LOADED
        : constants_1.TagCheckMapper.UTAG_DATA_NOT_LOADED;
};
const createPost = (ctx) => {
    if (!ctx) {
        // @ts-ignore
        ctx = window.utag;
    }
    // @ts-ignore
    window.tagCheck = getTagCheckVariable(ctx);
    return ({ type, payload }) => {
        if (!ctx) {
            throw new Error('Can not find tracking context');
        }
        const alias = {
            track: safeEvent('link'),
            page: safeEvent('view'),
        };
        alias[type](ctx, payload);
    };
};
exports.createPost = createPost;
