import * as React from 'react';
export const usePaginatedDOMElements = () => {
    const containerRef = React.useRef(null);
    const [maxContainerHeight, setMaxContainerHeight] = React.useState(0);
    const [paginatedElements, setPaginatedElements] = React.useState(null);
    const isContainerHeightMeasured = maxContainerHeight > 0;
    // Measure height of container onMount before content is added and isContainerHeightMeasured becomes true
    React.useEffect(() => {
        const containerHeight = measureDivHeight(containerRef);
        setMaxContainerHeight(containerHeight);
    }, []);
    // Measure height of container once content has been added to it and batch immediate child elements according to fit
    React.useEffect(() => {
        if (isContainerHeightMeasured) {
            const stringifiedChildren = paginateChildElements(containerRef, maxContainerHeight).map(batch => stringifyChildElements(batch));
            setPaginatedElements(stringifiedChildren);
        }
    }, [isContainerHeightMeasured]);
    return {
        containerRef,
        isContainerHeightMeasured,
        paginatedElements,
    };
};
// Helper functions
const measureDivHeight = (el, rounding = 'floor') => Math[rounding](el.current.getBoundingClientRect().height);
const paginateChildElements = (parentEl, fixedHeight) => {
    const contentExceedsHeight = measureDivHeight(parentEl, 'ceil') > fixedHeight;
    const childrenArray = Array.from(parentEl.current.children);
    if (contentExceedsHeight) {
        const paginatedElements = childrenArray.reduce((acc, curr) => {
            const childHeight = curr.getBoundingClientRect().height;
            if (acc[acc.length - 1].totalHeight + childHeight < fixedHeight) {
                acc[acc.length - 1] = {
                    childElements: [...acc[acc.length - 1].childElements, curr],
                    totalHeight: acc[acc.length - 1].totalHeight + childHeight,
                };
            }
            else {
                acc.push({ totalHeight: childHeight, childElements: [curr] });
            }
            return acc;
        }, [{ totalHeight: 0, childElements: [] }]);
        return paginatedElements.map(batch => batch.childElements);
    }
    else {
        return [childrenArray];
    }
};
const stringifyChildElements = (elements) => elements.map(element => element.outerHTML).join('');
