import * as React from 'react';
import { usePaginatedDOMElements } from '@hip/hooks';
import { Page } from '../page/page';
export const DynamicPage = ({ header, footer, children, firstChildEl, onRender, }) => {
    const { containerRef, isContainerHeightMeasured, paginatedElements, } = usePaginatedDOMElements();
    if (!isContainerHeightMeasured)
        return React.createElement(Page, { header: header, footer: footer, contentRef: containerRef });
    else if (!paginatedElements)
        return (React.createElement(Page, { header: header, footer: footer, contentRef: containerRef }, children));
    else
        return (React.createElement(React.Fragment, null, paginatedElements.map(htmlString => (React.createElement(Page, { onRender: onRender, header: header, footer: footer, contentRef: containerRef, key: htmlString },
            firstChildEl,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlString } }))))));
};
