import * as React from 'react';
import classnames from 'classnames';
import { Icon } from '../icon';
import styles from './messages.module.css';
const Message = ({ classes, children, icon, iconColor, padding, dataTestId, align, forwardedRef, }) => {
    if (!children)
        return null;
    return (React.createElement("div", { className: classnames(styles.MessageBase, padding === 'half' && styles['Message--padHalf'], padding === 'full' && styles['Message--padFull'], align === 'center' && styles['Message--centered'], classes), ref: forwardedRef, "data-test-id": dataTestId },
        React.createElement(Icon, { classes: styles.MessageIcon, icon: icon, size: "s", color: iconColor, rPadding: "m", inline: true }),
        React.createElement("div", { className: align === 'left' && styles.Content }, children)));
};
Message.defaultProps = {
    padding: 'half',
    align: 'left',
    forwardedRef: null,
};
export const WarningMessage = ({ children, classes, dataTestId, }) => (React.createElement(Message, { icon: "WARNING_CIRCLE", classes: classnames(styles['Message--isWarn'], classes), dataTestId: dataTestId }, children));
export const ErrorMessage = ({ children, classes, dataTestId, }) => (React.createElement(Message, { icon: "WARNING_CIRCLE_RED", classes: classnames(styles['Message--isError'], classes), dataTestId: dataTestId }, children));
