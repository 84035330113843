import React from 'react';
import classnames from 'classnames';
import styles from './button.module.css';
export const Button = ({ children, classes, disabled = false, type, dataTestId, onClick, }) => {
    const buttonClasses = classnames({
        [`${styles.Button}`]: true,
        [`${styles.Primary}`]: type === 'primary',
        [`${styles.Secondary}`]: type === 'secondary',
        [`${styles.Tertiary}`]: type === 'tertiary',
        [`${styles.Naked}`]: type === 'naked',
        [`${styles.Disabled}`]: disabled,
    });
    return (React.createElement("button", { className: classnames(buttonClasses, classes), disabled: disabled, onClick: onClick, "data-test-id": dataTestId }, children));
};
