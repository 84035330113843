import { call, select } from 'redux-saga/effects'

import { OPCOS } from '@hip/interfaces'
import { _t } from '@hip/translations'

import { hipApi } from '../hip-api/sagas'
import { getOPCO } from '../utils/selectors'
import { getExperienceUrl } from '../utils/sagas'

export function* patchDesign(projectId: string, designId: string) {
  const baseUrl = yield call(getExperienceUrl, false)
  const opco: OPCOS = yield select(getOPCO)

  const url = `${baseUrl}/${opco}/projects/${projectId}/designs/${designId}`

  return yield hipApi.patch(url)
}
