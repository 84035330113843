import React from 'react'

import { _t } from '@hip/translations'
import { dataTestId } from '@hip/test-attributes'
import { Button } from '@hip/components/src/components/button/button'
import { Text } from '@hip/components/src/components/text/text'

import { Modal } from '../modal/modal'

import styles from './duplicate-modal.module.css'

interface DuplicateModalProps {
  show: boolean
  close: () => void
  duplicate: () => void
}

export const DuplicateModal: React.FC<DuplicateModalProps> = ({
  show,
  close,
  duplicate,
}: DuplicateModalProps) => (
  <Modal show={show} onClose={close} title={_t('duplicate-modal.duplicate')}>
    <Text vMargin="xxxl">{_t('duplicate-modal.sure')}</Text>
    <div className={styles.Buttons}>
      <Button
        type="secondary"
        onClick={close}
        dataTestId={dataTestId.cdt.duplicateDesign.btnCancel}
      >
        <Text vMargin="none" color="white">
          {_t('duplicate-modal.cancel')}
        </Text>
      </Button>
      <Button
        type="primary"
        onClick={duplicate}
        dataTestId={dataTestId.cdt.duplicateDesign.btnSubmit}
      >
        <Text vMargin="none" color="white">
          {_t('duplicate-modal.duplicate')}
        </Text>
      </Button>
    </div>
  </Modal>
)
