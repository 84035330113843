"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToAccessDesignBomError = exports.subscribeToAccessDesignBomSuccess = exports.subscribeToAccessDesignBom = void 0;
const events_service_types_1 = require("@hip/events-service-types");
const createSubscriber_1 = require("../helpers/createSubscriber");
const subscribeToAccessDesignBom = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM, listener);
exports.subscribeToAccessDesignBom = subscribeToAccessDesignBom;
const subscribeToAccessDesignBomSuccess = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_SUCCESS, listener);
exports.subscribeToAccessDesignBomSuccess = subscribeToAccessDesignBomSuccess;
const subscribeToAccessDesignBomError = (socket, listener) => createSubscriber_1.createSubscriber(socket, events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_ERROR, listener);
exports.subscribeToAccessDesignBomError = subscribeToAccessDesignBomError;
