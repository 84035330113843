"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emitAccessDesignError = exports.emitAccessDesignSuccess = exports.emitAccessDesign = void 0;
const events_service_types_1 = require("@hip/events-service-types");
const createEmmiter_1 = require("../helpers/createEmmiter");
const emitAccessDesign = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN, request);
exports.emitAccessDesign = emitAccessDesign;
const emitAccessDesignSuccess = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_SUCCESS, request);
exports.emitAccessDesignSuccess = emitAccessDesignSuccess;
const emitAccessDesignError = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_ERROR, request);
exports.emitAccessDesignError = emitAccessDesignError;
