import { createAction, ActionsUnion } from '@hip/helpers'

import { Project } from './interfaces'

export enum ActionTypes {
  RESET = '@hip/design/RESET',

  LOAD_PROJECT = '@hip/project/LOAD_PROJECT',
  LOAD_PROJECT_SUCCESS = '@hip/project/LOAD_PROJECT_SUCCESS',
  LOAD_PROJECT_FAILED = '@hip/project/LOAD_PROJECT_FAILED',
}

export const actions = {
  reset: () => createAction(ActionTypes.RESET),

  loadProject: (id: Project['_id']) =>
    createAction(ActionTypes.LOAD_PROJECT, id),
  loadProjectSuccess: (payload: Project) =>
    createAction(ActionTypes.LOAD_PROJECT_SUCCESS, payload),
  loadProjectFailed: (payload: Error) =>
    createAction(ActionTypes.LOAD_PROJECT_FAILED, payload),
}

export type Actions = ActionsUnion<typeof actions>
