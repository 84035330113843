import {
  takeLatest,
  CallEffect,
  put,
  call,
  SelectEffect,
  PutEffect,
  TakeEffect,
  select,
} from 'redux-saga/effects'

import { definitions as experienceDefinitions } from '@hip/experience-service-types'

import { getIsShareFlow } from '../utils/selectors'

import {
  actions as hipProjectActions,
  ActionTypes as HipProjectActionTypes,
} from './actions'
import { getProjectById } from './requests'

export function* loadProjectSaga({
  payload,
}: ReturnType<typeof hipProjectActions.loadProject>): IterableIterator<
  | CallEffect
  | SelectEffect
  | TakeEffect
  | PutEffect<ReturnType<typeof hipProjectActions.loadProjectSuccess>>
  | PutEffect<ReturnType<typeof hipProjectActions.loadProjectFailed>>
> {
  const isShareFlow = yield select(getIsShareFlow)
  if (isShareFlow) {
    return
  }
  try {
    const response: experienceDefinitions['GetProjectResponse'] = yield call(
      getProjectById,
      payload
    )
    yield put(hipProjectActions.loadProjectSuccess(response?.data))
  } catch (e) {
    yield put(hipProjectActions.loadProjectFailed(e))
  }
}

export function* sagas(): IterableIterator<ReturnType<typeof takeLatest>> {
  yield takeLatest(HipProjectActionTypes.LOAD_PROJECT, loadProjectSaga)
}
