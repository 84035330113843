import * as React from 'react';
import classnames from 'classnames';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { Icon } from '../../icon';
import { BomPrice } from '../../bomPrice/bomPrice';
import { Text } from '../../text/text';
import styles from './productHeader.module.css';
export const ProductHeader = ({ name, currency, subTotal, classes, error, warning, }) => (React.createElement("div", { className: classnames(styles.BomContentHeader, classes) },
    React.createElement(Text, { el: "h4", classes: styles.ProductTitle, dataTestId: dataTestId.component.productList.productTitle },
        warning && !error && (React.createElement(Icon, { icon: "WARNING_CIRCLE", size: "s", classes: styles.Icon })),
        error && React.createElement(Icon, { icon: "ERROR_CIRCLE", size: "s", classes: styles.Icon }),
        name),
    React.createElement("div", null,
        React.createElement(Text, { weight: "normal", vMargin: "none" }, _t('product_bom.subtotal')),
        React.createElement(BomPrice, { displayPrice: true, size: "l", amount: subTotal, currency: currency, dataTestId: dataTestId.component.productList.productPrice }))));
