import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Notification } from '@hip/components/src/components/notification/notification'

import { actions as notificationsActions } from '../../core/notifications/actions'
import { actions as utilsActions } from '../../core/utils/actions'
import { getNotification } from '../../core/notifications/selectors'
import { TimeoutTypes } from '../../core/utils/interfaces'

export const NotificationsModule: React.FC = () => {
  const dispatch = useDispatch()
  const notification = useSelector(getNotification)

  return (
    <>
      {notification?.type && notification?.body && (
        <Notification
          type={notification.type}
          body={notification.body || ''}
          close={!!notification.close}
          closeAction={(): void => {
            dispatch(notificationsActions.clear())
            dispatch(
              utilsActions.clearTimeout({
                name: TimeoutTypes.NOTIFICATION_TIMEOUT,
              })
            )
          }}
        />
      )}
    </>
  )
}
