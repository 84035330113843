import { COMMUNICATOR_TYPES } from '@hip/communicator'

export interface InitPayload {
  type: COMMUNICATOR_TYPES
}

export enum TimeoutTypes {
  NOTIFICATION_TIMEOUT = 'NOTIFICATION_TIMEOUT',
  COPIED_NOTIFICATION = 'COPIED_NOTIFICATION',
}

export interface TokenPayload {
  token: string
}

export interface AnalyticsPayload {
  analytics: boolean
}

export interface SetCookiesPayload {
  cookies: boolean
}

export interface CopyToClipboardPayload {
  value: string
}

export interface SetTimeoutPayload {
  name: string
  time: number
}

export interface ClearTimeoutPayload {
  name: string
}

export interface CopiedPayload {
  copied: boolean
}
