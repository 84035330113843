import { createSelector } from 'reselect'

import { ApplicationState } from '../../interfaces'
import { DesignState } from './reducer'

const getRoot = ({ design }: ApplicationState): DesignState => design

export const getDesign = createSelector(getRoot, design => design)

export const getVendor = createSelector(getRoot, ({ vendorId }) => vendorId)

export const getMxtToken = createSelector(getRoot, ({ mxtToken }) => mxtToken)

export const getEditId = createSelector(getRoot, ({ editId }) => editId)

export const getViewId = createSelector(getRoot, ({ viewId }) => viewId)

export const getShareIds = createSelector(
  getViewId,
  getEditId,
  (viewId, editId) => ({ viewId, ...(editId && { editId }) })
)

export const getIsDirty = createSelector(getRoot, ({ isDirty }) => isDirty)
