import { createAction, ActionsUnion } from '@hip/helpers'
import { OpenPayload, ClosePayload } from './interfaces'

export enum ActionTypes {
  OPEN = '@hip/modals/OPEN',
  CLOSE = '@hip/modals/CLOSE',
}

export const actions = {
  open: (payload: OpenPayload) => createAction(ActionTypes.OPEN, payload),
  close: (payload: ClosePayload) => createAction(ActionTypes.CLOSE, payload),
}

export type Actions = ActionsUnion<typeof actions>
