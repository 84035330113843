"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.watchSocketEvents = exports.handleChannelEvents = exports.emitUnblockDesignBom = exports.emitAccessShareableDesign = exports.emitAccessDesignBom = exports.emitAccessDesign = exports.disconnectFromEventsService = exports.connectToEventsService = void 0;
const effects_1 = require("redux-saga/effects");
const redux_saga_1 = require("redux-saga");
const socketIOClient = __importStar(require("socket.io-client"));
const events_service_types_1 = require("@hip/events-service-types");
const events_core_1 = require("@hip/events-core");
const actions_1 = require("./actions");
const utils_1 = require("./utils");
let socket;
function connectToEventsService({ baseUrl, path = '/socket.io', query = {}, transports = ['polling'], authorization, }) {
    socket = socketIOClient.connect(baseUrl, {
        path,
        query,
        secure: true,
        transports,
        transportOptions: {
            polling: {
                extraHeaders: {
                    Authorization: authorization,
                },
            },
        },
    });
}
exports.connectToEventsService = connectToEventsService;
function* disconnectFromEventsService() {
    yield effects_1.put(actions_1.actions.closeEventsChannel());
}
exports.disconnectFromEventsService = disconnectFromEventsService;
function* emitAccessDesign(payload) {
    const previousSessionClientId = yield effects_1.call(utils_1.getClientIdFromSessionStorage);
    yield effects_1.call(events_core_1.emitAccessDesign, socket, Object.assign(Object.assign({}, payload), { previousSessionClientId }));
}
exports.emitAccessDesign = emitAccessDesign;
function* emitAccessDesignBom(payload) {
    const previousSessionClientId = yield effects_1.call(utils_1.getClientIdFromSessionStorage);
    yield effects_1.call(events_core_1.emitAccessDesignBom, socket, Object.assign(Object.assign({}, payload), { previousSessionClientId }));
}
exports.emitAccessDesignBom = emitAccessDesignBom;
function* emitAccessShareableDesign(payload) {
    const previousSessionClientId = yield effects_1.call(utils_1.getClientIdFromSessionStorage);
    yield effects_1.call(events_core_1.emitAccessShareableDesign, socket, Object.assign(Object.assign({}, payload), { previousSessionClientId }));
}
exports.emitAccessShareableDesign = emitAccessShareableDesign;
function* emitUnblockDesignBom(payload) {
    yield effects_1.call(events_core_1.emitUnlockDesignBom, socket, payload);
}
exports.emitUnblockDesignBom = emitUnblockDesignBom;
function createChannel(socket) {
    return redux_saga_1.eventChannel(emit => {
        const accessDesignSuccessHandler = (event) => {
            emit({ type: events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_SUCCESS, event });
            utils_1.setClientIdToSessionStorage(socket.id);
        };
        const accessDesignErrorHandler = (event) => {
            emit({ type: events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_ERROR, event });
        };
        const accessDesignBomSuccessHandler = (event) => {
            emit({ type: events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_SUCCESS, event });
            utils_1.setClientIdToSessionStorage(socket.id);
        };
        const accessDesignBomErrorHandler = (event) => {
            emit({ type: events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_ERROR, event });
        };
        const accessShareableDesignSuccessHandler = (event) => {
            emit({
                type: events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_SUCCESS,
                event,
            });
            utils_1.setClientIdToSessionStorage(socket.id);
        };
        const accessShareableDesignErrorHandler = (event) => {
            emit({
                type: events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_ERROR,
                event,
            });
        };
        const unblockDesignBomSuccessHandler = (event) => {
            emit({ type: events_service_types_1.UNLOCK_DESIGN_BOM_EVENTS.UNLOCK_DESIGN_BOM_SUCCESS, event });
        };
        events_core_1.subscribeToAccessDesignSuccess(socket, accessDesignSuccessHandler);
        events_core_1.subscribeToAccessDesignError(socket, accessDesignErrorHandler);
        events_core_1.subscribeToAccessDesignBomSuccess(socket, accessDesignBomSuccessHandler);
        events_core_1.subscribeToAccessDesignBomError(socket, accessDesignBomErrorHandler);
        events_core_1.subscribeToAccessShareableDesignSuccess(socket, accessShareableDesignSuccessHandler);
        events_core_1.subscribeToAccessShareableDesignError(socket, accessShareableDesignErrorHandler);
        events_core_1.subscribeToUnlockDesignBomSuccess(socket, unblockDesignBomSuccessHandler);
        return () => {
            socket.off(events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_SUCCESS, accessDesignSuccessHandler);
            socket.off(events_service_types_1.ACCESS_DESIGN_EVENTS.ACCESS_DESIGN_ERROR, accessDesignErrorHandler);
            socket.off(events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_SUCCESS, accessDesignBomSuccessHandler);
            socket.off(events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_ERROR, accessDesignBomErrorHandler);
            socket.off(events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_SUCCESS, accessShareableDesignSuccessHandler);
            socket.off(events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_ERROR, accessShareableDesignErrorHandler);
            socket.off(events_service_types_1.UNLOCK_DESIGN_BOM_EVENTS.UNLOCK_DESIGN_BOM_SUCCESS, unblockDesignBomSuccessHandler);
        };
    });
}
function* handleChannelEvents(subscribers, action) {
    const foundSubscribers = subscribers.filter(subscriber => subscriber.type === action.type);
    if (foundSubscribers.length) {
        yield effects_1.all(foundSubscribers.map(subscriber => effects_1.fork(subscriber.handler, action.event)));
    }
}
exports.handleChannelEvents = handleChannelEvents;
function* watchSocketEvents(subscribers) {
    const channel = yield effects_1.call(createChannel, socket);
    yield effects_1.takeEvery(channel, handleChannelEvents, subscribers);
    yield effects_1.take(actions_1.ActionTypes.CLOSE_EVENTS_CHANNEL);
    yield effects_1.call(channel.close);
    yield effects_1.call([socket, socket.close]);
}
exports.watchSocketEvents = watchSocketEvents;
