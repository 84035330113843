import { StockSource } from '@hip/interfaces'
import { JsonToXmlOptions } from 'jsontoxml'

export const xmlOptions: JsonToXmlOptions = {
  xmlHeader: true,
  prettyPrint: true,
  indent: ' ',
}

export const AVAILABILITY_INFORMATION_STOCKS: StockSource[] = ['DC', 'STORE']
