import React from 'react';
import { Text } from '../../text/text';
import { Button } from '../../button/button';
import styles from './featureToggleListItem.css';
export const FeatureToggleListItem = ({ feature, value, onChange, }) => {
    const isExists = typeof value === 'boolean';
    return (React.createElement("div", { className: styles.FeatureToggleListItem },
        React.createElement(Text, { size: "xl" },
            feature,
            ":"),
        React.createElement("div", { className: styles.FeatureToggleListItemButtons },
            React.createElement(Button, { type: "secondary", classes: styles.FeatureToggleListItemButton, disabled: value === true, onClick: () => onChange(true) }, "Enabled"),
            React.createElement(Button, { type: "secondary", classes: styles.FeatureToggleListItemButton, disabled: value === false, onClick: () => onChange(false) }, "Disabled"),
            React.createElement(Button, { type: "secondary", classes: styles.FeatureToggleListItemButton, disabled: isExists === false, onClick: () => onChange(null) }, "Not set (default)"))));
};
