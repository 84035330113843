import { call, CallEffect, select } from 'redux-saga/effects'

import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'
import { Features, OPCOS } from '@hip/interfaces'
import { Api } from '@hip/helpers'

import {
  getBearerTokenAuth,
  getIsShareFlow,
  getOPCO,
  getShareFlowAuth,
} from '../utils/selectors'

function* getHeaders() {
  const isShareFlow = yield select(getIsShareFlow)
  const auth = yield select(isShareFlow ? getShareFlowAuth : getBearerTokenAuth)
  const opco: OPCOS = yield select(getOPCO)
  const isTykCustomersApisFeatureEnabled = yield select(
    getIsFeatureEnabledByConst(Features.TYK_CUSTOMER_APIS)
  )

  const xTenantHeader = isTykCustomersApisFeatureEnabled
    ? { 'x-tenant': opco }
    : {}

  return {
    Authorization: auth,
    ...xTenantHeader,
  }
}

function* get<Params = void, Response = void>(
  url: string,
  params?: Params
): IterableIterator<CallEffect | Promise<Response>> {
  const headers = yield call(getHeaders)
  return yield Api.get<Params, Response>(url, headers, params)
}

function* post<Response = void>(
  url: string,
  body?: Record<string, any>
): IterableIterator<CallEffect | Promise<Response>> {
  const headers = yield call(getHeaders)
  return yield Api.post<Response>(url, headers, body)
}

function* patch<Response = void>(
  url: string,
  body?: Record<string, any>
): IterableIterator<CallEffect | Promise<Response>> {
  const headers = yield call(getHeaders)
  return yield Api.patch<Response>(url, headers, body)
}
export const hipApi = { get, post, patch }
