import * as React from 'react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { Notification } from '../notification/notification';
import { Placeholder } from '../placeholder/placeholder';
import { Hammertime } from '../hammertime';
import { Icon } from '../icon';
import { Button } from '../button/button';
import { Text } from '../text/text';
import { MissingProducts } from '../missingProducts/missingProducts';
import { OrderSummaryCard, QuoteErrorCard, QuoteInfoCard, WarningCard, WarningType, } from './sidebarComponents';
import { Product } from './product/product';
import styles from './productList.module.css';
import { ProductStockGeneralError } from './productStockGeneralError/productStockGeneralError';
export const ProductList = ({ opco, onClose, isLoading = false, data, handleAddToBasket, handleExportBOMToXML, handlePrintBOM, quoteError, quoteType, quoteId, handleQuoteClick, isCreatingQuote = false, designHasQuote = false, isColleagueOwnedProject = false, navigateToDotComPlus, financeLink, allProductStockMessages, productStockGeneralErrors, }) => {
    var _a, _b;
    const meta = data === null || data === void 0 ? void 0 : data.additionalInfo.meta;
    const hasErrors = Boolean(((_a = meta === null || meta === void 0 ? void 0 : meta.errors) === null || _a === void 0 ? void 0 : _a.length) > 0);
    const hasWarnings = Boolean(((_b = meta === null || meta === void 0 ? void 0 : meta.warnings) === null || _b === void 0 ? void 0 : _b.length) > 0);
    const isColleague = !!handleQuoteClick && !!navigateToDotComPlus;
    const showCreateQuote = !isColleagueOwnedProject && isColleague;
    const designName = data.additionalInfo.designName;
    const errors = data.additionalInfo.meta.errors;
    const warnings = data.additionalInfo.meta.warnings;
    return (React.createElement("div", { className: styles.ProductListWrapper, "data-test-id": dataTestId.component.productList.container },
        React.createElement("header", { className: styles.Header, "data-test-id": dataTestId.component.productList.header },
            React.createElement("div", { className: styles.TitleWrapper },
                React.createElement(Icon, { icon: "KITCHEN", classes: styles.Icon }),
                React.createElement(Text, { el: "h2", weight: "normal", classes: styles.Title, vMargin: "none", dataTestId: dataTestId.component.productList.title }, designName)),
            React.createElement(Button, { type: "naked", onClick: onClose, dataTestId: dataTestId.component.productList.btnClose },
                React.createElement(Icon, { icon: "CLOSE" }))),
        hasWarnings &&
            warnings.map(warning => (React.createElement(Notification, { key: warning, type: "warning", body: _t(`product_bom.notifications.n${warning.toString()}`) }))),
        hasErrors &&
            errors.map(error => (React.createElement(Notification, { key: error, type: "danger", body: _t(`product_bom.notifications.n${error.toString()}`) }))),
        productStockGeneralErrors && (React.createElement(React.Fragment, null, productStockGeneralErrors.map((errorText, key) => (React.createElement(ProductStockGeneralError, { message: errorText, key: key }))))),
        data.missingEans.length > 0 && (React.createElement(MissingProducts, { missingProducts: data.missingEans })),
        React.createElement("div", { className: styles.Container },
            isLoading && React.createElement(Hammertime, null),
            !isLoading && (!data || !data.groups.length) && (React.createElement("div", { className: styles.PlaceholderContainer },
                React.createElement(Placeholder, { src: "/welcome-create-customer.png", title: _t('product_bom.no_products_title'), subTitle: _t('product_bom.no_products_body'), buttonText: _t('back'), dataTestId: dataTestId.component.productList.placeholderNoData, onClick: onClose }))),
            data && data.groups.length > 0 && !isLoading && Boolean(data) && (React.createElement("div", { className: styles.Columns },
                React.createElement("div", { className: styles.Sidebar },
                    React.createElement(OrderSummaryCard, { opco: opco, subTotal: data.subTotal, showCreateQuote: showCreateQuote, financeLink: financeLink, disableCreateQuote: !isColleague || isCreatingQuote || hasErrors || designHasQuote, quote: { quoteType, quoteId }, createQuoteFn: handleQuoteClick, handleAddToBasket: handleAddToBasket, handleExportBOMToXML: handleExportBOMToXML, handlePrintBOM: handlePrintBOM }),
                    isColleague && designHasQuote && (React.createElement(QuoteInfoCard, { quote: { quoteType, quoteId }, ctaClickFn: navigateToDotComPlus })),
                    isColleague && quoteError && React.createElement(QuoteErrorCard, null),
                    hasErrors && (React.createElement(WarningCard, { type: WarningType.REGULATORY, alerts: meta.errors })),
                    hasWarnings && (React.createElement(WarningCard, { type: WarningType.ADVISORY, alerts: meta.warnings }))),
                React.createElement("div", { className: styles.ProductListContainer, "data-test-id": dataTestId.component.productList.listContainer }, data.groups.map((group, groupIndex) => (React.createElement(React.Fragment, { key: group.name },
                    React.createElement(Text, { el: "h2", weight: "normal", classes: styles.CategoryName, dataTestId: dataTestId.component.productList.groupName }, group.name),
                    React.createElement("div", { className: styles.ProductList, "data-test-id": dataTestId.component.productList.groupProductList }, group.assemblies.map((assembly, assemblyIndex) => (React.createElement(Product, Object.assign({ key: assemblyIndex, initialIsOpen: groupIndex === 0 && assemblyIndex === 0, allProductStockMessages: allProductStockMessages }, assembly))))))))))))));
};
