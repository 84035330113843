import React from 'react'
import { _t } from '@hip/translations'
import { Helmet } from 'react-helmet'

import { OPCOS } from '@hip/interfaces'
import { Header } from '../../header/header'
import { Footer } from '../../footer/footer'

import styles from './main.module.css'

interface MainProps {
  vendorId?: string
  opco?: OPCOS
  exit: () => void
  handleNewDesignClick: () => void
  handleAppointmentClick: () => void
  handleDuplicate: () => void
  handleShare: () => void
  children: React.ReactNode
}

export const Main: React.FC<MainProps> = ({
  vendorId,
  opco,
  exit,
  handleNewDesignClick,
  handleAppointmentClick,
  handleDuplicate,
  handleShare,
  children,
}: MainProps) => (
  <div className={styles.App}>
    <Helmet>
      <title>{_t('title')}</title>
    </Helmet>
    <Header
      opco={opco}
      handleNewDesignClick={handleNewDesignClick}
      handleAppointmentClick={handleAppointmentClick}
      handleDuplicate={handleDuplicate}
      handleShare={handleShare}
      vendorId={vendorId}
      exit={exit}
    />
    <main className={styles.Main}>
      <div className={styles.Wrapper}>{children}</div>
    </main>
    <Footer exit={exit} opco={opco} />
  </div>
)
