export enum ANALYTICS {
  EXIT = 'exit',
  BOOK_APPOINTMENT = 'book_appointment',
  DUPLICATE = 'design_duplicate',
  NEW_DESIGN = 'design_new',
  SHARE_EMAIL = 'share_email',
  SHARE_FACEBOOK = 'share_facebook',
  SHARE_WHATS_APP = 'share_whats_app',
  SHARE_TWITTER = 'share_twitter',
  DESIGN_BOM_PRINT = 'create_print_view',
}
