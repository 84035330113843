import { createAction, ActionsUnion } from '@hip/helpers'
import { ErrorPayload } from './interfaces'

export enum ActionTypes {
  PAGE_ERROR = '@hip/errors/PAGE_ERROR',
  SECTION_ERROR = '@hip/errors/SECTION_ERROR',
}

export const actions = {
  pageError: (payload: ErrorPayload) =>
    createAction(ActionTypes.PAGE_ERROR, payload),
  sectionError: (payload: ErrorPayload) =>
    createAction(ActionTypes.SECTION_ERROR, payload),
}

export type Actions = ActionsUnion<typeof actions>
