import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionWithPayloadHelper } from '@hip/helpers'
import { requireConfirmationActions } from '@hip/util-sagas'
import { _t } from '@hip/translations'

import { MODALS } from '../../core/modals/reducer'
import { getModal } from '../../core/modals/selectors'
import {
  actions as modalActions,
  ActionTypes as ModalActionTypes,
} from '../../core/modals/actions'
import {
  actions as designActions,
  ActionTypes as DesignActionTypes,
} from '../../core/design/actions'
import {
  actions as utilsActions,
  ActionTypes as UtilsActionTypes,
} from '../../core/utils/actions'

import { DuplicateModal } from '../../components/modals/duplicate-modal/duplicate-modal'
import { ShareModal } from '../../components/modals/share-modal/share-modal'
import { ConfirmActionModal } from '../../components/modals/confirm-action/confirm-action'
import { LoadFromAutoSaveModal } from '../../components/modals/load-from-auto-save-modal/load-from-auto-save-modal'
import { DesignWarningModal } from '../../components/modals/design-warning-modal/design-warning-modal'

import { ClosePayload } from '../../core/modals/interfaces'
import { DuplicateDesignPayload } from '../../core/design/interfaces'
import { getShareIds } from '../../core/design/selectors'
import {
  getDesignId,
  getProjectId,
  getShareId,
  getHostUrl,
  getCopied,
} from '../../core/utils/selectors'
import {
  CopyToClipboardPayload,
  TimeoutTypes,
  ClearTimeoutPayload,
} from '../../core/utils/interfaces'
import { getProjectStatus } from '../../core/project/selectors'

export const ModalsModule: React.FC = () => {
  const dispatch = useDispatch()
  const duplicateModal = useSelector(getModal(MODALS.DUPLICATE))
  const shareModal = useSelector(getModal(MODALS.SHARE))
  const confirmActionModal = useSelector(getModal(MODALS.CONFIRM_ACTION))
  const confirmLoadFromAutoSave = useSelector(
    getModal(MODALS.LOAD_FROM_AUTO_SAVE)
  )
  const designWarningModal = useSelector(getModal(MODALS.DESIGN_WARNING_MODAL))

  const projectId = useSelector(getProjectId)
  const designId = useSelector(getDesignId)
  const shareId = useSelector(getShareId)
  const shareIds = useSelector(getShareIds)
  const hostUrl = useSelector(getHostUrl)
  const copied = useSelector(getCopied)
  const projectStatus = useSelector(getProjectStatus)

  const duplicate = (): ActionWithPayloadHelper<
    DesignActionTypes.DUPLICATE,
    DuplicateDesignPayload
  > => dispatch(designActions.duplicate({ projectId, designId, shareId }))

  const close = (
    modal: MODALS
  ): ActionWithPayloadHelper<ModalActionTypes.CLOSE, ClosePayload> =>
    dispatch(modalActions.close({ modal }))

  const copyToClipboard = (
    elm
  ): ActionWithPayloadHelper<
    UtilsActionTypes.COPY_TO_CLIPBOARD,
    CopyToClipboardPayload
  > => dispatch(utilsActions.copyToClipboard(elm))

  const closeNotification = (): ActionWithPayloadHelper<
    UtilsActionTypes.CLEAR_TIMEOUT,
    ClearTimeoutPayload
  > =>
    dispatch(
      utilsActions.clearTimeout({ name: TimeoutTypes.COPIED_NOTIFICATION })
    )

  const confirmAction = () =>
    dispatch(requireConfirmationActions.confirmAction())
  const rejectAction = () => dispatch(requireConfirmationActions.rejectAction())

  return (
    <>
      <DuplicateModal
        duplicate={duplicate}
        show={!!duplicateModal}
        close={() => close(MODALS.DUPLICATE)}
      />

      <ShareModal
        hostUrl={hostUrl}
        shareIds={shareIds}
        copyToClipboard={copyToClipboard}
        show={!!shareModal}
        copied={copied}
        closeNotification={closeNotification}
        close={() => close(MODALS.SHARE)}
      />

      <ConfirmActionModal
        show={!!confirmActionModal}
        confirm={confirmAction}
        reject={rejectAction}
      />

      <LoadFromAutoSaveModal
        show={!!confirmLoadFromAutoSave}
        modalData={confirmLoadFromAutoSave?.modalData}
      />

      <DesignWarningModal
        show={!!designWarningModal}
        description={_t(`design-warning-modal.description.${projectStatus}`)}
        close={() => close(MODALS.DESIGN_WARNING_MODAL)}
      />
    </>
  )
}
