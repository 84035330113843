import React from 'react'
import { useSelector } from 'react-redux'

import { _t } from '@hip/translations'
import { OPCOS } from '@hip/interfaces'
import { createShareRoute } from '@hip/helpers'
import { dataTestId } from '@hip/test-attributes'
import { Notification } from '@hip/components/src/components/notification/notification'
import { Text } from '@hip/components/src/components/text/text'
import { Button } from '@hip/components/src/components/button/button'
import { Dropdown } from '@hip/components/src/components/dropdown/dropdown'
import { Icon } from '@hip/components/src/components/icon'
import { getOPCO } from '../../../core/utils/selectors'
import { Modal } from '../modal/modal'

import {
  ShareButtons,
  ShareButtonTypes,
} from '../../share-buttons/share-buttons'

import styles from './share-modal.module.css'

enum ShareTypes {
  VIEW = 'view',
  EDIT = 'edit',
}

interface ShareIds {
  viewId: string
  editId?: string
}

interface ShareModalProps {
  hostUrl: string
  show: boolean
  copied: boolean
  closeNotification: () => void
  close: () => void
  shareIds: ShareIds
  copyToClipboard: (elm: unknown) => void
}

export const ShareModal: React.FC<ShareModalProps> = ({
  hostUrl,
  show,
  close,
  copied,
  closeNotification,
  shareIds,
  copyToClipboard,
}: ShareModalProps) => {
  const elm = React.useRef()
  const [shareId, setShareId] = React.useState(
    shareIds && shareIds.editId ? shareIds.editId : shareIds.viewId
  )
  const [mode, setMode] = React.useState(
    shareIds.editId ? ShareTypes.EDIT : ShareTypes.VIEW
  )

  const opco = useSelector(getOPCO)

  const handleSetId = (mode: ShareTypes): void => {
    setMode(mode)
    setShareId(shareIds[`${mode}Id`])
  }

  const createLink = (id: string): string => `${hostUrl}${createShareRoute(id)}`

  React.useEffect(() => {
    setShareId(shareIds[`${mode}Id`])
  }, [shareIds, mode])

  return (
    <Modal show={show} onClose={close} title={_t('share.title')}>
      <Text vMargin="xxxl">{_t('share.intro')}</Text>
      <div className={styles.CopyLinkControls}>
        <input
          className={styles.Input}
          ref={elm}
          value={createLink(shareId)}
          readOnly
          data-test-id={dataTestId.cdt.shareDesign.link}
        />
        {opco === OPCOS.BDRO && (
          <Dropdown
            buttonComponent={DropdownButton}
            optionComponent={DropdownOption}
            options={[
              {
                value: 'view',
                label: _t('share.access-view'),
                dataTestId: dataTestId.cdt.shareDesign.optionView,
              },
              {
                value: 'edit',
                label: _t('share.access-edit'),
                dataTestId: dataTestId.cdt.shareDesign.optionEdit,
              },
            ]}
            onChange={handleSetId}
            classes={styles.ControlButton}
          />
        )}
        <Button
          classes={styles.ControlButton}
          onClick={() => copyToClipboard(elm.current)}
          type="primary"
          dataTestId={dataTestId.cdt.shareDesign.btnCopy}
        >
          <Text vMargin="none" color="white">
            {_t('share.button-copy')}
          </Text>
        </Button>
      </div>
      {copied && (
        <Notification
          type="success"
          body={_t('share.copy-success')}
          close={true}
          closeAction={closeNotification}
        />
      )}
      <div className={styles.ShareButtonsWrapper}>
        <Text vMargin="none">{_t('share.label-send')}</Text>
        <ul className={styles.ShareButtonList}>
          {ShareButtonTypes.map(variant => (
            <li key={variant} className={styles.ShareButtonItem}>
              <ShareButtons variant={variant} link={createLink(shareId)} />
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  )
}

const DropdownButton: React.FC<{
  label: string
  onClick: () => void
  isOpen: boolean
}> = ({ label, onClick, isOpen }) => (
  <Button
    onClick={onClick}
    type="secondary"
    dataTestId={dataTestId.cdt.shareDesign.btnAccessType}
  >
    <Text vMargin="none" color="white">
      {label}
    </Text>
    <Icon
      inline
      lPadding="l"
      icon={isOpen ? 'CHEVRON_UP' : 'CHEVRON_DOWN'}
      size="xxs"
      color="white"
    />
  </Button>
)

const DropdownOption: React.FC<{
  label: string
  dataTestId: string
  onClick: () => void
}> = ({ label, dataTestId, onClick }) => (
  <li className={styles.DropdownOptionItem}>
    <Button
      classes={styles.DropdownButton}
      type="naked"
      dataTestId={dataTestId}
      onClick={onClick}
    >
      <Text vMargin="none">{label}</Text>
    </Button>
  </li>
)
