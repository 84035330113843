import React from 'react'
import { render } from 'react-dom'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { isProd } from '@hip/helpers'
import { OPCOS } from '@hip/interfaces'
import '@hip/theme/src/theme.module.css'

import { Routes } from './routes'
import { createRootReducers } from './reducers'
import { sagas } from './sagas'

import './index.module.css'

const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const store = createStore(
  createRootReducers(history),
  isProd()
    ? applyMiddleware(routerMiddleware(history), sagaMiddleware)
    : composeEnhancers(
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
      )
)

sagaMiddleware.run(sagas)

const assignOPCOThemeVariables = (opco: string = OPCOS.BQUK) => {
  document.body.style.setProperty(
    '--color-primary',
    `var(--color-primary-${opco.toLowerCase()})`
  )
}

assignOPCOThemeVariables(process.env.TENANT)

render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
