import * as React from 'react';
import { _t } from '@hip/translations';
import { Text } from '../text/text';
import styles from './printedMissingProductsDetailedTableRow.module.css';
export const PrintedMissingProductsDetailedTableRow = ({ product }) => {
    const { image, ean, quantity, name } = product;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.ProductThumb },
            React.createElement("img", { src: image || '/projectcard-no-img.png' })),
        React.createElement("div", { className: styles.ProductDetails },
            React.createElement("div", { className: styles.ProductDetailsContent },
                React.createElement("div", { className: styles.ProductName },
                    React.createElement("p", { className: styles.ProductNameText }, name),
                    React.createElement("p", { className: styles.ProductCode },
                        _t('product_bom.product_code'),
                        " ",
                        ean)),
                React.createElement("div", { className: styles.ProductQuantity },
                    React.createElement("p", null, quantity)),
                React.createElement("div", { className: styles.ProductPrice },
                    React.createElement(Text, null, "-"))))));
};
