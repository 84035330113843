import { createAction, ActionsUnion } from '@hip/helpers'
import { UserData } from './interfaces'

export enum ActionTypes {
  SET_USER_DATA = '@hip/user/SET_USER_DATA',
  DECODE_USER_DATA_FAILURE = '@hip/user/DECODE_USER_DATA_FAILURE',
}

export const actions = {
  setUserData: (data: Partial<UserData>) =>
    createAction(ActionTypes.SET_USER_DATA, { data }),
  decodeUserDataFailure: (error: Error) =>
    createAction(ActionTypes.DECODE_USER_DATA_FAILURE, { error }),
}

export type Actions = ActionsUnion<typeof actions>
