import { FeatureToggleListValues } from './interfaces'

export const FEATURE_TOGGLES_STORAGE_KEY = 'featureTogglesDashboard'

export const loadFeatures = (): FeatureToggleListValues => {
  const value = window.localStorage.getItem(FEATURE_TOGGLES_STORAGE_KEY)
  if (value) {
    try {
      const parsed = JSON.parse(value)
      return typeof parsed === 'object' && parsed ? parsed : {}
    } catch (err) {
      console.error('LoadFeatures error: ', err)
    }
  }
  return null
}

export const saveFeatures = (values: FeatureToggleListValues): void => {
  const json = JSON.stringify(values ?? {})
  window.localStorage.setItem(FEATURE_TOGGLES_STORAGE_KEY, json)
}
