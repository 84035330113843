export interface MarxentInitPayload {
  mxtEvent: any
}

export enum MarxentEventKind {
  DESIGN_CREATED = 'DesignCreated',
  DESIGN_SAVED = 'DesignSaved',
  BOM_SAVED = 'BomSaved',
  TOKEN_REFRESH_REQUESTED = 'TokenRefreshRequested',
  DIRTY_STATE_CHANGED = 'DirtyStateChanged',
  JOURNEY_SELECTED = 'JourneySelected',
  PROMPT_LOAD_FROM_AUTO_SAVE = 'PromptLoadFromAutoSave',
}

export enum AutoSavePromptResponse {
  AUTOSAVE_APPROVED = 'AUTOSAVE_APPROVED',
  AUTOSAVE_REJECTED = 'AUTOSAVE_REJECTED',
  AUTOSAVE_SKIPPED = 'AUTOSAVE_SKIPPED',
}

export interface MxtAutoSavePromptResponseData {
  eventUUID: string
  response: AutoSavePromptResponse
}

export enum AutoSaveMarxentResponseType {
  DONT_LOAD = 0,
  LOAD = 1,
  DONT_LOAD_AND_DELETE = 2,
}

export enum MarxentJourneyType {
  KITCHEN = 'kitchen',
  BATHROOM = 'bathroom',
  BEDROOM = 'bedroom',
}

export interface MarxentJourneyPayload {
  journey: MarxentJourneyType
}
