import React from 'react';
import { _t } from '@hip/translations';
import { Text } from '../text/text';
import { BomPrice } from '../bomPrice/bomPrice';
import { PrintedTableFooter } from '../printedTableComponents/printedTableComponents';
import styles from './printedEnhancedBomSimpleTableFooter.module.css';
export const PrintedEnhancedBomSimpleTableFooter = ({ amount, currency, }) => (React.createElement(PrintedTableFooter, null,
    React.createElement(Text, { weight: "bold", vMargin: "none", classes: styles.FooterPriceTitle },
        _t('product_bom.total'),
        ":\u00A0"),
    React.createElement(BomPrice, { weight: "bold", amount: amount, currency: currency })));
