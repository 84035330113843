import React from 'react'

import { _t } from '@hip/translations'
import { OPCOS } from '@hip/interfaces'
import { Text } from '@hip/components/src/components/text/text'
import { isMobileDevice } from '@hip/helpers'

import styles from './footer.module.css'

interface FooterProps {
  opco?: OPCOS
  exit: () => void
}

export const Footer: React.FC<FooterProps> = ({ opco, exit }: FooterProps) => {
  const isMobile = isMobileDevice()

  return (
    <footer className={styles.Footer}>
      {!isMobile && (
        <Text
          classes={styles.Copyright}
          onClick={exit}
          vMargin="none"
          size="s"
          color="inherit"
        >
          &copy; {opco && _t(`opcos.${opco}`)} {new Date().getFullYear()}
        </Text>
      )}

      <ul className={styles.FooterLinkList}>
        <FooterLinkItem
          href={_t('links.terms')}
          label={_t(isMobile ? 'footer.terms_mobile' : 'footer.terms')}
        />
        <FooterLinkItem
          href={_t('links.privacy')}
          label={_t(isMobile ? 'footer.privacy_mobile' : 'footer.privacy')}
        />
        <FooterLinkItem
          href={_t('links.cookies')}
          label={_t('footer.cookies')}
        />
        <FooterLinkItem
          href={_t('links.contact')}
          label={_t(isMobile ? 'footer.contact' : 'footer.contact')}
        />
      </ul>
    </footer>
  )
}

const FooterLinkItem: React.FC<{ href: string; label: string }> = ({
  href,
  label,
}) => (
  <li className={styles.FooterLinkItem}>
    <a
      className={styles.FooterLink}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Text vMargin="none" size="s" color="inherit">
        {label}
      </Text>
    </a>
  </li>
)
