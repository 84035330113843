import React, { useRef } from 'react'
import { compose, defaultTo, find, flatten, groupBy } from 'lodash/fp'

import {
  ErrorMessage,
  WarningMessage,
} from '@hip/components/src/components/messages'
import { Page } from '@hip/components/src/components/page/page'
import { DynamicPage } from '@hip/components/src/components/dynamicPage/dynamicPage'
import { BomPdfHeader } from '@hip/components/src/components/bomPdfHeader/bomPdfHeader'
import { BomPdfFooter } from '@hip/components/src/components/bomPdfFooter/bomPdfFooter'
import { PrintedEnhancedBomSimpleTable } from '@hip/components/src/components/printedEnhancedBomSimpleTable/printedEnhancedBomSimpleTable'
import { PrintedEnhancedBomSimpleTableFooter } from '@hip/components/src/components/printedEnhancedBomSimpleTableFooter/printedEnhancedBomSimpleTableFooter'
import {
  PrintedTableHorizontalBorder,
  PrintedTableSpacer,
} from '@hip/components/src/components/printedTableComponents/printedTableComponents'
import { PrintedEnhancedBomDetailedTable } from '@hip/components/src/components/printedEnhancedBomDetailedTable/printedEnhancedBomDetailedTable'
import { PrintedMissingProductsDetailedTable } from '@hip/components/src/components/printedMissingProductsDetailedTable/printedMissingProductsDetailedTable'
import { PrintedBomBulletList } from '@hip/components/src/components/printedBomBulletList/printedBomBulletList'
import { Installation } from '@hip/components/src/components/installation/installation'
import { Overview } from '@hip/components/src/components/overview/overview'
import { Text } from '@hip/components/src/components/text/text'
import { _t } from '@hip/translations'
import {
  HydratedBom,
  IPromotionalAsset,
  IScreenshot,
  Locale,
  OPCOS,
  PROMOTIONAL_ASSET_TYPE,
  SCREENSHOT_TYPE,
} from '@hip/interfaces'
import { MissingProducts } from '@hip/components/src/components/missingProducts/missingProducts'

import styles from './printable-bom.module.css'

interface OrderScreenshot extends IScreenshot {
  title: string
}

const orderScreenshots = (screenshots: IScreenshot[]): OrderScreenshot[] => {
  const groupedScreenshots = groupBy('type', screenshots)
  const order = [
    { type: SCREENSHOT_TYPE.THREE_D_VIEW, text: _t('bom_pdf.view_3d') },
    { type: SCREENSHOT_TYPE.SIDE_VIEW, text: _t('bom_pdf.view') },
    { type: SCREENSHOT_TYPE.UNSPECIFIED_VIEW, text: _t('bom_pdf.view') },
    { type: SCREENSHOT_TYPE.TOP_VIEW, text: _t('bom_pdf.view_top') },
  ]
  return flatten(
    order.map(({ type, text }) => {
      const typeScreenshots = defaultTo([], groupedScreenshots[type])
      const multiple = typeScreenshots.length > 1

      return typeScreenshots.map((screenshot, index) => ({
        ...screenshot,
        title: multiple ? `${text} ${index + 1}` : text,
      }))
    })
  )
}

const getFinanceImage = (promotionalAssets: IPromotionalAsset[]): string =>
  compose(
    defaultTo({ url: '', type: PROMOTIONAL_ASSET_TYPE.FINANCE }),
    find(({ type }) => type === PROMOTIONAL_ASSET_TYPE.FINANCE)
  )(promotionalAssets).url

interface PrintableBomProps {
  data: HydratedBom
  opco: OPCOS
  barcodesEnabled?: boolean
  onResize: (height: number) => void
}

export const PrintableBom: React.FC<PrintableBomProps> = ({
  data,
  opco,
  barcodesEnabled,
  onResize,
}: PrintableBomProps) => {
  const {
    additionalInfo: {
      designName,
      thumbnailUrl,
      screenshots,
      promotionalAssets,
      generalConditions,
      meta: { warnings, errors },
    },
    subTotal: { amount, currency },
    groups,
    missingEans,
    missingProducts,
  } = data

  const hasErrors = Boolean(errors && errors.length > 0)
  const hasWarnings = Boolean(warnings && warnings.length > 0)

  const installationAssets = promotionalAssets.filter(
    asset => asset.type === PROMOTIONAL_ASSET_TYPE.INSTALLATION
  )
  const printBomRef = useRef<HTMLDivElement>(null)

  const onPageRender = () => {
    const containerHeight = printBomRef.current.clientHeight
    onResize(containerHeight)
  }

  return (
    <div ref={printBomRef} className={styles.PrintDesignBomView}>
      <Page onRender={onPageRender}>
        <Overview
          opco={opco}
          locale={Locale[opco.toString()]}
          designName={designName}
          financeImage={getFinanceImage(promotionalAssets)}
          image={thumbnailUrl}
          amount={amount}
          currency={currency}
        />
      </Page>

      {orderScreenshots(screenshots).map(({ url, title }) => (
        <Page
          onRender={onPageRender}
          key={url}
          header={<BomPdfHeader left={title} right={designName} />}
          footer={<BomPdfFooter opco={opco} />}
        >
          <div className={styles.CenterContent}>
            <img src={url} alt={designName} />
          </div>
        </Page>
      ))}

      {(hasErrors || hasWarnings) && (
        <DynamicPage
          onRender={onPageRender}
          header={
            <BomPdfHeader left={_t('bom_pdf.alerts')} right={designName} />
          }
          footer={<BomPdfFooter opco={opco} />}
        >
          {hasErrors && (
            <>
              <ErrorMessage>
                <Text vMargin="none">
                  {_t('product_bom.notifications.regulatory_warning')}
                </Text>
              </ErrorMessage>
              <PrintedTableSpacer />
              <PrintedBomBulletList
                list={errors.map(num =>
                  _t(`product_bom.notifications.n${num}`)
                )}
              />
              <PrintedTableSpacer />
            </>
          )}

          {hasWarnings && (
            <>
              <WarningMessage>
                <Text vMargin="none">
                  {_t('product_bom.notifications.advisory_warning')}
                </Text>
              </WarningMessage>
              <PrintedTableSpacer />
              <PrintedBomBulletList
                list={warnings.map(num =>
                  _t(`product_bom.notifications.n${num}`)
                )}
              />
            </>
          )}
        </DynamicPage>
      )}

      {groups.length > 0 && (
        <DynamicPage
          onRender={onPageRender}
          header={
            <BomPdfHeader
              left={_t('bom_pdf.product_summary')}
              right={designName}
            />
          }
          footer={<BomPdfFooter opco={opco} />}
          firstChildEl={<PrintedTableHorizontalBorder />}
        >
          {missingEans.length > 0 && (
            <MissingProducts missingProducts={missingEans} />
          )}
          {groups.map(group => (
            <PrintedEnhancedBomSimpleTable
              opco={opco}
              key={group.name}
              title={group.name}
              products={group.assemblies.map(
                ({ name, quantity, unitPrice }) => ({
                  name,
                  quantity,
                  unitPrice,
                })
              )}
            />
          ))}
          {
            <PrintedEnhancedBomSimpleTableFooter
              amount={amount}
              currency={currency}
            />
          }
        </DynamicPage>
      )}

      {groups.length > 0 && (
        <DynamicPage
          onRender={onPageRender}
          header={
            <BomPdfHeader
              left={_t('bom_pdf.product_list')}
              right={designName}
            />
          }
          footer={<BomPdfFooter opco={opco} />}
          firstChildEl={<PrintedTableHorizontalBorder />}
        >
          {groups.map(group =>
            group.assemblies.map(assembly => (
              <PrintedEnhancedBomDetailedTable
                key={assembly.name}
                title={assembly.name}
                unitPrice={assembly.unitPrice}
                quantity={assembly.quantity}
                products={assembly.products}
                barcodesEnabled={barcodesEnabled}
              />
            ))
          )}
        </DynamicPage>
      )}

      {missingProducts.length > 0 && (
        <DynamicPage
          onRender={onPageRender}
          header={
            <BomPdfHeader
              left={_t('bom_pdf.missing_products_list')}
              right={designName}
            />
          }
          footer={<BomPdfFooter opco={opco} />}
          firstChildEl={<PrintedTableHorizontalBorder />}
        >
          {missingProducts.map(product => (
            <PrintedMissingProductsDetailedTable
              key={product.ean}
              product={product}
              barcodesEnabled={barcodesEnabled}
            />
          ))}
        </DynamicPage>
      )}

      {installationAssets.length > 0 && (
        <Page onRender={onPageRender}>
          <div className={styles.CenterContent}>
            <Installation assets={installationAssets} />
          </div>
        </Page>
      )}

      {generalConditions.length > 0 && (
        <DynamicPage
          onRender={onPageRender}
          header={
            <BomPdfHeader
              left={_t('bom_pdf.terms_and_conditions')}
              right={designName}
            />
          }
          footer={<BomPdfFooter opco={opco} />}
        >
          <PrintedBomBulletList list={generalConditions} />
        </DynamicPage>
      )}
    </div>
  )
}
