"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
const helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["GET_FEATURES_SUCCESS"] = "@hip/feature-toggles/GET_FEATURES_SUCCESS";
    ActionTypes["GET_FEATURES_FAILURE"] = "@hip/feature-toggles/GET_FEATURES_FAILURE";
    ActionTypes["UPDATE_FEATURES"] = "@hip/feature-toggles/UPDATE_FEATURES";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    getFeaturesSuccess: (features) => helpers_1.createAction(ActionTypes.GET_FEATURES_SUCCESS, { features }),
    getFeaturesFailure: (error) => helpers_1.createAction(ActionTypes.GET_FEATURES_FAILURE, { error }),
    updateFeatures: (features) => helpers_1.createAction(ActionTypes.UPDATE_FEATURES, { features }),
};
