import React, { useEffect } from 'react';
import styles from './page.module.css';
export const Page = ({ header, footer, children, contentRef, onRender, }) => {
    useEffect(() => {
        onRender && onRender();
    }, []);
    return (React.createElement("div", { className: styles.Page },
        header,
        React.createElement("div", { ref: contentRef, className: styles.PageContent }, children),
        footer));
};
