import { call, select } from 'redux-saga/effects'

import { OPCOS } from '@hip/interfaces'

import { definitions as experienceDefinitions } from '@hip/experience-service-types'

import { hipApi } from '../hip-api/sagas'
import { getOPCO } from '../utils/selectors'
import { getExperienceUrl } from '../utils/sagas'

export function* getProjectById(projectId: string) {
  const baseUrl = yield call(getExperienceUrl, false)
  const opco: OPCOS = yield select(getOPCO)
  const url = `${baseUrl}/${opco}/projects/${projectId}`

  return yield hipApi.get<void, experienceDefinitions['GetProjectResponse']>(
    url
  )
}
