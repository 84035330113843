import * as React from 'react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { WarningMessage } from '../../messages';
import { BomPrice } from '../../bomPrice/bomPrice';
import { Text } from '../../text/text';
import styles from './productItem.module.css';
import { ProductStockMessage, } from '../productStockMessage/productStockMessage';
export const ProductItem = ({ ean, name, quantity = 1, unitPrice, image, productStatus, productStockMessages, }) => (React.createElement("div", { className: styles.ProductListItem, "data-test-id": dataTestId.component.productList.productItem },
    React.createElement("div", { className: styles.PrimaryContent },
        React.createElement("div", { className: styles.ImageWrapper },
            React.createElement("img", { className: styles.Image, src: image || '/projectcard-no-img.png' })),
        React.createElement("div", null,
            React.createElement(Text, { el: "h4", dataTestId: dataTestId.component.productList.productItemName }, name),
            React.createElement(Text, { dataTestId: dataTestId.component.productList.productItemCode },
                _t('product_bom.product_code'),
                " ",
                ean),
            productStatus === 'Discontinued' && (React.createElement(WarningMessage, null,
                React.createElement(Text, null, _t('product_bom.unit_discontinued')))))),
    React.createElement("div", { className: styles.SecondaryContent },
        React.createElement(Text, { dataTestId: dataTestId.component.productList.productQuantity },
            "Qty: ",
            quantity),
        React.createElement("div", { className: styles.ProductPrice }, unitPrice ? (React.createElement(React.Fragment, null,
            React.createElement(BomPrice, { size: "l", amount: quantity * unitPrice.amount, currency: unitPrice.currency, displayPrice: true, dataTestId: dataTestId.component.productList.productItemPrice }),
            React.createElement("div", { className: styles.UnitPrice },
                React.createElement(BomPrice, { size: "m", amount: unitPrice.amount, currency: unitPrice.currency, dataTestId: dataTestId.component.productList.productItemUnitPrice }),
                "\u00A0",
                React.createElement(Text, { vMargin: "none" }, _t('product_bom.unit_price'))))) : (React.createElement(Text, null, "-"))),
        React.createElement("div", { className: styles.FlexBreak }),
        productStockMessages && (React.createElement("div", { className: styles.StockInformation, "data-test-id": dataTestId.component.productList.productStockMessages }, productStockMessages.map((productStockMessageProps, key) => (React.createElement(ProductStockMessage, Object.assign({ key: key }, productStockMessageProps)))))))));
