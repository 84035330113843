import React from 'react';
import classnames from 'classnames';
import { dataTestId } from '@hip/test-attributes';
import { Text } from '../text/text';
import { Icon } from '../icon/icon';
import { Button } from '../button/button';
import styles from './notification.module.css';
const icons = {
    primary: 'WARNING_CIRCLE',
    success: 'WARNING_CIRCLE',
    warning: 'WARNING_CIRCLE',
    danger: 'ERROR_CIRCLE',
};
export const Notification = ({ type, body, close, closeAction, }) => {
    const handleClose = () => {
        if (close && closeAction) {
            closeAction();
        }
    };
    const notificationClasses = classnames(styles.Notification, styles[`Notification-${type}`]);
    return (React.createElement("div", { className: notificationClasses, "data-test-id": dataTestId.component.notification.container },
        React.createElement(Icon, { rPadding: "l", icon: icons[type] }),
        React.createElement(Text, { classes: styles.NotificationText, vMargin: "none" }, body),
        close && (React.createElement(Button, { type: "naked", onClick: handleClose, dataTestId: dataTestId.component.notification.btnClose },
            React.createElement(Icon, { icon: "CLOSE" })))));
};
