import * as React from 'react';
import JsBarcode from 'jsbarcode';
import styles from './barcode.module.css';
export const Barcode = ({ ean }) => {
    React.useEffect(() => {
        JsBarcode(`.barcode-${ean}`).init();
    }, []);
    return (React.createElement("div", { className: styles.Barcode },
        React.createElement("svg", { className: `barcode-${ean}`, "data-format": "EAN13", "data-value": ean, "data-textmargin": "0", "data-margin": "0", "data-fontoptions": "bold" })));
};
