"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const post_1 = require("./src/post");
const interfaces_1 = require("./interfaces");
const init = (url, target, actions, type = interfaces_1.COMMUNICATOR_TYPES.MXT) => {
    let loaded = false;
    let hipCommsPost;
    let callbacks;
    let executeAction;
    if (!url || !target || !actions.loaded) {
        throw Error('Must have a target, url and loaded action');
    }
    // set the iframe url
    target && target.setAttribute('src', url);
    target.setAttribute('scrolling', 'no');
    target.setAttribute('width', '100%');
    target.setAttribute('height', '100%');
    switch (type) {
        case interfaces_1.COMMUNICATOR_TYPES.BOM:
            callbacks = actions;
            if (!callbacks.getProducts ||
                !callbacks.printedBom ||
                !callbacks.closeBom ||
                !callbacks.addToBasket) {
                throw Error('Missing required actions');
            }
            executeAction = (msg, hipCommsPost) => {
                switch (msg.kind) {
                    case interfaces_1.EVENTS.LOADED:
                        loaded = true;
                        callbacks.loaded(hipCommsPost);
                        break;
                    case interfaces_1.EVENTS.GET_PRODUCTS:
                        loaded && callbacks.getProducts(hipCommsPost, msg.data);
                        break;
                    case interfaces_1.EVENTS.PRINTED_BOM:
                        loaded && callbacks.printedBom(hipCommsPost);
                        break;
                    case interfaces_1.EVENTS.ADD_TO_BASKET:
                        loaded && callbacks.addToBasket(hipCommsPost);
                        break;
                    case interfaces_1.EVENTS.CLOSE_BOM:
                        loaded && callbacks.closeBom();
                        break;
                }
            };
            break;
        case interfaces_1.COMMUNICATOR_TYPES.PRINT_BOM:
            callbacks = actions;
            if (!callbacks.getProducts || !callbacks.refreshAuthToken) {
                throw Error('Missing required actions');
            }
            executeAction = (msg, hipCommsPost) => {
                switch (msg.kind) {
                    case interfaces_1.EVENTS.LOADED:
                        loaded = true;
                        callbacks.loaded(hipCommsPost);
                        break;
                    case interfaces_1.EVENTS.RESIZED:
                        loaded && callbacks.resized(msg.data);
                        break;
                    case interfaces_1.EVENTS.GET_PRODUCTS:
                        loaded && callbacks.getProducts(hipCommsPost, msg.data);
                        break;
                    case interfaces_1.EVENTS.REFRESH_AUTH_TOKEN:
                        loaded && callbacks.refreshAuthToken(hipCommsPost);
                        break;
                }
            };
            break;
        case interfaces_1.COMMUNICATOR_TYPES.MXT:
            callbacks = actions;
            if (!callbacks.bom ||
                !callbacks.designCreated ||
                !callbacks.exit ||
                !callbacks.createNewDesign ||
                !callbacks.navigateToAppointmentBooking ||
                !callbacks.refreshAuthToken) {
                throw Error('Missing required actions');
            }
            executeAction = (msg, hipCommsPost) => {
                switch (msg.kind) {
                    case interfaces_1.EVENTS.LOADED:
                        loaded = true;
                        callbacks.loaded(hipCommsPost);
                        break;
                    case interfaces_1.EVENTS.BOM:
                        loaded && callbacks.bom();
                        break;
                    case interfaces_1.EVENTS.BOOK_APPOINTMENT:
                        loaded && callbacks.navigateToAppointmentBooking();
                        break;
                    case interfaces_1.EVENTS.DESIGN_CREATED:
                        loaded && callbacks.designCreated(msg.data);
                        break;
                    case interfaces_1.EVENTS.EXIT:
                        loaded && callbacks.exit();
                        break;
                    case interfaces_1.EVENTS.CREATE_NEW_DESIGN:
                        loaded && callbacks.createNewDesign();
                        break;
                    case interfaces_1.EVENTS.REFRESH_AUTH_TOKEN:
                        loaded && callbacks.refreshAuthToken(hipCommsPost);
                        break;
                }
            };
            break;
    }
    // wait for target to load
    const eventHandler = (event) => {
        const msg = event.data;
        if (!(event.source instanceof MessagePort) &&
            msg.namespace === constants_1.MSG_NAMESPACE &&
            url.includes(event.origin)) {
            if (!hipCommsPost)
                hipCommsPost = post_1.post(event.origin, event.source);
            executeAction(msg, hipCommsPost);
        }
    };
    window.addEventListener('message', eventHandler);
    return {
        unsubscribe: () => window.removeEventListener('message', eventHandler),
    };
};
__exportStar(require("./constants"), exports);
__exportStar(require("./interfaces"), exports);
exports.default = { init, post: post_1.post };
