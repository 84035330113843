import { createSelector } from 'reselect'

import { ApplicationState } from '../../interfaces'
import { UserState } from './interfaces'

const getRoot = ({ user }: ApplicationState): UserState => user

export const getUserGivenName = createSelector(
  getRoot,
  ({ data }) => data?.givenName
)

export const getUserSapStoreID = createSelector(getRoot, ({ data }) => {
  // TODO: HIP-6722 Remove next hidden feature
  // Note: The next code block significantly violates the pure principle
  // of selectors; it should be removed in the mentioned ticket.
  const testStoreId = global?.localStorage?.getItem('TEST_TOKEN_STORE_ID')
  if (testStoreId) {
    return testStoreId
  }
  return data?.sapStoreID
})

export const getIsUserDataReady = createSelector(
  getRoot,
  ({ data, error }) => !!data || !!error
)
