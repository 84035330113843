import { createSelector } from 'reselect'
import { ApplicationState } from '../../interfaces'
import { ProductsState } from './reducer'

const getRoot = ({ products }: ApplicationState): ProductsState => products

export const getIsLoadingProducts = createSelector(
  getRoot,
  ({ isLoading }) => isLoading
)

export const getProducts = createSelector(getRoot, ({ products }) => products)

export const getProductsError = createSelector(getRoot, ({ error }) => error)

export const getStock = createSelector(getRoot, ({ stockData }) => stockData)

export const getStockData = createSelector(getStock, ({ data }) => data)

export const getStockIsLoading = createSelector(
  getStock,
  ({ isLoading }) => isLoading
)

export const getStockError = createSelector(getStock, ({ error }) => error)
