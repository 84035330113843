import * as React from 'react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { Icon } from '../../../icon';
import { SidebarCard } from '../sidebarCard/sidebarCard';
import { SidebarCardHeader } from '../sidebarCard/sidebarCardHeader/sidebarCardHeader';
import styles from './quoteErrorCard.module.css';
export const QuoteErrorCard = () => (React.createElement(SidebarCard, { classes: styles.QuoteErrorCard, dataTestId: dataTestId.component.productList.quoteErrorContainer },
    React.createElement(SidebarCardHeader, null,
        React.createElement(Icon, { size: "s", classes: styles.SidebarHeaderIcon, inline: true, icon: "WARNING_CIRCLE_WHITE" }),
        React.createElement("h4", { className: styles.Title }, _t('generic.error'))),
    React.createElement("p", null, _t('product_bom.error_quoted'))));
