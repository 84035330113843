import * as React from 'react';
import classnames from 'classnames';
import { Icon } from '../icon';
import { Base } from './base/base';
import styles from './accordionHip.module.css';
export const AccordionHip = ({ children, initialIsOpen = false, headerContent, wrapperClasses, headerClasses, contentClasses, }) => {
    return (React.createElement(Base, { className: classnames(styles.Container, wrapperClasses), initialIsOpen: initialIsOpen, Header: ({ isOpen }) => (React.createElement("div", { className: classnames(styles.Header, headerClasses) },
            React.createElement("div", { className: styles.HeaderContent }, headerContent),
            React.createElement("div", { className: styles.HeaderIcon },
                React.createElement(Icon, { icon: isOpen ? 'CHEVRON_UP' : 'CHEVRON_DOWN', size: "s" })))) },
        React.createElement("div", { className: classnames(styles.Content, contentClasses) }, children)));
};
