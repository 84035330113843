import * as React from 'react';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';
import { PrintedTableHeader, PrintedTableRow, PrintedTableSpacer, } from '../printedTableComponents/printedTableComponents';
import { Text } from '../text/text';
import { BomPrice } from '../bomPrice/bomPrice';
import styles from './printedEnhancedBomSimpleTable.module.css';
// PrintedEnhancedBomSimpleTable is wrapped in a fragment intentionally because the DynamicPage
// component with which it is used slices it's immediate children into sub-arrays to be rendered
// across multiple pages when necessary. Returning this list wrapped in a single element
// would prevent this from working correctly.
// Ternary condition inside render function means that the table header is grouped with the first
// row of the table. This means that the two will never be rendered on separate pages.
// Spacer is rendered after final row so as to provide visual separation between tables
const TableHeader = ({ title }) => (React.createElement(PrintedTableHeader, null,
    React.createElement(Text, { vMargin: "none", weight: "bold" }, title)));
const TableRow = ({ product, opco, }) => {
    const { name, quantity, unitPrice } = product;
    return (React.createElement(PrintedTableRow, null,
        React.createElement(Text, { weight: "bold", classes: classnames(styles.SimpleTableCell, styles.ProductNameCell) }, name),
        React.createElement(Text, { weight: "bold", classes: classnames(styles.SimpleTableCell, styles.QuantityCell) },
            "x",
            quantity),
        Boolean(unitPrice) && (React.createElement(BomPrice, { size: "s", weight: "bold", amount: unitPrice.amount, currency: unitPrice.currency, classes: classnames(styles.SimpleTableCell, styles.PriceCell) }))));
};
export const PrintedEnhancedBomSimpleTable = ({ title, products, opco, }) => (React.createElement(React.Fragment, null, products.map((product, i) => {
    const isFinalRow = i === products.length - 1;
    const isFirstRow = i === 0;
    return (React.createElement(React.Fragment, { key: uuid() },
        isFirstRow ? (React.createElement(React.Fragment, null,
            React.createElement(TableHeader, { title: title }),
            React.createElement(TableRow, { product: product, opco: opco }))) : (React.createElement(TableRow, { product: product, opco: opco })),
        isFinalRow && React.createElement(PrintedTableSpacer, null)));
})));
