"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requireConfirmation = void 0;
const effects_1 = require("redux-saga/effects");
const actions_1 = require("./actions");
function* getUserConfirmation({ onStart, onEnd, }) {
    yield effects_1.call(onStart);
    const { accept, cancel } = yield effects_1.race({
        accept: effects_1.take(actions_1.ActionTypes.CONFIRM_ACTION),
        cancel: effects_1.take(actions_1.ActionTypes.REJECT_ACTION),
    });
    yield effects_1.call(onEnd);
    if (accept)
        return true;
    else if (cancel)
        return false;
}
function* requireConfirmation({ payload: { onStart, onEnd, onConfirm }, }) {
    const isActionConfirmed = yield effects_1.call(getUserConfirmation, {
        onStart,
        onEnd,
    });
    if (!isActionConfirmed)
        return;
    yield effects_1.call(onConfirm);
}
exports.requireConfirmation = requireConfirmation;
