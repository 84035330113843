import { ActionTypes, Actions } from './actions'
import { ModalParams } from './interfaces'

export enum MODALS {
  SHARE = 'SHARE',
  DUPLICATE = 'DUPLICATE',
  CONFIRM_ACTION = 'CONFIRM_ACTION',
  LOAD_FROM_AUTO_SAVE = 'LOAD_FROM_AUTO_SAVE',
  DESIGN_WARNING_MODAL = 'DESIGN_WARNING_MODAL',
}

export type ModalState = {
  [key in MODALS]?: ModalParams
}

const initialState: ModalState = Object.keys(MODALS).reduce((acc, elm) => {
  return {
    ...acc,
    [elm]: false,
  }
}, {})

export const reducer = (state = initialState, action: Actions): ModalState => {
  switch (action.type) {
    case ActionTypes.OPEN:
      return {
        ...state,
        [action.payload.modal]: action.payload.modalParams || {},
      }

    case ActionTypes.CLOSE:
      return {
        ...state,
        [action.payload.modal]: undefined,
      }

    default:
      return state
  }
}
