import { all } from 'redux-saga/effects'
import { sagas as analyticsSagas } from '@hip/analytics'
import { sagas as hipCommunicatorSagas } from './core/hip-communicator/sagas'
import { sagas as designSagas } from './core/design/sagas'
import { sagas as hipEventsSagas } from './core/hip-events/sagas'
import { sagas as marxentSagas } from './core/marxent/sagas'
import { sagas as productsSagas } from './core/products/sagas'
import { sagas as userSagas } from './core/user/sagas'
import { sagas as utilsSagas } from './core/utils/sagas'
import { sagas as projectSagas } from './core/project/sagas'

export function* sagas(): IterableIterator<ReturnType<typeof all>> {
  yield all([
    analyticsSagas(),
    designSagas(),
    hipCommunicatorSagas(),
    hipEventsSagas(),
    marxentSagas(),
    productsSagas(),
    userSagas(),
    utilsSagas(),
    projectSagas(),
  ])
}
