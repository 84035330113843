import { INotification } from '@hip/components/src/components/notification/notification'
import { ActionTypes, Actions } from './actions'

export interface NotificationsState {
  notification?: INotification
}

const initialState: NotificationsState = {
  notification: undefined,
}

export const reducer = (
  state = initialState,
  action: Actions
): NotificationsState => {
  switch (action.type) {
    case ActionTypes.SET:
      return {
        notification: {
          ...action.payload,
        },
      }

    case ActionTypes.CLEAR:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
