import * as React from 'react';
import { _t } from '@hip/translations';
export const WarningCircleIcon = ({ color = '#F99F1B' }) => {
    const alt = _t('icons.warning');
    return (React.createElement("svg", { role: "img", "aria-label": alt, width: "24px", height: "24px", viewBox: "0 0 24 24", version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, alt),
        React.createElement("path", { d: "M13.44 12.964a1.44 1.44 0 01-2.88 0v-6.72a1.44 1.44 0 112.88 0v6.72zM12 19.196a1.44 1.44 0 110-2.878 1.44 1.44 0 010 2.878zM12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z", fill: color, fillRule: "evenodd" })));
};
export const WarningCircleRedIcon = () => React.createElement(WarningCircleIcon, { color: "#D0021B" });
export const WarningCircleWhiteIcon = () => React.createElement(WarningCircleIcon, { color: "#FFF" });
