import React from 'react'
import { _t } from '@hip/translations'
import { Helmet } from 'react-helmet'

import styles from './smart.module.css'

interface SmartLayoutProps {
  children: React.ReactNode
}

export const SmartLayout: React.FC<SmartLayoutProps> = ({ children }) => (
  <div className={styles.SmartLayout}>
    <Helmet>
      <title>{_t('title')}</title>
    </Helmet>
    <main className={styles.SmartLayoutWrapper}>{children}</main>
  </div>
)
