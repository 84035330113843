"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Features = void 0;
var Features;
(function (Features) {
    Features["FEATURE_TOGGLE_DASHBOARD"] = "FEATURE_TOGGLE_DASHBOARD";
    Features["QUOTING"] = "QUOTING";
    Features["OPPORTUNITY_CREATION"] = "OPPORTUNITY_CREATION";
    Features["BARCODES"] = "BARCODES";
    Features["NO_BASKET"] = "NO_BASKET";
    Features["AUTHENTICATED_SHARE_FLOW"] = "AUTHENTICATED_SHARE_FLOW";
    Features["EXPORT_XML_BOM"] = "EXPORT_XML_BOM";
    Features["DSV_STOCK"] = "DSV_STOCK";
    Features["CIAM_INTEGRATION"] = "CIAM_INTEGRATION";
    Features["TYK_UNAUTHENTICATED_EVENTS_WS"] = "TYK_UNAUTHENTICATED_EVENTS_WS";
    Features["TYK_AUTHENTICATED_EVENTS_WS"] = "TYK_AUTHENTICATED_EVENTS_WS";
    Features["TYK_CUSTOMER_APIS"] = "TYK_CUSTOMER_APIS";
    Features["STOCK_AVAILABILITY_INFORMATION"] = "STOCK_AVAILABILITY_INFORMATION";
    Features["PERSONAL_PROJECTS_LIMITATION"] = "PERSONAL_PROJECTS_LIMITATION";
    Features["COLLEAGUE_LOCK_SOLD_DESIGNS"] = "COLLEAGUE_LOCK_SOLD_DESIGNS";
})(Features = exports.Features || (exports.Features = {}));
