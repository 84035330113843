import { PROJECT_CATEGORY } from '@hip/rest-utils/providers/projectService/models'
import { JsCommunicator } from '@mxt/kf-js-communicator'

import {
  AutoSavePromptResponse,
  MxtAutoSavePromptResponseData,
  AutoSaveMarxentResponseType,
  MarxentJourneyType,
} from './interfaces'

export const mapMxtJourneyToProjectCategory = (journey: MarxentJourneyType) => {
  const map: { [k in MarxentJourneyType]: PROJECT_CATEGORY } = {
    [MarxentJourneyType.KITCHEN]: PROJECT_CATEGORY.KITCHEN,
    [MarxentJourneyType.BEDROOM]: PROJECT_CATEGORY.BEDROOM_STORAGE,
    [MarxentJourneyType.BATHROOM]: PROJECT_CATEGORY.BATHROOM,
  }
  if (map[journey]) return map[journey]
  throw new Error('Invalid journey type')
}

export const mapProjectCategoryToMxtJourney = (category: PROJECT_CATEGORY) => {
  const map: { [k in PROJECT_CATEGORY]: MarxentJourneyType } = {
    [PROJECT_CATEGORY.KITCHEN]: MarxentJourneyType.KITCHEN,
    [PROJECT_CATEGORY.BEDROOM_STORAGE]: MarxentJourneyType.BEDROOM,
    [PROJECT_CATEGORY.BATHROOM]: MarxentJourneyType.BATHROOM,
  }
  if (map[category]) return map[category]
  throw new Error('Invalid category')
}

export const mapAutoSavePromptResponseToMarxentResponseType = (
  response: AutoSavePromptResponse
): number | null => {
  switch (response) {
    case AutoSavePromptResponse.AUTOSAVE_APPROVED:
      return AutoSaveMarxentResponseType.LOAD
    case AutoSavePromptResponse.AUTOSAVE_REJECTED:
      return AutoSaveMarxentResponseType.DONT_LOAD_AND_DELETE
    case AutoSavePromptResponse.AUTOSAVE_SKIPPED:
      return AutoSaveMarxentResponseType.DONT_LOAD
    default:
      return null
  }
}

export const mxtAutoSavePromptResponse = (
  data: MxtAutoSavePromptResponseData,
  jsCommunicator: JsCommunicator
) => {
  const { eventUUID, response } = data
  jsCommunicator.autoSavePromptResponse(
    eventUUID,
    mapAutoSavePromptResponseToMarxentResponseType(response)
  )
}
