import {
  takeLatest,
  PutEffect,
  put,
  call,
  select,
  SelectEffect,
  CallEffect,
  take,
  TakeEffect,
  race,
  delay,
} from 'redux-saga/effects'
import { JsCommunicator } from '@mxt/kf-js-communicator'
import { _t } from '@hip/translations'

import { actions as modalActions } from '../../core/modals/actions'
import { actions as errorsActions } from '../errors/actions'
import { actions as communicatorActions } from '../hip-communicator/actions'
import {
  actions as designActions,
  ActionTypes as DesignActionTypes,
} from '../design/actions'
import { MODALS } from '../modals/reducer'
import { actions as notificationsActions } from '../notifications/actions'
import { getMxtToken, getVendor } from '../design/selectors'
import {
  getDesignId,
  getProjectId,
  getIsAuthenticatedShareFlow,
  getIsShareFlow,
} from '../utils/selectors'
import { refreshAuthToken } from '../hip-communicator/sagas'
import { TimeoutTypes } from '../utils/interfaces'
import { NOTIFICATION_TIMEOUT } from '../utils/sagas'
import {
  ActionTypes as MarxentActionTypes,
  actions as marxentActions,
} from './actions'
import { MarxentEventKind } from './interfaces'
import { mxtAutoSavePromptResponse } from './utils'
import { patchDesign } from './requests'

let refreshUuid
let jsCommunicator: JsCommunicator

function mxtInit({ payload }: ReturnType<typeof marxentActions.mxtInit>) {
  jsCommunicator = payload
  jsCommunicator.refreshToken = jsCommunicator.refreshToken.bind(jsCommunicator)
}

export function* handleDesignSave() {
  const designId = yield select(getDesignId)
  const projectId = yield select(getProjectId)
  const isShareFlow = yield select(getIsShareFlow)

  if (!isShareFlow && designId && projectId) {
    yield call(patchDesign, projectId, designId)
  }
}

export function* mxtEvent({
  payload: {
    mxtEvent: { kind, json, isDirty, uuid, journey, projectData },
  },
}: ReturnType<typeof marxentActions.mxtEvent>): IterableIterator<
  | PutEffect<ReturnType<typeof designActions.saveDesign>>
  | PutEffect<ReturnType<typeof communicatorActions.bom>>
  | PutEffect<ReturnType<typeof marxentActions.mxtTokenExpired>>
  | PutEffect<ReturnType<typeof designActions.dirtyStateChanged>>
  | PutEffect<ReturnType<typeof marxentActions.journeySelected>>
  | PutEffect<ReturnType<typeof errorsActions.pageError>>
  | SelectEffect
  | CallEffect
> {
  try {
    switch (kind) {
      case MarxentEventKind.DESIGN_CREATED: {
        const vendorId = JSON.parse(json).projectUUID

        if (!vendorId) {
          return yield put(
            errorsActions.pageError({ message: _t('messages.error') })
          )
        }

        const isCreateDesignPage = !(yield select(getDesignId))
        if (isCreateDesignPage) {
          yield put(designActions.saveDesign({ vendorId }))
        }
        break
      }

      case MarxentEventKind.BOM_SAVED:
        yield put(communicatorActions.bom())
        break

      case MarxentEventKind.TOKEN_REFRESH_REQUESTED:
        refreshUuid = uuid
        yield put(marxentActions.mxtTokenExpired())
        break

      case MarxentEventKind.DIRTY_STATE_CHANGED:
        yield put(designActions.dirtyStateChanged({ isDirty }))
        break

      case MarxentEventKind.JOURNEY_SELECTED: {
        yield put(marxentActions.journeySelected({ journey }))
        break
      }
      case MarxentEventKind.PROMPT_LOAD_FROM_AUTO_SAVE: {
        if (projectData?.projectUUID) {
          yield call(
            handleShowLoadFromAutoSaveDialog,
            uuid,
            projectData.projectUUID
          )
        }
        break
      }
      case MarxentEventKind.DESIGN_SAVED: {
        yield call(handleDesignSave)
        break
      }
    }
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

export const handleShowLoadFromAutoSaveDialog = function* (
  eventUUID: string,
  vendorId: string
): IterableIterator<
  PutEffect<ReturnType<typeof modalActions.open>> | SelectEffect
> {
  const currentVendorId = yield select(getVendor)
  if (currentVendorId && currentVendorId === vendorId) {
    const modalParams = { modalData: { eventUUID } }
    yield put(
      modalActions.open({ modal: MODALS.LOAD_FROM_AUTO_SAVE, modalParams })
    )
  }
}

function* mxtTokenExpired() {
  const TIMEOUT = 20000
  try {
    const { tokens, timeout } = yield race({
      tokens: call(refreshTokens),
      timeout: delay(TIMEOUT),
    })

    if (tokens) {
      yield call(jsCommunicator.refreshToken, refreshUuid, tokens.mxtToken)
    } else if (timeout) {
      throw new Error(`Failed to refresh token(s) within ${TIMEOUT}ms`)
    }
  } catch (e) {
    yield put(errorsActions.pageError(e))
  }
}

function* refreshTokens(): IterableIterator<
  | SelectEffect
  | CallEffect
  | PutEffect<ReturnType<typeof notificationsActions.set>>
  | { mxtToken: string; authToken: string }
> {
  try {
    const isShareFlow = yield select(getIsShareFlow)
    const isAuthenticatedShareFlow = yield select(getIsAuthenticatedShareFlow)
    let authToken
    if (!isShareFlow || isAuthenticatedShareFlow) {
      authToken = yield call(refreshAuthToken)
      if (!authToken) throw new Error('Auth token refresh failed')
    }
    const mxtToken = yield call(refreshMxtToken)
    if (!mxtToken) throw new Error('Mxt token refresh failed')
    return { mxtToken, authToken }
  } catch (e) {
    yield put(
      notificationsActions.set({
        name: TimeoutTypes.NOTIFICATION_TIMEOUT,
        type: 'danger',
        body: _t('messages.auth-expired'),
        close: true,
        timeout: NOTIFICATION_TIMEOUT,
      })
    )
  }
}

function* refreshMxtToken(): IterableIterator<
  | PutEffect<ReturnType<typeof designActions.loadDesign>>
  | TakeEffect
  | SelectEffect
  | string
> {
  yield put(designActions.loadDesign())
  yield take(DesignActionTypes.SET_MXT_TOKEN)
  const mxtToken = yield select(getMxtToken)
  return mxtToken
}

export function* handleSavePromptResponse({
  payload: { eventUUID, response },
}: ReturnType<typeof marxentActions.autoSavePromptResponse>): IterableIterator<
  CallEffect
> {
  yield call(mxtAutoSavePromptResponse, { eventUUID, response }, jsCommunicator)
}

export function* sagas() {
  yield takeLatest(MarxentActionTypes.MXT_INIT, mxtInit)
  yield takeLatest(MarxentActionTypes.MXT_EVENT, mxtEvent)
  yield takeLatest(MarxentActionTypes.MXT_TOKEN_EXPIRED, mxtTokenExpired)
  yield takeLatest(
    MarxentActionTypes.AUTO_SAVE_PROMPT_RESPONSE,
    handleSavePromptResponse
  )
}
