"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
const helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["PAGE_VIEW"] = "@hip/analytics/PAGE_VIEW";
    ActionTypes["EVENT"] = "@hip/analytics/EVENT";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    pageView: (payload) => helpers_1.createAction(ActionTypes.PAGE_VIEW, payload),
    event: (payload) => helpers_1.createAction(ActionTypes.EVENT, payload),
};
