"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENTS = exports.COMMUNICATOR_TYPES = void 0;
var COMMUNICATOR_TYPES;
(function (COMMUNICATOR_TYPES) {
    COMMUNICATOR_TYPES["BOM"] = "BOM";
    COMMUNICATOR_TYPES["PRINT_BOM"] = "PRINT_BOM";
    COMMUNICATOR_TYPES["MXT"] = "MXT";
})(COMMUNICATOR_TYPES = exports.COMMUNICATOR_TYPES || (exports.COMMUNICATOR_TYPES = {}));
var EVENTS;
(function (EVENTS) {
    EVENTS["LOADED"] = "LOADED";
    EVENTS["RESIZED"] = "RESIZED";
    EVENTS["BOM"] = "BOM";
    EVENTS["GET_PRODUCTS"] = "GET_PRODUCTS";
    EVENTS["POST_PRODUCTS"] = "POST_PRODUCTS";
    EVENTS["GET_PRODUCTS_FAIL"] = "GET_PRODUCTS_FAIL";
    EVENTS["CLOSE_BOM"] = "CLOSE_BOM";
    EVENTS["BOOK_APPOINTMENT"] = "BOOK_APPOINTMENT";
    EVENTS["DESIGN_CREATED"] = "DESIGN_CREATED";
    EVENTS["EXIT"] = "EXIT";
    EVENTS["POST_ANALYTICS"] = "POST_ANALYTICS";
    EVENTS["POST_AUTH"] = "POST_AUTH";
    EVENTS["POST_CATEGORY"] = "POST_CATEGORY";
    EVENTS["CREATE_NEW_DESIGN"] = "CREATE_NEW_DESIGN";
    EVENTS["REFRESH_AUTH_TOKEN"] = "REFRESH_AUTH_TOKEN";
    EVENTS["REFRESH_AUTH_TOKEN_SUCCESS"] = "REFRESH_AUTH_TOKEN_SUCCESS";
    EVENTS["REFRESH_AUTH_TOKEN_FAIL"] = "REFRESH_AUTH_TOKEN_FAIL";
    EVENTS["PRINTED_BOM"] = "PRINTED_BOM";
    EVENTS["ADD_TO_BASKET"] = "ADD_TO_BASKET";
})(EVENTS = exports.EVENTS || (exports.EVENTS = {}));
