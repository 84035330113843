import jwtDecode from 'jwt-decode'
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects'
import { ActionTypes as DesignActionTypes } from '../design/actions'
import { getToken } from '../utils/selectors'

import { actions } from './actions'
import { JWTUserData } from './interfaces'

export function* setUserData(): IterableIterator<
  | SelectEffect
  | CallEffect
  | PutEffect<ReturnType<typeof actions.setUserData>>
  | PutEffect<ReturnType<typeof actions.decodeUserDataFailure>>
> {
  try {
    const token = yield select(getToken)
    const data: JWTUserData = yield call(jwtDecode, token)
    yield put(
      actions.setUserData({
        givenName: data.given_name,
        sapStoreID: data.SapStoreID,
      })
    )
  } catch (e) {
    yield put(actions.decodeUserDataFailure(e))
  }
}

export function* sagas(): IterableIterator<ReturnType<typeof takeLatest>> {
  yield takeLatest(DesignActionTypes.SET_MXT_TOKEN, setUserData)
}
