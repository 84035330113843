import React from 'react'
import classnames from 'classnames'

import { dataTestId } from '@hip/test-attributes'
import { Button } from '@hip/components/src/components/button/button'
import { Text } from '@hip/components/src/components/text/text'
import { Icon } from '@hip/components/src/components/icon/icon'

import styles from './modal.module.css'

interface ModalProps {
  show: boolean
  onClose?: () => void
  title?: string
  rootClasses?: string
  headerClasses?: string
  contentClasses?: string
}

export const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  title,
  children,
  rootClasses,
  headerClasses,
  contentClasses,
}) => {
  return show ? (
    <div
      className={styles.ModalOverlay}
      onClick={onClose}
      data-test-id={dataTestId.component.modal.overlay}
    >
      <div
        className={classnames(styles.Modal, rootClasses)}
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        data-test-id={dataTestId.component.modal.container}
      >
        <div
          className={classnames(styles.ModalHeader, headerClasses)}
          data-test-id={dataTestId.component.modal.header}
        >
          <Text vMargin="none" el="h2">
            {title}
          </Text>
          {onClose && (
            <Button
              type="naked"
              classes={styles.CloseButton}
              onClick={onClose}
              dataTestId={dataTestId.component.modal.btnClose}
            >
              <Icon icon="CLOSE" />
            </Button>
          )}
        </div>
        <div className={classnames(styles.ModalContent, contentClasses)}>
          {children}
        </div>
      </div>
    </div>
  ) : null
}
