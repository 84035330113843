"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagCheckMapper = exports.trackingScriptId = exports.TRACK_PAGE = exports.TRACK_EVENT = void 0;
exports.TRACK_EVENT = 'track';
exports.TRACK_PAGE = 'page';
exports.trackingScriptId = 'utag-tracking-script';
var TagCheckMapper;
(function (TagCheckMapper) {
    TagCheckMapper["UTAG_DATA_LOADED"] = "utag.data loaded";
    TagCheckMapper["UTAG_DATA_NOT_LOADED"] = "utag.data not loaded";
    TagCheckMapper["UTAG_JS_NOT_LOADED"] = "utag.js not loaded";
})(TagCheckMapper = exports.TagCheckMapper || (exports.TagCheckMapper = {}));
