import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as features } from '@hip/feature-toggles'

import { reducer as errors } from './core/errors/reducer'
import { reducer as design } from './core/design/reducer'
import { reducer as marxent } from './core/marxent/reducer'
import { reducer as modals } from './core/modals/reducer'
import { reducer as notifications } from './core/notifications/reducer'
import { reducer as products } from './core/products/reducer'
import { reducer as user } from './core/user/reducer'
import { reducer as utils } from './core/utils/reducer'
import { reducer as project } from './core/project/reducer'

export const createRootReducers = history =>
  combineReducers({
    design,
    marxent,
    errors,
    features,
    modals,
    notifications,
    products,
    user,
    utils,
    project,
    router: connectRouter(history),
  })
