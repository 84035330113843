"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emitAccessShareableDesignError = exports.emitAccessShareableDesignSuccess = exports.emitAccessShareableDesign = void 0;
const events_service_types_1 = require("@hip/events-service-types");
const createEmmiter_1 = require("../helpers/createEmmiter");
const emitAccessShareableDesign = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN, request);
exports.emitAccessShareableDesign = emitAccessShareableDesign;
const emitAccessShareableDesignSuccess = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_SUCCESS, request);
exports.emitAccessShareableDesignSuccess = emitAccessShareableDesignSuccess;
const emitAccessShareableDesignError = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_SHAREABLE_DESIGN_EVENTS.ACCESS_SHAREABLE_DESIGN_ERROR, request);
exports.emitAccessShareableDesignError = emitAccessShareableDesignError;
