import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import jwtDecode from 'jwt-decode'
import {
  baseShareRoute,
  createProjectRoute,
  createShareRoute,
} from '@hip/helpers'
import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'
import { Features } from '@hip/interfaces'
import { ApplicationState } from '../../interfaces'
import { getIsTykAuthenticatedEventsWs } from '../hip-events/selectors'
import { getMxtToken } from '../design/selectors'
import { UtilsState } from './reducer'
import { getIsPartOfColleagueDesigns } from './utils'

const getRoot = ({ utils }: ApplicationState): UtilsState => utils
const projectMatchSelector = createMatchSelector(createProjectRoute())
const shareMatchSelector = createMatchSelector(createShareRoute(baseShareRoute))
const projectRouteMatcher = (state: ApplicationState): any =>
  projectMatchSelector(state)
const shareRouteMatcher = (state: ApplicationState): any =>
  shareMatchSelector(state)

export const getToken = createSelector(getRoot, state => state && state.token)

export const getTokenDecoded = createSelector(getToken, token =>
  token ? jwtDecode<any>(token) : null
)

export const getTokenStoreId = createSelector(getTokenDecoded, tokenDecoded => {
  // TODO: HIP-6722 Remove next hidden feature
  // Note: The next code block significantly violates the pure principle
  // of selectors; it should be removed in the mentioned ticket.
  const testStoreId = global?.localStorage?.getItem('TEST_TOKEN_STORE_ID')
  if (testStoreId) {
    return testStoreId
  }
  return tokenDecoded?.SapStoreID ?? null
})

export const getCookies = createSelector(
  getRoot,
  state => state && state.cookies
)

export const getCopied = createSelector(getRoot, state => state && state.copied)

export const getConfig = createSelector(getRoot, ({ config }) => config)

export const getOPCO = createSelector(getRoot, ({ opco }) => opco)

export const getMxtUrl = createSelector(getConfig, config => {
  const jsCommunicator = config?.marxent?.jsCommunicator
  if (!jsCommunicator) {
    return null
  }
  const url = new URL(jsCommunicator.url)
  Object.entries(jsCommunicator.queryParams ?? {}).forEach(([key, value]) =>
    url.searchParams.set(key, value)
  )
  return url.toString()
})

export const getIsTykAuthenticatedEventsWsEnabled = createSelector(
  getIsPartOfColleagueDesigns,
  getIsTykAuthenticatedEventsWs,
  (isPartOfColleagueDesigns, isCustomerAuthenticatedEventsWS) =>
    isPartOfColleagueDesigns ? true : isCustomerAuthenticatedEventsWS
)

export const getEventService = createSelector(
  getConfig,
  getIsTykAuthenticatedEventsWsEnabled,
  (config, isTykAuthenticatedEventsWsEnabled) => {
    if (!config) {
      return null
    }
    return isTykAuthenticatedEventsWsEnabled
      ? config['events-service']
      : config['events-service-polling']
  }
)

export const getIsUnauthenticatedEventsWS = getIsFeatureEnabledByConst(
  Features.TYK_UNAUTHENTICATED_EVENTS_WS
)

export const getUnauthenticatedEventService = createSelector(
  getConfig,
  getIsUnauthenticatedEventsWS,
  (config, isUnauthenticatedEventsWS) =>
    config && isUnauthenticatedEventsWS
      ? config['unauthenticated-events-service']
      : config['unauthenticated-events-service-polling']
)

export const getExperienceService = createSelector(
  getConfig,
  config => config && config['experience-service']
)

export const getUnauthenticatedExperienceService = createSelector(
  getConfig,
  config => config && config['unauthenticated-experience-service']
)

export const getAllowedOrigins = createSelector(
  getConfig,
  config => config && config!.communicator!.allowedOrigins
)

export const getAtmosphereId = createSelector(
  getConfig,
  config => config && config!.security!.clientId
)

export const getHostUrl = createSelector(
  getConfig,
  config => config && config!.hostUrl
)

export const getFinanceSimulator = createSelector(
  getConfig,
  config => config && config!.financeSimulator
)

export const getStockApi = createSelector(
  getConfig,
  config => config?.['stock-api']
)

export const getProjectId = createSelector(projectRouteMatcher, match => {
  return match ? match.params.projectId : undefined
})

export const getDesignId = createSelector(projectRouteMatcher, match =>
  match ? match.params.designId : undefined
)

export const getShareId = createSelector(shareRouteMatcher, match =>
  match ? match.params.shareId : undefined
)

export const getIsShareFlow = createSelector(shareRouteMatcher, match =>
  match && match.url ? match.url.includes('share') : false
)

export const getIsMxtReady = createSelector(
  getMxtToken,
  getConfig,
  (mxtToken, config) =>
    Boolean(mxtToken && config && Object.keys(config).length > 0)
)

export const getIsAuthenticatedShareFlow = getIsFeatureEnabledByConst(
  Features.AUTHENTICATED_SHARE_FLOW
)

export const getBearerTokenAuth = createSelector(
  getToken,
  token => `Bearer ${token}`
)

export const getShareFlowAuth = createSelector(
  getBearerTokenAuth,
  getAtmosphereId,
  getIsAuthenticatedShareFlow,
  (tokenAuth, atmosphereId, isAuthenticatedShareFlow) =>
    isAuthenticatedShareFlow
      ? tokenAuth
      : `Atmosphere atmosphere_app_id="${atmosphereId}"`
)
