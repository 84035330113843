import React from 'react';
import { FeatureToggleListItem } from '../featureToggleListItem/featureToggleListItem';
export const FeatureTogglesList = ({ displayFeatures, values, onChange, }) => {
    return (React.createElement("div", null, displayFeatures.map(feature => (React.createElement(FeatureToggleListItem, { feature: feature, value: values[feature], key: feature, onChange: newValue => {
            const newValues = Object.assign({}, values);
            if (newValue === null) {
                delete newValues[feature];
            }
            else {
                newValues[feature] = newValue;
            }
            onChange(newValues);
        } })))));
};
