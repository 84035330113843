import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { actions as analyticsActions, Tracking } from '@hip/analytics'
import { getBomEansAndQty } from '@hip/helpers'
import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'
import { Features } from '@hip/interfaces'
import { ProductList } from '@hip/components/src/components/productList/productList'
import { Hammertime } from '@hip/components/src/components/hammertime'
import { FlexCenter } from '@hip/components/src/components/flexCenter/flexCenter'
import { Text } from '@hip/components/src/components/text/text'
import { Icon } from '@hip/components/src/components/icon/icon'

import { ANALYTICS } from '../../core/analytics/constants'
import { actions as productActions } from '../../core/products/actions'
import {
  getIsLoadingProducts,
  getProducts,
  getProductsError,
  getStockData,
  getStockError,
  getStockIsLoading,
} from '../../core/products/selectors'
import { actions as hipCommunicatorActions } from '../../core/hip-communicator/actions'
import {
  getConfig,
  getFinanceSimulator,
  getOPCO,
} from '../../core/utils/selectors'

import styles from './bom.module.css'
import { parseStockUIMessages } from './utils'

const Bom: React.FC = () => {
  const dispatch = useDispatch()
  const { shareId, projectId, designId } = useParams() as any
  const config = useSelector(getConfig)
  const opco = useSelector(getOPCO)
  const data = useSelector(getProducts)
  const error = useSelector(getProductsError)
  const isLoading = useSelector(getIsLoadingProducts)
  const stockDataSummary = useSelector(getStockData)
  const isStockLoading = useSelector(getStockIsLoading)
  const stockDataError = useSelector(getStockError)
  const financeSimulator = useSelector(getFinanceSimulator)
  const isBasketEnabled = !useSelector(
    getIsFeatureEnabledByConst(Features.NO_BASKET)
  )
  const isExportBOMToXMLFeatureEnabled = useSelector(
    getIsFeatureEnabledByConst(Features.EXPORT_XML_BOM)
  )
  const isStockAvailabilityFeatureEnabled = useSelector(
    getIsFeatureEnabledByConst(Features.STOCK_AVAILABILITY_INFORMATION)
  )
  const isColleagueOwnedProject = false

  React.useEffect(() => {
    if ((projectId && designId) || shareId) {
      dispatch(productActions.loadProducts({ projectId, designId, shareId }))
    }
  }, [projectId, designId, shareId])

  const handleAddToBasket = isBasketEnabled
    ? () => {
        const products = getBomEansAndQty(data)
        dispatch(hipCommunicatorActions.addToBasket({ products }))
      }
    : undefined

  const handlePrintBOM = () => {
    dispatch(hipCommunicatorActions.showPrintedBom())

    dispatch(
      analyticsActions.event({
        eventName: ANALYTICS.DESIGN_BOM_PRINT,
      })
    )
  }

  const handleExportBOMToXML = isExportBOMToXMLFeatureEnabled
    ? () => dispatch(productActions.exportBOMToXML({ designId: shareId }))
    : undefined

  const handleClose = () => dispatch(hipCommunicatorActions.closeBom())

  const stockUIMessages = React.useMemo(() => {
    if (!isStockAvailabilityFeatureEnabled) {
      return null
    }
    if (!(stockDataSummary || stockDataError)) {
      return null
    }
    return parseStockUIMessages({
      stockDataSummary,
      stockDataError,
      bom: data,
    })
  }, [
    stockDataSummary,
    stockDataError,
    data,
    isStockAvailabilityFeatureEnabled,
  ])

  return (
    <div className={styles.FullViewHeight}>
      {isLoading && (
        <FlexCenter fullHeight>
          <Hammertime />
        </FlexCenter>
      )}

      {!(isLoading || isStockLoading) && data && (
        <ProductList
          opco={opco}
          data={data}
          allProductStockMessages={stockUIMessages?.byProduct}
          productStockGeneralErrors={stockUIMessages?.general}
          onClose={handleClose}
          handleAddToBasket={handleAddToBasket}
          handleExportBOMToXML={handleExportBOMToXML}
          handlePrintBOM={handlePrintBOM}
          isLoading={isLoading}
          financeLink={financeSimulator}
          isColleagueOwnedProject={isColleagueOwnedProject}
        />
      )}

      {!isLoading && error && <BOMError error={error} onClose={handleClose} />}

      {config?.analytics?.enabled && (
        <Tracking
          env={config?.analytics.env}
          profile={config?.analytics.profile}
          config={config?.analytics.config}
        />
      )}
    </div>
  )
}

export default Bom

const BOMError: React.FC<{ error: string; onClose: () => void }> = ({
  error,
  onClose,
}) => {
  return (
    <div className={styles.ErrorWrapper} onClick={onClose}>
      <FlexCenter fullHeight>
        <Icon icon="ERROR_CIRCLE" classes={styles.ErrorIcon} />
        <Text el="h3" color="white">
          {error}
        </Text>
      </FlexCenter>
    </div>
  )
}
