"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
const helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["INIT"] = "@util-sagas/requireConfirmation/INIT";
    ActionTypes["CONFIRM_ACTION"] = "@util-sagas/requireConfirmation/CONFIRM_ACTION";
    ActionTypes["REJECT_ACTION"] = "@util-sagas/requireConfirmation/REJECT_ACTION";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    init: (payload) => helpers_1.createAction(ActionTypes.INIT, payload),
    confirmAction: () => helpers_1.createAction(ActionTypes.CONFIRM_ACTION),
    rejectAction: () => helpers_1.createAction(ActionTypes.REJECT_ACTION),
};
