"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emitAccessDesignBomError = exports.emitAccessDesignBomSuccess = exports.emitAccessDesignBom = void 0;
const events_service_types_1 = require("@hip/events-service-types");
const createEmmiter_1 = require("../helpers/createEmmiter");
const emitAccessDesignBom = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM, request);
exports.emitAccessDesignBom = emitAccessDesignBom;
const emitAccessDesignBomSuccess = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_SUCCESS, request);
exports.emitAccessDesignBomSuccess = emitAccessDesignBomSuccess;
const emitAccessDesignBomError = (socket, request) => createEmmiter_1.createEmitter(socket, events_service_types_1.ACCESS_DESIGN_BOM_EVENTS.ACCESS_DESIGN_BOM_ERROR, request);
exports.emitAccessDesignBomError = emitAccessDesignBomError;
