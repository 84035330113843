import React from 'react'
import classnames from 'classnames'

import styles from './mobile-menu-button.module.css'

interface MobileMenuButtonProps {
  active: boolean
  handleClick: () => void
}

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({
  active,
  handleClick,
}: MobileMenuButtonProps) => (
  <div
    className={classnames(styles.MobileMenuButton, active && styles.Active)}
    onClick={handleClick}
  >
    <div className={styles.LineWrap}>
      <div className={classnames(styles.Line, styles.Top)}></div>
      <div className={classnames(styles.Line, styles.Middle)}></div>
      <div className={classnames(styles.Line, styles.Bottom)}></div>
    </div>
  </div>
)
