import React from 'react';
import classnames from 'classnames';
import { getStructuredPriceFormat, CurrencyTypeOption, } from '@hip/helpers';
import { Text } from '../text/text';
import styles from './bomPrice.module.css';
const renderPricePart = ({ type, text }, displayPrice = false, size, weight) => {
    const isSuperscript = displayPrice && type === CurrencyTypeOption.SYMBOL;
    return (React.createElement(Text, { key: type, weight: weight, size: size, el: "span", classes: isSuperscript && styles.Super }, text));
};
export const BomPrice = ({ amount, currency, displayPrice = false, size = 'm', weight = 'normal', classes, dataTestId, }) => {
    const structuredPrice = getStructuredPriceFormat({ amount, currency });
    const priceParts = structuredPrice.map(part => renderPricePart(part, displayPrice, size, weight));
    return (React.createElement(Text, { size: size, classes: classnames(styles.Price, displayPrice && styles.Display, classes), vMargin: "none", weight: weight, dataTestId: dataTestId }, priceParts));
};
