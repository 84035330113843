import * as React from 'react';
import { BrandIcon } from '../brandIcon/brandIcon';
import styles from './bomPdfFooter.module.css';
export const BomPdfFooter = ({ page, total, opco, }) => (React.createElement("div", { className: styles.Footer },
    React.createElement("div", { className: styles.Separator }),
    React.createElement("div", { className: styles.FooterContent },
        React.createElement(BrandIcon, { opco: opco }),
        Boolean(page) && Boolean(total) && (React.createElement("p", null,
            page,
            " / ",
            total)))));
