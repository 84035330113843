import * as React from 'react';
import { _t } from '@hip/translations';
export const CopyOutlineIcon = () => {
    const alt = _t('icons.copy');
    return (React.createElement("svg", { role: "img", "aria-label": alt, width: "20", height: "20", viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("title", null, alt),
        React.createElement("g", { fill: "inherit", fillRule: "evenodd" },
            React.createElement("path", { d: "M13.5 0v3.997h-2V1.999h-9v11.997h4.51v2H.5V0z" }),
            React.createElement("path", { d: "M14.743 3.997H6.5v16h13V8.995l-4.757-4.998zm-.859 2L17.5 9.793v8.202h-9v-12h5.384z" }),
            React.createElement("path", { d: "M14.5 4.997v4h3.903v2H12.5v-6z" }))));
};
