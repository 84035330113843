import * as React from 'react';
import { Text } from '../text/text';
import { PrintedTableHeader, PrintedTableRow, } from '../printedTableComponents/printedTableComponents';
import { PrintedMissingProductsDetailedTableRow } from '../printedMissingProductsDetailedTableRow/printedMissingProductsDetailedTableRow';
import { Barcode } from '../barcode/barcode';
import styles from './printedMissingProductsDetailedTable.module.css';
export const PrintedMissingProductsDetailedTable = ({ product, barcodesEnabled, }) => {
    const { ean, name, quantity } = product;
    const productRowClasses = barcodesEnabled
        ? styles.ProductRowNoBorderBottom
        : undefined;
    return (React.createElement("div", null,
        React.createElement(PrintedTableHeader, null,
            React.createElement(Text, { weight: "bold", classes: styles.ProductNameCell }, name),
            React.createElement(Text, { weight: "bold", classes: styles.ProductQuantityCell },
                "x",
                quantity),
            React.createElement("div", { className: styles.ProductPriceCell }, "-")),
        React.createElement(PrintedTableRow, { classes: productRowClasses },
            React.createElement(PrintedMissingProductsDetailedTableRow, { product: product })),
        barcodesEnabled && (React.createElement(PrintedTableRow, { classes: styles.BarcodeRow },
            React.createElement(Barcode, { ean: ean })))));
};
