import { createSelector, OutputSelector } from 'reselect'
import { ApplicationState } from '../../interfaces'
import { ModalParams } from './interfaces'
import { ModalState, MODALS } from './reducer'

const getRoot = ({ modals }: ApplicationState): ModalState => modals

export const getModal = (
  modal: MODALS
): OutputSelector<
  ApplicationState,
  ModalParams | undefined,
  (res: ModalState) => ModalParams | undefined
> => createSelector(getRoot, state => state[modal])
