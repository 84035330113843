import * as React from 'react';
import uuid from 'uuid';
import classnames from 'classnames';
import { dataTestId } from '@hip/test-attributes';
import styles from './base.module.css';
var actionTypes;
(function (actionTypes) {
    actionTypes["ON_CLICK"] = "ON_CLICK";
    actionTypes["ON_FOCUS"] = "ON_FOCUS";
    actionTypes["ON_BLUR"] = "ON_BLUR";
    actionTypes["ON_KEY_DOWN"] = "ON_KEY_DOWN";
})(actionTypes || (actionTypes = {}));
const actions = {
    onClick: () => ({ type: actionTypes.ON_CLICK }),
    onFocus: () => ({ type: actionTypes.ON_FOCUS }),
    onBlur: () => ({ type: actionTypes.ON_BLUR }),
    onKeyDown: () => ({ type: actionTypes.ON_KEY_DOWN }),
};
const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.ON_CLICK:
            return Object.assign(Object.assign({}, state), { isOpen: !state.isOpen, isFocused: false });
        case actionTypes.ON_FOCUS:
            return Object.assign(Object.assign({}, state), { isFocused: true });
        case actionTypes.ON_BLUR:
            return Object.assign(Object.assign({}, state), { isFocused: false });
        case actionTypes.ON_KEY_DOWN:
            return Object.assign(Object.assign({}, state), { isOpen: !state.isOpen, isFocused: true });
        default:
            throw new Error('Please provide a valid action');
    }
};
export const Base = ({ children, Header, initialIsOpen = false, className, }) => {
    const [state, dispatch] = React.useReducer(reducer, {
        isFocused: false,
        id: uuid.v4(),
        isOpen: initialIsOpen,
    });
    const sectionId = `section-${state.id}`;
    const labelId = `label-${state.id}`;
    return (React.createElement("div", { className: classnames(className) },
        React.createElement("div", { className: classnames(styles.Header, state.isFocused && styles.HeaderFocus), role: "button", id: sectionId, onClick: () => dispatch(actions.onClick()), onKeyDown: event => {
                if (event.key === ' ' || event.key === 'Enter') {
                    event.preventDefault();
                    dispatch(actions.onKeyDown());
                }
            }, onFocus: () => dispatch(actions.onFocus()), onBlur: () => dispatch(actions.onBlur()), "aria-controls": sectionId, "aria-expanded": state.isOpen, tabIndex: 0, "data-test-id": dataTestId.component.accordionHip.toggler },
            React.createElement(Header, { isOpen: state.isOpen })),
        React.createElement("div", { className: styles.Content, role: "region", id: labelId, hidden: !state.isOpen, "aria-hidden": !state.isOpen, "aria-expanded": state.isOpen, "aria-labelledby": labelId }, state.isOpen && children)));
};
