import { createSelector } from 'reselect'

import { ApplicationState } from '../../interfaces'
import { ProjectState } from './interfaces'

const getRoot = ({ project }: ApplicationState): ProjectState => project

export const getProject = createSelector(getRoot, project => project?.data)

export const getProjectIsLoading = createSelector(
  getRoot,
  project => project?.isLoading
)

export const getProjectError = createSelector(
  getRoot,
  project => project?.error
)

export const getProjectStatus = createSelector(
  getProject,
  project => project?.status
)
