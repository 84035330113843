"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sagas = void 0;
const redux_saga_1 = require("redux-saga");
const effects_1 = require("redux-saga/effects");
const actions_1 = require("./actions");
const constants_1 = require("./constants");
const utils_1 = require("./utils");
const eventQueue = [];
const listenForScriptLoad = script => redux_saga_1.eventChannel(emitter => {
    const handleLoad = () => {
        emitter(true);
        emitter(redux_saga_1.END);
    };
    script.addEventListener('load', handleLoad);
    return () => script.removeEventListener('load', handleLoad);
});
const getTrackingScript = () => document.querySelector(`#${constants_1.trackingScriptId}`);
function* flushQueueOnTrackingScriptLoad() {
    const script = yield effects_1.call(getTrackingScript);
    if (!script)
        return;
    const channel = yield effects_1.call(listenForScriptLoad, script);
    yield effects_1.take(channel);
    yield effects_1.call(flushQueue, false);
}
function* flushQueue(tryAgain = true) {
    try {
        const unhandled = [];
        while (eventQueue.length) {
            const event = yield effects_1.call(utils_1.createPost, eventQueue.shift());
            if (event) {
                unhandled.push(event);
            }
        }
        eventQueue.push(...unhandled);
        if (tryAgain && eventQueue.length > 0) {
            yield effects_1.fork(flushQueueOnTrackingScriptLoad);
        }
    }
    catch (err) {
        yield effects_1.call(console.log, 'Error trigger analytics event', err);
        return event;
    }
}
function* event(payload) {
    const event = yield effects_1.call(utils_1.generateTrackingEvent, payload);
    eventQueue.push(event);
    yield effects_1.call(flushQueue);
}
function* sagas() {
    // yield takeLatest(ActionTypes.PAGE_VIEW, pageView)
    yield effects_1.takeLatest(actions_1.ActionTypes.EVENT, event);
}
exports.sagas = sagas;
