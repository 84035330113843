import { createAction, ActionsUnion } from '@hip/helpers'
import {
  GetProductsPayload,
  ResizedPayload,
  DesignCreatedPayload,
  AddToBasketPayload,
} from './interfaces'

export enum ActionTypes {
  BOOTSTRAP = '@hip/hip-communicator/bootstrap',
  RESIZED = '@hip/hip-communicator/RESIZE',
  BOM = '@hip/hip-communicator/BOM',
  GET_PRODUCTS = '@hip/hip-communicator/GET_PRODUCTS',
  GET_PRODUCTS_FAIL = '@hip/hip-communicator/GET_PRODUCTS_FAIL',
  CLOSE_BOM = '@hip/hip-communicator/CLOSE_BOM',
  DESIGN_CREATED = '@hip/hip-communicator/DESIGN_CREATED',
  BOOK_APPOINTMENT = '@hip/hip-communicator/APPOINTMENT',
  EXIT = '@hip/hip-communicator/EXIT',
  CREATE_NEW_DESIGN = '@hip/hip-communicator/CREATE_NEW_DESIGN',
  REFRESH_AUTH_TOKEN_SUCCESS = '@hip/hip-communicator/REFRESH_AUTH_TOKEN_SUCCESS',
  REFRESH_AUTH_TOKEN_FAIL = '@hip/hip-communicator/REFRESH_AUTH_TOKEN_FAIL',
  SHOW_PRINTED_BOM = '@hip/hip-communicator/SHOW_PRINTED_BOM',
  ADD_TO_BASKET = '@hip/hip-communicator/ADD_TO_BASKET',
}

export const actions = {
  bootstrap: () => createAction(ActionTypes.BOOTSTRAP),
  resized: (payload: ResizedPayload) =>
    createAction(ActionTypes.RESIZED, payload),
  bom: () => createAction(ActionTypes.BOM),
  getProducts: (products: GetProductsPayload) =>
    createAction(ActionTypes.GET_PRODUCTS, products),
  getProductsFail: () => createAction(ActionTypes.GET_PRODUCTS_FAIL),
  closeBom: () => createAction(ActionTypes.CLOSE_BOM),
  designCreated: (payload: DesignCreatedPayload) =>
    createAction(ActionTypes.DESIGN_CREATED, payload),
  bookAppointment: () => createAction(ActionTypes.BOOK_APPOINTMENT),
  exit: () => createAction(ActionTypes.EXIT),
  createNewDesign: () => createAction(ActionTypes.CREATE_NEW_DESIGN),
  refreshAuthTokenSuccess: () =>
    createAction(ActionTypes.REFRESH_AUTH_TOKEN_SUCCESS),
  refreshAuthTokenFail: () => createAction(ActionTypes.REFRESH_AUTH_TOKEN_FAIL),
  showPrintedBom: () => createAction(ActionTypes.SHOW_PRINTED_BOM),
  addToBasket: (products: AddToBasketPayload) =>
    createAction(ActionTypes.ADD_TO_BASKET, products),
}

export type Actions = ActionsUnion<typeof actions>
