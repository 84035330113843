import React from 'react'
import { useDispatch } from 'react-redux'

import { _t } from '@hip/translations'
import { Button } from '@hip/components/src/components/button/button'
import { Text } from '@hip/components/src/components/text/text'
import { RadioGroup } from '@hip/components/src/components/form/radio'

import { actions as modalActions } from '../../../core/modals/actions'
import { actions as marxentActions } from '../../../core/marxent/actions'
import { AutoSavePromptResponse } from '../../../core/marxent/interfaces'
import { MODALS } from '../../../core/modals/reducer'
import { Modal } from '../modal/modal'

import styles from './load-from-auto-save-modal.css'

enum AnswerOption {
  YES = 'YES',
  NO = 'NO',
}

interface LoadFromAutoSaveProps {
  show: boolean
  modalData: {
    eventUUID?: string
  }
}

export const LoadFromAutoSaveModal: React.FC<LoadFromAutoSaveProps> = ({
  show,
  modalData = {},
}: LoadFromAutoSaveProps) => {
  const { eventUUID = '' } = modalData
  const [answer, setAnswer] = React.useState<AnswerOption>(AnswerOption.YES)
  const dispatch = useDispatch()

  const closeModal = () =>
    dispatch(modalActions.close({ modal: MODALS.LOAD_FROM_AUTO_SAVE }))

  const dispatchAutoSaveResponse = (response: AutoSavePromptResponse) => {
    dispatch(marxentActions.autoSavePromptResponse(eventUUID, response))
  }

  const handleApproveClick = () => {
    if (answer === AnswerOption.YES) {
      dispatchAutoSaveResponse(AutoSavePromptResponse.AUTOSAVE_APPROVED)
    } else {
      dispatchAutoSaveResponse(AutoSavePromptResponse.AUTOSAVE_REJECTED)
    }
    closeModal()
  }

  const handleModalClose = React.useCallback(() => {
    dispatchAutoSaveResponse(AutoSavePromptResponse.AUTOSAVE_SKIPPED)
    closeModal()
  }, [])

  return (
    <Modal
      show={show}
      onClose={handleModalClose}
      title={_t('load-auto-save-modal.title')}
      rootClasses={styles.LoadAutoSaveModalRoot}
      headerClasses={styles.LoadAutoSaveModalHeader}
    >
      <Text size="l" vMargin="xxxl">
        {_t('load-auto-save-modal.subtitle')}
      </Text>
      <RadioGroup
        classes={styles.LoadAutoSaveModalRadioGroup}
        name="loadFromAutoSaveOption"
        dataTestId="loadFromAutoSaveOption"
        selectedValue={answer}
        action={value => setAnswer(AnswerOption[value])}
        description=""
        options={[
          {
            label: _t('load-auto-save-modal.yes'),
            value: AnswerOption.YES,
            dataTestId: 'loadFromAutoSaveOption-yes',
          },
          {
            label: _t('load-auto-save-modal.no'),
            value: AnswerOption.NO,
            dataTestId: 'loadFromAutoSaveOption-no',
          },
        ]}
      />
      <Button
        classes={styles.LoadAutoSaveModalButton}
        onClick={handleApproveClick}
        type="primary"
        data-test-id="design-load-form-auto-save-modal-btn"
      >
        <Text size="l" color="white">
          {_t('load-auto-save-modal.confirm')}
        </Text>
      </Button>
      <Text size="l">{_t('load-auto-save-modal.note')}</Text>
    </Modal>
  )
}
